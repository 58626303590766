import React, { useState } from "react";
import IntakeSearch from "./IntakeSearch";
import IntakeDownload from "./IntakeDownload";
import { FaSyncAlt, FaPlus } from "react-icons/fa";
import CreateEncounterModal from "./CreateEncounterModal"; 

const IntakeToolbar = ({
  selectAll,
  setSelectAll,
  onRefresh,
  selectedPatients,
  patients,
  onFilter,
  clinicToken,
  loading,
}) => {
  const [showCreateEncounter, setShowCreateEncounter] = useState(false);

  return (
    <>
      <div className="intakeview-toolbar">
        <div className="intakeview-toolbar-left">
          {loading ? (
            <div className="small-spinner">
              <div className="small-spinner-circle"></div>
            </div>
          ) : (
            <>
              <input
                type="checkbox"
                className="intake-checkbox"
                checked={selectAll}
                onChange={(e) => setSelectAll(e.target.checked)}
                title="Select All"
              />
              <button onClick={onRefresh} title="Refresh" className="intakeview-refresh-button">
                <FaSyncAlt />
              </button>
            </>
          )}
        </div>
        <div className="intakeview-toolbar-center">
          <IntakeSearch patients={patients} onFilter={onFilter} />
        </div>
        <div className="intakeview-toolbar-right">
          {selectedPatients.length > 0 && (
            <>
              <IntakeDownload selectedPatients={selectedPatients} patients={patients} clinicId={clinicToken} />
              <button className="create-encounter-button" onClick={() => setShowCreateEncounter(true)}>
                <FaPlus /> Create Encounter
              </button>
            </>
          )}
        </div>
      </div>

      {showCreateEncounter && (
        <CreateEncounterModal
          selectedPatients={selectedPatients}
          clinicToken={clinicToken}
          onClose={() => setShowCreateEncounter(false)}
        />
      )}
    </>
  );
};

export default IntakeToolbar;
