import React, { useState } from "react";
import Modal from "react-modal";
import { FaTasks, FaSearch } from "react-icons/fa";

function IntakeSearch({ patients, onFilter }) {
  const [searchQuery, setSearchQuery] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filters, setFilters] = useState({
    dateFrom: "",
    dateTo: "",
    hasAppointment: false,
    hasIntakeForm: false,
  });

  /**
   * Apply filters immediately whenever something changes.
   * If you want a debounce, you can add a useEffect + setTimeout.
   */
  const applyFilters = (newFilters = filters, newSearch = searchQuery) => {
    let filtered = [...patients];

    // 1) Text search by name
    if (newSearch.trim()) {
      filtered = filtered.filter((patient) => {
        const first = patient?.data?.patient?.firstName?.toLowerCase() || "";
        const middle = patient?.data?.patient?.middleName?.toLowerCase() || "";
        const last = patient?.data?.patient?.lastName?.toLowerCase() || "";
        const fullName = `${first} ${middle} ${last}`.trim();
        return fullName.includes(newSearch.toLowerCase());
      });
    }

    // 2) Date filtering by string comparison of the UTC date (YYYY-MM-DD)
    const { dateFrom, dateTo, hasAppointment, hasIntakeForm } = newFilters;

    filtered = filtered.filter((patient) => {
      // Convert Firestore timestamp to a real Date, then slice UTC date string
      const createdDate = new Date(patient.created).toISOString().slice(0, 10);
      
      // Compare if dateFrom is set
      if (dateFrom && createdDate < dateFrom) {
        return false;
      }
      // Compare if dateTo is set
      if (dateTo && createdDate > dateTo) {
        return false;
      }
      return true;
    });

    // 3) Checkbox filters
    if (hasAppointment) {
      filtered = filtered.filter((p) => p.hasAppointment);
    }
    if (hasIntakeForm) {
      filtered = filtered.filter((p) => p.hasIntakeForm);
    }

    onFilter(filtered);
  };

  // Handle direct input changes
  const handleSearchChange = (e) => {
    const newVal = e.target.value;
    setSearchQuery(newVal);
    applyFilters(filters, newVal);
  };

  const handleFilterChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newFilters = {
      ...filters,
      [name]: type === "checkbox" ? checked : value,
    };
    setFilters(newFilters);
    applyFilters(newFilters, searchQuery);
  };

  // Reset filters fully
  const clearFilters = () => {
    const cleared = {
      dateFrom: "",
      dateTo: "",
      hasAppointment: false,
      hasIntakeForm: false,
    };
    setFilters(cleared);
    setSearchQuery("");
    applyFilters(cleared, "");
    setIsModalOpen(false);
  };

  // Submit button in the modal
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsModalOpen(false);
  };

  return (
    <div className="intakeview-search-container">
      {/* Basic search bar */}
      <div className="intakeview-search-bar">
        <FaSearch className="intakeview-search-icon" />
        <input
          type="text"
          value={searchQuery}
          onChange={handleSearchChange}
          placeholder="Search by Name"
          className="intakeview-search-input"
        />
        <FaTasks
          className="intakeview-filter-icon"
          title="Filter"
          onClick={() => setIsModalOpen(true)}
        />
      </div>

      {/* Modal for filters */}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        contentLabel="Filter Patients"
        style={{
          overlay: { backgroundColor: "rgba(0, 0, 0, 0.5)" },
          content: {
            maxWidth: "400px",
            margin: "auto",
            padding: "2rem",
            borderRadius: "8px",
          },
        }}
      >
        <h2>Filter Patients</h2>
        <form onSubmit={handleSubmit}>
          <div className="intakeview-filter-item">
            <label>
              Date From:
              <input
                type="date"
                name="dateFrom"
                value={filters.dateFrom}
                onChange={handleFilterChange}
                className="intakeview-filter-input"
              />
            </label>
          </div>
          <div className="intakeview-filter-item">
            <label>
              Date To:
              <input
                type="date"
                name="dateTo"
                value={filters.dateTo}
                onChange={handleFilterChange}
                className="intakeview-filter-input"
              />
            </label>
          </div>
          <div className="intakeview-filter-item">
            <label>
              <input
                type="checkbox"
                name="hasAppointment"
                checked={filters.hasAppointment}
                onChange={handleFilterChange}
                className="intake-checkbox"
              />
              Has Appointment
            </label>
          </div>
          <div className="intakeview-filter-item">
            <label>
              <input
                type="checkbox"
                name="hasIntakeForm"
                checked={filters.hasIntakeForm}
                onChange={handleFilterChange}
                className="intake-checkbox"
              />
              Has Intake Form
            </label>
          </div>
          <div className="intakeview-filter-actions">
            <button type="submit" className="intakeview-filter-button">
              Apply
            </button>
            <button
              type="button"
              onClick={clearFilters}
              className="intakeview-filter-button"
            >
              Clear
            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
}

export default IntakeSearch;
