import React, { useState, useEffect } from "react";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";
import PayerSelection from "../Payers/PayerSelection";
import { getFunctions, httpsCallable } from "firebase/functions";
import styles from "./PatientView.module.css";
import Modal from "react-modal";

const functions = getFunctions();

const InsuranceStatus = ({ patientSelected, onDataUpdated, uid }) => {
  const [isPrimaryPayerMatched, setIsPrimaryPayerMatched] = useState(false);
  const [isSecondaryPayerMatched, setIsSecondaryPayerMatched] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingPayerType, setEditingPayerType] = useState(null);

  useEffect(() => {
    const payers = patientSelected?.data?.payers || {};
    console.log("Checking payer data:", payers);

    setIsPrimaryPayerMatched(Boolean(payers?.CPID && payers.CPID !== "Not Available"));
    setIsSecondaryPayerMatched(
      Boolean(payers?.secondaryPayer?.CPID && payers.secondaryPayer.CPID !== "Not Available")
    );
  }, [patientSelected]);

  const handleOpenPayerSelector = (type) => {
    console.log(`Opening payer selector for: ${type}`);
    setEditingPayerType(type);
    setIsModalOpen(true);
  };

  const handlePayerSelect = async (payer) => {
    if (!payer || !editingPayerType || !uid || !patientSelected?.id) {
      console.warn("Invalid selection or missing required data:", { payer, editingPayerType, uid, patientId: patientSelected?.id });
      return;
    }

    try {
      const existingData = { ...patientSelected.data };
      const isPrimary = editingPayerType === "primary";

      const payerName = payer.name || payer.payerName || "Not Available";
      const memberId = payer.memberId || existingData.payers?.memberId || "Not Available";
      const cpid = payer.CPID ?? "Not Available";

      console.log(`Selected payer (${editingPayerType}):`, { payerName, memberId, cpid });

      if (isPrimary) {
        existingData.payers = {
          ...existingData.payers,
          name: payerName,
          memberId: memberId,
          CPID: cpid,
        };
      } else {
        const oldSecondary = existingData.payers.secondaryPayer || {};
        existingData.payers = {
          ...existingData.payers,
          secondaryPayer: {
            ...oldSecondary,
            name: payerName,
            memberId: memberId,
            CPID: cpid,
          },
        };
      }

      console.log("Updated patient data before encryption:", existingData);

      const encryptFunction = httpsCallable(functions, "encrypt");
      const encryptionResult = await encryptFunction(existingData);
      const { iv, ciphertext } = encryptionResult.data;

      console.log("Encryption result:", { iv, ciphertext });

      const patientRef = doc(db, "patients", uid, "patientData", patientSelected.id);
      await updateDoc(patientRef, { patient: { iv, ciphertext } });

      console.log("Updated patient record in Firestore");

      setIsModalOpen(false);
      onDataUpdated();
    } catch (error) {
      console.error("Error updating payer information:", error);
    }
  };

  const renderPayerStatus = (type, name, isMatched, memberId) => (
    <div className={styles.payerSummary} style={{ marginTop: type === "secondary" ? "10px" : "0" }}>
      <p className={styles.insuranceName} style={{ fontSize: "20px", fontWeight: "bold" }}>
        {name || `${type === "primary" ? "Primary" : "Secondary"} Insurance`}
      </p>
      {memberId && (
        <p className={styles.memberId} style={{ fontSize: "14px", color: "gray", marginTop: "5px" }}>
          Member ID: {memberId}
        </p>
      )}
      {/* <span
        onClick={() => handleOpenPayerSelector(type)}
        style={{
          cursor: "pointer",
          color: isMatched ? "blue" : "orange",
          fontSize: "12px",
          textDecoration: "underline",
          marginTop: "5px",
        }}
      >
        {isMatched ? "Payer Matched" : "Payer Not Matched"}
      </span> */}
    </div>
  );

  const tradingPartnerName =
    editingPayerType === "primary"
      ? patientSelected?.data?.payers?.name || ""
      : patientSelected?.data?.payers?.secondaryPayer?.name || "";

  return (
    <div className={styles.flexContainer}>
      {renderPayerStatus(
        "primary",
        patientSelected.data.payers?.name || "No Insurance on Record",
        isPrimaryPayerMatched,
        patientSelected.data.payers?.memberId
      )}

      {patientSelected.data.payers?.secondaryPayer?.name &&
        renderPayerStatus(
          "secondary",
          patientSelected.data.payers.secondaryPayer.name,
          isSecondaryPayerMatched,
          patientSelected.data.payers.secondaryPayer?.memberId
        )}

      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        contentLabel="Select Payer"
        className={styles.modal}
        overlayClassName={styles.modalOverlay}
      >
        <h2>Select Payer</h2>
        <PayerSelection
          mode="all"
          onSelect={handlePayerSelect}
          TradingPartnerName={tradingPartnerName}
        />
        <div style={{ marginTop: "1rem" }}>
          <button
            onClick={() => setIsModalOpen(false)}
            style={{ cursor: "pointer", padding: "0.5rem 1rem" }}
          >
            Continue
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default InsuranceStatus;
