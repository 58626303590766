import React, { useState, useEffect } from "react";
import { doc, getDoc, collection, getDocs, updateDoc, query, where } from "firebase/firestore";
import { db } from "../../firebase";
import useUID from "../General/useUID";
import "./PatientView.module.css";
import style from "./PatientView.module.css";

function ClinicNameFetcher({ clinicToken, patientId }) {
  const [clinicName, setClinicName] = useState(null);
  const [uid] = useUID();

  // State to manage modal visibility
  const [showModal, setShowModal] = useState(false);

  // State for the list of all clinics
  const [allClinics, setAllClinics] = useState([]);

  // State for the new clinic the user wants to move to
  const [selectedClinicToken, setSelectedClinicToken] = useState("");

  /**
   * Fetch the current clinic name (if clinicToken is valid).
   */
  useEffect(() => {
    if (!clinicToken) return;

    const fetchClinicName = async () => {
      try {
        const clinicDocRef = doc(db, "clinics", clinicToken);
        const clinicDoc = await getDoc(clinicDocRef);

        if (clinicDoc.exists()) {
          setClinicName(clinicDoc.data().clinicName);
        } else {
          setClinicName(null);
        }
      } catch (error) {
        console.error("Error fetching clinic name:", error);
        setClinicName(null);
      }
    };

    fetchClinicName();
  }, [clinicToken]);

/**
 * Fetch the list of all clinics owned by the current user,
 * excluding those with `deleted: true`.
 */
useEffect(() => {
  const fetchAllClinics = async () => {
    try {
      if (!uid) return;

      const clinicsCollection = collection(db, "clinics");
      const q = query(
        clinicsCollection,
        where("ownerId", "==", uid) // Only fetch clinics owned by the user
      );
      const querySnapshot = await getDocs(q);

      const fetchedClinics = querySnapshot.docs
        .map((docSnap) => ({
          id: docSnap.id,
          ...docSnap.data(),
        }))
        .filter((clinic) => clinic.deleted !== true); // Exclude clinics with `deleted: true`

      setAllClinics(fetchedClinics);
    } catch (error) {
      console.error("Error fetching all clinics:", error);
    }
  };

  fetchAllClinics();
}, [uid]);


  /**
   * Update the clinic token for the specified patient.
   */
  const handleConfirmChangeClinic = async () => {
    if (!selectedClinicToken) {
      console.error("No clinic selected. Cannot proceed.");
      return;
    }

    try {
      const patientDocRef = doc(db, "patients", uid, "patientData", patientId);

      await updateDoc(patientDocRef, { clinicToken: selectedClinicToken });

      // Update the clinic name in the UI
      setClinicName(
        allClinics.find((clinic) => clinic.id === selectedClinicToken)?.clinicName
      );

      // Close modal and reset state
      setShowModal(false);
      setSelectedClinicToken("");
    } catch (error) {
      console.error("Error changing clinic for patient:", error);
    }
  };

  /**
   * Inline Modal Component
   */
  const MoveClinicModal = ({ open, onClose, clinicName }) => {
    if (!open) return null;

    return (
      <div style={styles.backdrop}>
        <div style={styles.modalContainer}>
          <h2>Move Clinic</h2>
          <p>
            Current Clinic: <strong>{clinicName ?? "N/A"}</strong>
          </p>

          <label>Choose new clinic: </label>
          <select
            value={selectedClinicToken}
            onChange={(e) => {
              const value = e.target.value;
              setSelectedClinicToken(value);
            }}
          >
            <option value="">-- Select --</option>
            {allClinics.map((clinic) => (
              <option key={clinic.id} value={clinic.id}>
                {clinic.clinicName}
              </option>
            ))}
          </select>

          <div style={{ marginTop: "1rem" }}>
            <button
              onClick={handleConfirmChangeClinic}
              disabled={!selectedClinicToken}
            >
              Confirm Move
            </button>
            <button onClick={onClose} style={{ marginLeft: "1rem" }}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  };

  // Don't render anything if clinicName is not available
  if (!clinicName) {
    return null;
  }

  return (
    <>
      <p>
        <span style={{ opacity: 0.75 }}>Clinic Name: </span>
        <span>{clinicName}</span>
        <span
          style={{ marginLeft: "1rem" }}
          onClick={() => setShowModal(true)}
          className={style.editButton}         >
          Move Clinic &gt;
        </span>
      </p>

      {/* Render the modal conditionally */}
      <MoveClinicModal
        open={showModal}
        onClose={() => setShowModal(false)}
        clinicName={clinicName}
      />
    </>
  );
}

const styles = {
  backdrop: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 5,
  },
  modalContainer: {
    background: "#fff",
    padding: "2rem",
    borderRadius: "8px",
    boxShadow: "0 2px 10px rgba(0,0,0,0.2)",
    minWidth: "300px",
  },
};

export default ClinicNameFetcher;
