// src/components/BillingCodes/BillingCodesSelector.jsx
import React, { useState, useEffect } from "react";
import { auth, db } from "../../../firebase";
import { doc, onSnapshot, getDocs, setDoc, addDoc, deleteDoc, collection } from "firebase/firestore";
import BillingCodeForm from "./BillingCodeForm";
import BillingCodeCard from "./BillingCodeCard";
import "../Claims.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import HexagonSpinner from "../../General/Animations/Hexspinner";
import Modal from "react-modal";
import HelpArticleLink from "../../Articles/HelpArticleLink";
import useUID from "../../General/useUID";

function BillingCodesSelector({
  onBillingCodeSelect,
  mode,
  initialSelectedCode = null,
  className = "",
}) {
  const [billingCodes, setBillingCodes] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedBillingCodes, setSelectedBillingCodes] = useState([]);
  const [editingCodeId, setEditingCodeId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const [deleteCandidateId, setDeleteCandidateId] = useState(null);
  const [uid, subUserUID] = useUID();
  const [userRole, setUserRole] = useState(null);

  // Form state (note: no diagnosisCode here)
  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const [billAmount, setBillAmount] = useState("0.00");
  const [modifierA, setModifierA] = useState("");
  const [modifierB, setModifierB] = useState("");
  const [error, setError] = useState("");

  const billingCodesRef = collection(db, `users/${uid}/billingCodes`);

  useEffect(() => {
    if (!uid) return;
    let unsubscribe;
    if (subUserUID) {
      // For a subuser, fetch from the subusers collection
      const subUserRef = doc(db, "users", uid, "subusers", subUserUID);
      unsubscribe = onSnapshot(subUserRef, (docSnapshot) => {
        if (docSnapshot.exists()) {
          const data = docSnapshot.data();
          setUserRole(data.role || "nurse");
        }
      });
    } else {
      // For a main user, fetch from the main users document
      const userRef = doc(db, "users", uid);
      unsubscribe = onSnapshot(userRef, (docSnapshot) => {
        if (docSnapshot.exists()) {
          const data = docSnapshot.data();
          setUserRole(data.role || "nurse");
        }
      });
    }
    return () => unsubscribe && unsubscribe();
  }, [uid, subUserUID]);
  
  // Handle initial selection if provided
  useEffect(() => {
    if (initialSelectedCode && billingCodes.length > 0) {
      const billingCode = billingCodes.find(
        (b) => b.id === initialSelectedCode || b.code === initialSelectedCode
      );
      if (billingCode) {
        setSelectedBillingCodes([billingCode]);
        onBillingCodeSelect([billingCode]);
      }
    }
  }, [initialSelectedCode, billingCodes]);

  // Load billing codes from Firestore
  useEffect(() => {
    if (!uid) return;
    async function getBillingCodes() {
      setIsLoading(true);
      const querySnapshot = await getDocs(billingCodesRef);
      if (querySnapshot.empty) {
        await addDoc(billingCodesRef, {});
      }
      const unsubscribe = onSnapshot(billingCodesRef, (querySnapshot) => {
        const billingCodesData = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          billingCodesData.push({
            id: doc.id,
            name: data.name,
            code: data.code,
            diagnosisCode: data.diagnosisCode || [], // Diagnosis codes stored separately
            billAmount: data.billAmount,
            modifierA: data.modifierA,
            modifierB: data.modifierB,
          });
        });
        setBillingCodes(billingCodesData);
        setIsLoading(false);
      });
      return unsubscribe;
    }
    getBillingCodes();
  }, [uid]);

  // Reset form fields (no diagnosis codes here)
  const resetFormFields = () => {
    setName("");
    setCode("");
    setBillAmount("0.00");
    setModifierA("");
    setModifierB("");
    setError("");
    setEditingCodeId(null);
  };

  // Handle form submission (add or update) without diagnosis codes
  const handleFormSubmit = async (event) => {
    event.preventDefault();
    setError("");

    if (!name) {
      setError("Name is required.");
      return;
    }
    if (!code) {
      setError("Billing code is required.");
      return;
    }
    if (!billAmount || isNaN(billAmount)) {
      setError("Valid charge amount is required.");
      return;
    }

    const billAmountInDollars = parseFloat(billAmount);

    if (editingCodeId) {
      await setDoc(doc(billingCodesRef, editingCodeId), {
        name,
        code,
        billAmount: billAmountInDollars,
        modifierA,
        modifierB,
      });
    } else {
      await addDoc(billingCodesRef, {
        name,
        code,
        billAmount: billAmountInDollars,
        modifierA,
        modifierB,
      });
    }
    resetFormFields();
    setShowPopup(false);
  };

  // Delete handling
  const handleDeleteRequest = (billingCodeId) => {
    setDeleteCandidateId(billingCodeId);
    setShowDeleteConfirmModal(true);
  };

  const confirmDelete = async () => {
    await deleteDoc(doc(billingCodesRef, deleteCandidateId));
    setShowDeleteConfirmModal(false);
  };

  // Selection handling
  const handleSelect = (billingCodeId) => {
    const billingCode = billingCodes.find((b) => b.id === billingCodeId);
    const alreadySelected = selectedBillingCodes.some((b) => b.id === billingCodeId);
  
    let updatedState;
    if (!alreadySelected) {
      // Add the new code
      updatedState = [...selectedBillingCodes, billingCode];
    } else {
      // Remove if already selected
      updatedState = selectedBillingCodes.filter((b) => b.id !== billingCodeId);
    }
  
    setSelectedBillingCodes(updatedState);
    onBillingCodeSelect(updatedState);
  };
  

  // Begin edit flow
  const startEdit = (billingCode) => {
    setName(billingCode.name);
    setCode(billingCode.code);
    setBillAmount(billingCode.billAmount);
    setModifierA(billingCode.modifierA);
    setModifierB(billingCode.modifierB);
    setEditingCodeId(billingCode.id);
    setShowPopup(true);
  };

  // Handle diagnosis code selection for a specific billing code (triggered from the ICD10 selector rendered in the card)
  const handleBillingCodeDiagnosisSelect = async (billingCodeId, selectedCodes) => {
    try {
      const diagnosisCodesArray = selectedCodes.map((codeObj) => codeObj.code);
      await setDoc(
        doc(billingCodesRef, billingCodeId),
        { diagnosisCode: diagnosisCodesArray },
        { merge: true }
      );
      // Update local state for billingCodes
      setBillingCodes((prevCodes) =>
        prevCodes.map((b) => {
          if (b.id === billingCodeId) {
            return { ...b, diagnosisCode: diagnosisCodesArray };
          }
          return b;
        })
      );
      // Also update selectedBillingCodes if needed
      setSelectedBillingCodes((prevCodes) =>
        prevCodes.map((b) => {
          if (b.id === billingCodeId) {
            return { ...b, diagnosisCode: diagnosisCodesArray };
          }
          return b;
        })
      );
    } catch (error) {
      console.error("Error updating diagnosis codes", error);
    }
  };

  return (
    <div className={`billing-codes-selector-container ${className}`}>
      <div className="billing-codes-header">
        {mode !== "encounters" && (
          <h3 className="billing-codes-label">Billing Codes</h3>
        )}
        <button
          className="add-billing-code-button"
          onClick={() => setShowPopup(true)}
        >
          <FontAwesomeIcon className="m-0" icon={faPlus} /> Add Billing Code
        </button>
      </div>
      {showPopup && (
        <div className={`add-billing-code-popup ${className}`}>
          <BillingCodeForm
            name={name}
            setName={setName}
            code={code}
            setCode={setCode}
            billAmount={billAmount}
            setBillAmount={setBillAmount}
            modifierA={modifierA}
            setModifierA={setModifierA}
            modifierB={modifierB}
            setModifierB={setModifierB}
            error={error}
            onSubmit={handleFormSubmit}
            onCancel={() => {
              resetFormFields();
              setShowPopup(false);
            }}
            isEditing={!!editingCodeId}
          />
        </div>
      )}
      {!showPopup && (
        <div className={`billingCodesContainer ${className}`}>
          {isLoading ? (
            <HexagonSpinner />
          ) : billingCodes.length > 0 ? (
            billingCodes.map((billingCode) => (
              <BillingCodeCard
                key={billingCode.id}
                billingCode={billingCode}
                isSelected={selectedBillingCodes.some((b) => b.id === billingCode.id)}
                onSelect={handleSelect}
                onEdit={startEdit}
                onDelete={handleDeleteRequest}
                onDiagnosisCodeSelect={handleBillingCodeDiagnosisSelect}
                userRole={userRole}  
              />
            ))
          ) : (
            <div className="no-billing-codes-message">
              <h4>You don't have any codes, please add your billing codes.</h4>
            </div>
          )}
        </div>
      )}
      <Modal
        isOpen={showDeleteConfirmModal}
        onRequestClose={() => setShowDeleteConfirmModal(false)}
        className={`confirmModal ${className}`}
      >
        <h2>Delete Billing Code?</h2>
        <div>
          <button onClick={() => setShowDeleteConfirmModal(false)}>
            Cancel
          </button>
          <button onClick={confirmDelete}>Delete</button>
        </div>
      </Modal>
      {mode !== "encounters" && (
        <div className={`help-article-wide ${className}`}>
          <HelpArticleLink
            article={{
              title: "Adding Billing Codes",
              link: "https://popularishealth.com/article/Adding-Billing-Codes",
            }}
          />
        </div>
      )}
    </div>
  );
}

export default BillingCodesSelector;
