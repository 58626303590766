import React, { useState, useEffect } from "react";
import {
  collection,
  doc,
  query,
  getDoc,
  onSnapshot,
  updateDoc
} from "firebase/firestore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash, faClinicMedical, faHospital, faAmbulance, faUserMd, faHome, faEye,
  faFileMedical, faHandshake, faBed, faHeartbeat, faHandHoldingMedical,
  faLaptopMedical, faBuilding, faUserTie, faSmile, faShieldAlt
} from "@fortawesome/free-solid-svg-icons";
import useUID from "../../General/useUID";
import { getFunctions, httpsCallable } from "firebase/functions";
import "./Encounters.css";
import { db } from "../../../firebase";

const functions = getFunctions();
const decryptFunction = httpsCallable(functions, "decrypt");

const faIcons = {
  "Outpatient Visit": faClinicMedical,
  "Inpatient Admission": faHospital,
  "Emergency Room Visit": faAmbulance,
  "Telemedicine Visit": faLaptopMedical,
  "Home Health Visit": faHome,
  "Ambulatory Care": faUserMd,
  "Day Surgery": faClinicMedical,
  "Observation": faEye,
  "Ancillary Services": faFileMedical,
  "Follow-up Visit": faHandshake,
  "Preventative Visit": faShieldAlt,
  "Rehabilitation Visit": faHandHoldingMedical,
  "Referral Visit": faBuilding,
  "Urgent Care Visit": faAmbulance,
  "Post-operative Visit": faHeartbeat,
  "Nursing Home Visit": faBed,
  "Routine Check-up": faSmile,
  "Maternity & Antenatal Visits": faUserTie,
};

function EncountersView({ patientId }) {
  const [encounters, setEncounters] = useState([]);
  const [openEncounterId, setOpenEncounterId] = useState(null);
  const [uid, subUserUID, isLoading, error] = useUID();

  useEffect(() => {
    if (!uid) return;
    if (!patientId) {
      setEncounters([]);
      return;
    }

    const encountersRef = collection(
      doc(db, "patients", uid, "patientData", patientId),
      "encounters"
    );

    const encountersQuery = query(encountersRef);

    const unsubscribe = onSnapshot(encountersQuery, async (snapshot) => {
      const loadedEncounters = [];

      for (const docSnap of snapshot.docs) {
        const encounter = docSnap.data();
        let decryptedData = {};

        if (encounter.ciphertext && encounter.iv) {
          try {
            const encryptedData = {
              ciphertext: encounter.ciphertext,
              iv: encounter.iv
            };
            const decryptedResponse = await decryptFunction(encryptedData);
            decryptedData = decryptedResponse.data;
          } catch (error) {
            console.error("Error decrypting encounter data: ", error);
            continue;
          }
        } else {
          decryptedData = encounter;
        }

        // Ensure billing codes include their respective diagnosis codes
        if (decryptedData.billingCode) {
          decryptedData.billingCode = decryptedData.billingCode.map((bc) => ({
            ...bc,
            diagnosisCode: bc.diagnosisCode || []
          }));
        }

        loadedEncounters.push({
          id: docSnap.id,
          ...decryptedData,
          timestamp: encounter.timestamp?.toDate()
        });
      }

      // Sort so newest is first
      loadedEncounters.sort((a, b) => b.timestamp - a.timestamp);

      // Look up provider data for each encounter
      const providerDataPromises = loadedEncounters.map(async (encounter) => {
        if (!encounter.selectedProviders?.providerId) {
          return { organizationName: null, firstName: null, lastName: null };
        }
        const providerDocRef = doc(
          db,
          "users",
          uid,
          "providers",
          encounter.selectedProviders.providerId
        );
        const providerSnapshot = await getDoc(providerDocRef);
        return providerSnapshot.exists()
          ? providerSnapshot.data()
          : { organizationName: null, firstName: null, lastName: null };
      });

      const providersData = await Promise.all(providerDataPromises);

      // Merge the provider data
      const encountersWithProviders = loadedEncounters.map(
        (encounter, index) => ({
          ...encounter,
          ...providersData[index]
        })
      );

      setEncounters(encountersWithProviders);
      console.log("Processed Encounters:", encountersWithProviders);
    });

    return () => unsubscribe();
  }, [uid, patientId]);

  const handleToggle = (id) => {
    setOpenEncounterId(openEncounterId === id ? null : id);
  };

  const handleDelete = async (encounterId) => {
    try {
      const encounterDocRef = doc(
        db,
        "patients",
        uid,
        "patientData",
        patientId,
        "encounters",
        encounterId
      );

      await updateDoc(encounterDocRef, {
        isDeleted: true,
        BillingStatus: "Deleted"
      });
    } catch (error) {
      console.error("Error deleting encounter:", error);
    }
  };

  const handleToggleBillingStatus = async (encounterId, currentStatus) => {
    try {
      const encounterDocRef = doc(
        db,
        "patients",
        uid,
        "patientData",
        patientId,
        "encounters",
        encounterId
      );
      const newStatus = currentStatus === "NotBilled" ? "Billed" : "NotBilled";
      await updateDoc(encounterDocRef, { BillingStatus: newStatus });
    } catch (error) {
      console.error("Error updating billing status:", error);
    }
  };

  return (
    <div className="encountersContainer">
      {encounters.length > 0 &&
        encounters.filter((enc) => !enc.isDeleted).length > 0 && (
          <>
            <h3>Encounters</h3>
            <div className="divider" />
          </>
        )}

      {encounters
        .filter((enc) => !enc.isDeleted)
        .map((enc) => {
          const {
            id,
            encounterType,
            dateOfService,
            BillingStatus,
            controlNumber,
            encounterId
          } = enc;

          return (
            <div
              key={id}
              className={`encounterCard ${
                BillingStatus === "NotBilled" ? "notBilled" : ""
              }`}
              onClick={() => handleToggle(id)}
              title="Click to expand"
            >
              <div className="serviceAndDateContainer">
                {faIcons[encounterType] && (
                  <FontAwesomeIcon
                    icon={faIcons[encounterType]}
                    className="encounterIcon"
                    title={encounterType}
                  />
                )}
                {/* NONCOLLAPSED: Show Date of Service + Type */}
                <p>
                  {dateOfService}
                  {encounterType && ` – ${encounterType}`}
                </p>

                <span
                  className="toggleBillingStatus"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleToggleBillingStatus(id, BillingStatus);
                  }}
                  title="Click to mark as billed."
                >
                  $
                </span>
              </div>

              {/* EXPANDED DETAILS */}
              {openEncounterId === id && (
                <div className="expandedEncounterDetails">
                  <p>
                    <strong>Provider:</strong>{" "}
                    {[
                      enc.provider?.firstName ||
                        enc.providerfirstName ||
                        "Unknown",
                      enc.provider?.lastName ||
                        enc.providerlastName ||
                        ""
                    ]
                      .filter(Boolean)
                      .join(" ")}
                  </p>
                  <p>
                    <strong>Provider Organization:</strong>{" "}
                    {enc.provider?.organizationName ||
                      enc.organizationName ||
                      "Unknown"}
                  </p>

                  <div>
                    <strong>Billing Codes:</strong>
                    <ul>
                      {enc.billingCode?.map((code) => (
                        <li key={code.id}>
                          {code.name} ({code.code}) – {code.billAmount} USD
                          <ul>
                            {code.diagnosisCode?.map((diag, index) => (
                              <li key={index}>Diagnosis Code: {diag}</li>
                            ))}
                          </ul>
                        </li>
                      ))}
                    </ul>
                  </div>

                  {/* Control Number + ID side by side */}
                  <p>
                    <strong>Control Number:</strong> {controlNumber} 
                    {" – "}
                    <strong>Encounter ID:</strong> {encounterId}
                  </p>
                </div>
              )}
            </div>
          );
        })}
    </div>
  );
}

export default EncountersView;
