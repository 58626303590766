import React from "react";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const encryptionData = {
  "Zero-Trust AES": {
    label: "Zero-Trust AES",
    data: [9, 6, 9], // Speed, Security, Efficiency
    backgroundColor: "rgba(54, 162, 235, 0.6)", // Blue
  },
  "Post-Quantum Encryption": {
    label: "Post-Quantum Encryption",
    data: [5, 10, 7], // Speed, Security, Efficiency
    backgroundColor: "rgba(255, 99, 132, 0.6)", // Red
  },
};

const EncryptionHistogram = ({ encryptionType }) => {
  console.log("Histogram encryptionType:", encryptionType); // Debugging

  const dataset = encryptionData[encryptionType] || encryptionData["Zero-Trust AES"];

  const data = {
    labels: ["Speed", "Security Level", "Efficiency"],
    datasets: [dataset],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: { display: false },
    },
    scales: {
      y: { beginAtZero: true, max: 10 },
    },
  };

  return (
    <div className="callOfDuty">
      <h3>{dataset.label} Performance</h3>
      <Bar data={data} options={options} />
    </div>
  );
};

export default EncryptionHistogram;
