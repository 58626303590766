import React, { useState, useEffect } from "react";
import { 
  collection, 
  getDocs, 
  addDoc, 
  deleteDoc, 
  doc, 
  updateDoc 
} from "firebase/firestore";
import { db } from "../../../firebase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faSyncAlt } from "@fortawesome/free-solid-svg-icons"; // Import faSyncAlt for refresh icon
import HexagonSpinner from "../../General/Animations/Hexspinner";
import useUID from "../../General/useUID";
import PayerSelection from "../../Payers/PayerSelection";

function PayerMatchingModal({ onClose, initialMode }) {
  const [payerMatches, setPayerMatches] = useState([]);
  const [selectedPayer, setSelectedPayer] = useState(null);
  const [payerName, setPayerName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [mode, setMode] = useState(initialMode || "all"); // If no initial mode, default to 'all'
  const [showAddPayerForm, setShowAddPayerForm] = useState(false);
  const [uid] = useUID();
  
  // State to hold Payers collection data mapped by normalized payerName
  const [payersMap, setPayersMap] = useState({});

  useEffect(() => {
    if (!uid) return;
    fetchPayers(); // Fetch payers once when component mounts
  }, [uid]);

  useEffect(() => {
    if (!uid || Object.keys(payersMap).length === 0) return;
    fetchPayerMatches();
  }, [uid, mode, payersMap]); // Re-fetch when uid, mode, or payersMap changes

  /**
   * Fetch the Payers collection and create a mapping from normalized payerName to EligibilityPayerID
   */
  const fetchPayers = async () => {
    try {
      const payersCollection = collection(db, "Payers"); // Adjust the path if Payers are under a different collection
      const payersSnapshot = await getDocs(payersCollection);
      const payersData = payersSnapshot.docs.map(doc => doc.data());
      
      const map = {};
      payersData.forEach(payer => {
        if (payer.payerName) {
          // Normalize the payerName to ensure consistent matching (e.g., case-insensitive)
          const normalizedName = payer.payerName.trim().toLowerCase();
          
          if (map[normalizedName]) {
            console.warn(`Duplicate payerName found in Payers collection: ${payer.payerName}`);
            // Handle duplicates as needed, e.g., skip or override. Here, we'll skip duplicates.
            return;
          }
          
          if (!payer.EligibilityPayerID) {
            console.warn(`EligibilityPayerID missing for payer: ${payer.payerName}`);
            // Optionally, you can handle this case by notifying the user or skipping
            // For now, we'll skip adding this payer to the map
            return;
          }
          
          map[normalizedName] = payer.EligibilityPayerID;
        }
      });
      setPayersMap(map);
    } catch (error) {
      console.error("Error fetching Payers collection: ", error);
    }
  };

  /**
   * Handle mode change and reset the form
   * @param {string} newMode - The new mode to switch to
   */
  const handleModeChange = (newMode) => {
    setMode(newMode);
    resetForm(); // Reset form when mode changes
  };

  /**
   * Handle payer selection from the PayerSelection component
   * @param {object} payer - The selected payer object
   */
  const handlePayerSelect = (payer) => {
    setSelectedPayer(payer);
  };

  /**
   * Handle form submission to add a new payer match
   * @param {Event} e - The form submission event
   */
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!payerName.trim()) {
        alert("Please provide a name for the matched payer.");
        return;
    }

    if (!selectedPayer || !selectedPayer.payerName) {
        alert("Please select a valid payer from the list.");
        return;
    }

    // Normalize the payerName
    const normalizedPayerName = selectedPayer.payerName.trim().toLowerCase();
    const eligibilityID = payersMap[normalizedPayerName];

    // Save the payer match, even if EligibilityPayerID is missing
    const updatedPayer = {
        ...selectedPayer,
        EligibilityPayerID: eligibilityID || null, // Set to null if not found
    };

    try {
        await addDoc(collection(db, `users/${uid}/payerMatches`), {
            name: payerName.trim(),
            payer: updatedPayer, // Save the updated payer data
        });

        fetchPayerMatches();
        resetForm();
    } catch (error) {
        console.error("Error adding payer match: ", error);
        alert("Failed to add payer match. Please try again.");
    }
};


  /**
   * Removed deriveEligibilityID since EligibilityPayerID should be fetched from Payers collection
   */

  /**
   * Fetch payer matches for the user and ensure EligibilityPayerID is set
   */
  const fetchPayerMatches = async () => {
    setIsLoading(true);
    try {
      const payerMatchesCollection = collection(db, `users/${uid}/payerMatches`);
      const payerMatchesSnapshot = await getDocs(payerMatchesCollection);
      const payerMatchesData = payerMatchesSnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      })).filter((match) => 
        mode === "all" || 
        (mode === "claims" && match.payer.CPID) || 
        (mode === "eligibility" && match.payer.EligibilityPayerID)
      );

      setPayerMatches(payerMatchesData);

      // Identify matches with payerName but missing EligibilityPayerID
      const matchesToUpdate = payerMatchesData.filter(
        (match) => match.payer.payerName && !match.payer.EligibilityPayerID
      );

      // Update each match to include EligibilityPayerID from payersMap
      const updatePromises = matchesToUpdate.map(async (match) => {
        const normalizedPayerName = match.payer.payerName.trim().toLowerCase();
        const eligibilityID = payersMap[normalizedPayerName];
        
        if (!eligibilityID) {
          console.warn(`EligibilityPayerID not found for payer: ${match.payer.payerName}. Skipping update for match ID: ${match.id}`);
          return match; // Skip updating if EligibilityPayerID is not found
        }

        const updatedPayer = {
          ...match.payer,
          EligibilityPayerID: eligibilityID,
        };
        const matchRef = doc(db, `users/${uid}/payerMatches`, match.id);
        try {
          await updateDoc(matchRef, {
            payer: updatedPayer,
          });
          console.log(`Updated EligibilityPayerID for match ID: ${match.id}`);
          return { ...match, payer: updatedPayer };
        } catch (error) {
          console.error(`Error updating match ID ${match.id}: `, error);
          return match; // Return original match if update fails
        }
      });

      const updatedMatches = await Promise.all(updatePromises);
      setPayerMatches(prevMatches => 
        prevMatches.map(match => {
          const updatedMatch = updatedMatches.find(u => u.id === match.id);
          return updatedMatch || match;
        })
      );

    } catch (error) {
      console.error("Error fetching payer matches: ", error);
      alert("Failed to fetch payer matches. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Handle deletion of a payer match
   * @param {string} matchId - The ID of the payer match to delete
   */
  const handleDelete = async (matchId) => {
    const matchRef = doc(db, `users/${uid}/payerMatches`, matchId);

    if (!window.confirm("Are you sure you want to delete this payer match?")) {
      return;
    }

    try {
      await deleteDoc(matchRef);
      fetchPayerMatches();
    } catch (error) {
      console.error("Error deleting document: ", error);
    }
  };

  /**
   * Handle click on "Add Payer Match" button to show the form
   */
  const handleAddPayerClick = () => {
    setShowAddPayerForm(true);
  };

  /**
   * Handle click to show only eligibility matches
   */
  const handleShowEligibilityMatches = () => {
    setMode("eligibility"); // Update mode to show only eligibility matches
    resetForm(); // Reset form when switching to eligibility mode
  };

  /**
   * Handle back button click to reset the form
   */
  const handleBackClick = () => {
    resetForm(); // Reset form when back button is clicked
  };

  /**
   * Reset the form state
   */
  const resetForm = () => {
    setSelectedPayer(null);
    setPayerName("");
    setShowAddPayerForm(false);
  };

  /**
   * Handle refresh button click to refetch Payers and payerMatches
   */
  const handleRefresh = () => {
    fetchPayers(); // Refresh both Payers and payerMatches
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="filesCloseButton" onClick={onClose}>X</button>
        <div className="padded-top">
          {/* Mode toggle buttons */}
          <button
            onClick={() => handleModeChange("all")}
            className={`mode-button ${mode === "all" ? "selected-mode" : ""}`}
          >
            All
          </button>
          <button
            onClick={() => handleModeChange("claims")}
            className={`mode-button ${mode === "claims" ? "selected-mode" : ""}`}
          >
            Claims
          </button>
          <button
            onClick={handleShowEligibilityMatches}
            className={`mode-button ${mode === "eligibility" ? "selected-mode" : ""}`}
          >
            Eligibility
          </button>

          {/* Refresh Button */}
          <button 
            className="refresh-button" 
            onClick={handleRefresh}
            style={{ marginLeft: "10px", padding: "5px 10px", cursor: "pointer" }}
            title="Refresh Payer Matches"
          >
            <FontAwesomeIcon icon={faSyncAlt} /> Refresh
          </button>
        </div>
        {showAddPayerForm ? (
          <>
            <button className="backButton" onClick={handleBackClick}>Back</button>
            {selectedPayer ? (
              <form onSubmit={handleSubmit} className="padded-top">
                <h2>Please type the payer's name in your spreadsheet.</h2>
                <p>This is the name that we will match to our system payer whenever you upload a batch file.</p>
                <input
                  value={payerName}
                  onChange={(e) => setPayerName(e.target.value.toUpperCase())}
                  placeholder={`Name for ${selectedPayer.payerName}`}
                  required
                />
                <button type="submit">Save Match</button>
              </form>
            ) : (
              <PayerSelection onSelect={handlePayerSelect} required mode={mode} />
            )}
          </>
        ) : (
          <>
            <button className="padded-top" onClick={handleAddPayerClick}>Add Payer Match</button>
            {!isLoading && payerMatches.length > 0 && (
              <div className="payerMatchedTable padded-top">
                <h4>Existing Matches:</h4>
                <table>
                  <thead>
                    <tr>
                      <th>Name in Spreadsheet</th>
                      <th>Name in System</th>
                      <th>Eligibility Payer ID</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {payerMatches.map((match) => (
                      <tr key={match.id}>
                        <td>{match.name}</td>
                        <td>{match.payer.payerName}</td>
                        <td>{match.payer.EligibilityPayerID || "N/A"}</td>
                        <td>
                          <FontAwesomeIcon
                            icon={faTrash}
                            onClick={() => handleDelete(match.id)}
                            style={{ cursor: "pointer", color: "red" }}
                            title="Delete Payer Match"
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
            {!isLoading && payerMatches.length === 0 && (
              <p className="padded-top">No payer matches found.</p>
            )}
            {isLoading && (
              <div className="loading-container">
                <HexagonSpinner />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default PayerMatchingModal;
