import { useState, useEffect } from "react";
import { storage } from "../../firebase";
import { ref, listAll, getDownloadURL, getMetadata, deleteObject } from "firebase/storage";
import useUID from "./useUID";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolder, faFilePdf, faDownload, faTrash, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import "./Files.css";
import Topbar from "./Topbar";

const Files = () => {
  const [currentPath, setCurrentPath] = useState("");
  const [folders, setFolders] = useState([]);
  const [files, setFiles] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [menuPosition, setMenuPosition] = useState(null);
  const [contextMenuVisible, setContextMenuVisible] = useState(false);
  const [downloadProgress, setDownloadProgress] = useState(0);
  const [hoverInfo, setHoverInfo] = useState(null);
  const [hoverPosition, setHoverPosition] = useState({ x: 0, y: 0 });
  const [uid] = useUID();

  useEffect(() => {
    if (!uid) return;
    fetchContents();
  }, [uid, currentPath]);

  const fetchContents = async () => {
    if (!uid) return;
    const basePath = currentPath ? `users/${uid}/${currentPath}/` : `users/${uid}/`;
    console.log(`Fetching from: ${basePath}`);
    const baseRef = ref(storage, basePath);

    try {
      const res = await listAll(baseRef);

      setFolders(
        res.prefixes.map((folder) => ({
          name: folder.name,
          path: currentPath ? `${currentPath}/${folder.name}` : folder.name,
          type: "folder",
        }))
      );

      const fetchedFiles = await Promise.all(
        res.items.map(async (item) => ({
          name: item.name,
          url: await getDownloadURL(item),
          path: `${currentPath}/${item.name}`,
          type: "file",
        }))
      );

      setFiles(fetchedFiles);
    } catch (error) {
      console.error("Error fetching contents:", error);
    }
  };

  const selectItem = (item, event) => {
    event.preventDefault();
    setSelectedItem(item);
    setMenuPosition({ x: event.pageX, y: event.pageY });
    setContextMenuVisible(true);
  };

  const openItem = (item) => {
    if (item.type === "file") {
      window.open(item.url, "_blank");
    } else {
      setCurrentPath(item.path);
    }
  };

  const goBack = () => {
    if (!currentPath) return;
    const newPath = currentPath.split("/").slice(0, -1).join("/");
    setCurrentPath(newPath);
  };

  // ---------- HOVER INFO LOGIC ----------
  const handleMouseEnter = async (item, event) => {
    setHoverPosition({ x: event.pageX, y: event.pageY });
    if (item.type === "file") {
      try {
        const fileRef = ref(storage, `users/${uid}/${item.path}`);
        const metadata = await getMetadata(fileRef);
        setHoverInfo({
          name: item.name,
          count: null,
          createdAt: metadata.timeCreated,
          type: item.type,
        });
      } catch (error) {
        console.error("Error fetching file metadata:", error);
        setHoverInfo({
          name: item.name,
          count: null,
          createdAt: "Unknown",
          type: item.type,
        });
      }
    } else if (item.type === "folder") {
      try {
        const folderRef = ref(storage, `users/${uid}/${item.path}`);
        const res = await listAll(folderRef);
        const itemCount = res.items.length + res.prefixes.length;
        // Attempt to get folder metadata (folders often lack metadata in Firebase Storage)
        let createdAt = "N/A";
        try {
          const metadata = await getMetadata(folderRef);
          createdAt = metadata.timeCreated;
        } catch (err) {
          console.warn("Folder metadata not available", err);
        }
        setHoverInfo({
          name: item.name,
          count: itemCount,
          createdAt,
          type: item.type,
        });
      } catch (error) {
        console.error("Error fetching folder info:", error);
        setHoverInfo({
          name: item.name,
          count: 0,
          createdAt: "Unknown",
          type: item.type,
        });
      }
    }
  };

  const handleMouseLeave = () => {
    setHoverInfo(null);
  };
  // ---------- END HOVER INFO LOGIC ----------

  // Count total files recursively under a storage path.
  const countFilesRecursively = async (storagePath) => {
    const folderRef = ref(storage, storagePath);
    let count = 0;
    try {
      const res = await listAll(folderRef);
      count += res.items.length;
      for (const subFolder of res.prefixes) {
        count += await countFilesRecursively(subFolder.fullPath);
      }
    } catch (error) {
      console.error("Error counting files in folder:", error);
    }
    return count;
  };

  // Modified addFolderToZip accepts a callback to update progress per file added.
  const addFolderToZip = async (zip, storagePath, localPath, onFileAdded) => {
    console.log(`Fetching folder: ${storagePath}`);
    const folderRef = ref(storage, storagePath);

    try {
      const res = await listAll(folderRef);
      console.log(`Found ${res.items.length} files and ${res.prefixes.length} subfolders in ${storagePath}`);

      for (const fileRef of res.items) {
        try {
          console.log(`Fetching file: ${fileRef.name}`);
          const url = await getDownloadURL(fileRef);
          const response = await fetch(url, { mode: "cors" });
          if (!response.ok) throw new Error(`Failed to fetch ${fileRef.name}, Status: ${response.status}`);

          const blob = await response.blob();
          zip.file(`${localPath}/${fileRef.name}`, blob);
          onFileAdded(); // Update progress for each file added
          console.log(`✅ Added to ZIP: ${fileRef.name}`);
        } catch (err) {
          console.error(`🚨 Failed to add file: ${fileRef.name}`, err);
        }
      }

      for (const subFolder of res.prefixes) {
        console.log(`Entering subfolder: ${subFolder.fullPath}`);
        const subZipFolder = zip.folder(subFolder.name);
        await addFolderToZip(subZipFolder, subFolder.fullPath, `${localPath}/${subFolder.name}`, onFileAdded);
      }
    } catch (error) {
      console.error("Error fetching folder contents:", error);
    }
  };

  const downloadFolderAsZip = async (folderPath) => {
    console.log(`Starting ZIP process for folder: ${folderPath}`);

    // Count total files in folder and subfolders
    const totalFiles = await countFilesRecursively(`users/${uid}/${folderPath}`);
    let filesAdded = 0;

    // Callback to update progress for each file added.
    const updateFileProgress = () => {
      filesAdded++;
      // File adding accounts for the first 50% of overall progress.
      const fileStageProgress = totalFiles ? (filesAdded / totalFiles) * 50 : 50;
      setDownloadProgress(fileStageProgress);
    };

    const zip = new JSZip();
    await addFolderToZip(zip, `users/${uid}/${folderPath}`, folderPath, updateFileProgress);

    console.log("Generating ZIP file...");
    // Use JSZip's progress callback to update progress from 50% to 100%
    zip.generateAsync({ type: "blob" }, (metadata) => {
      const generationProgress = metadata.percent * 0.5; // Scale 0-100 to 0-50
      setDownloadProgress(50 + generationProgress);
      console.log(`ZIP generation progress: ${50 + generationProgress}%`);
    }).then((content) => {
      console.log("ZIP file ready, saving...");
      saveAs(content, `${folderPath.split("/").pop()}.zip`);
      setDownloadProgress(0); // Reset when done
    }).catch(error => {
      console.error("Error generating ZIP file:", error);
      setDownloadProgress(0);
    });
  };

  const downloadSelected = async () => {
    if (!selectedItem) return;

    if (selectedItem.type === "file") {
      window.open(selectedItem.url, "_blank");
    } else {
      await downloadFolderAsZip(selectedItem.path);
    }

    setSelectedItem(null);
    setContextMenuVisible(false);
  };

  const deleteFolderRecursively = async (folderPath) => {
    const folderRef = ref(storage, `users/${uid}/${folderPath}`);
    try {
      const res = await listAll(folderRef);

      // Delete all files in the current folder.
      const deleteFiles = res.items.map((item) => deleteObject(item));
      await Promise.all(deleteFiles);

      // Recursively delete files in each subfolder.
      for (const subFolder of res.prefixes) {
        await deleteFolderRecursively(subFolder.fullPath.replace(`users/${uid}/`, ""));
      }
    } catch (error) {
      console.error("Error deleting folder:", error);
    }
  };

  const deleteSelected = async () => {
    if (!selectedItem) return;

    try {
      if (selectedItem.type === "folder") {
        await deleteFolderRecursively(selectedItem.path);
      } else {
        const fileRef = ref(storage, `users/${uid}/${selectedItem.path}`);
        await deleteObject(fileRef);
      }
      alert(`Deleted: ${selectedItem.name}`);
      setSelectedItem(null);
      setContextMenuVisible(false);
      fetchContents();
    } catch (error) {
      console.error("Error deleting:", error);
    }
  };

  return (
    <>
      <Topbar />
      <div className="file-browser" onClick={() => setContextMenuVisible(false)}>
        <h2>File Manager</h2>

        {currentPath && (
          <button className="back-button" onClick={goBack}>
            <FontAwesomeIcon icon={faArrowLeft} /> Back
          </button>
        )}

        <div className="finder-grid">
          {folders.map((folder) => (
            <div
              key={folder.name}
              className={`finder-item ${selectedItem?.path === folder.path ? "selected" : ""}`}
              onClick={(e) => selectItem(folder, e)}
              onDoubleClick={() => openItem(folder)}
              onContextMenu={(e) => selectItem(folder, e)}
              onMouseEnter={(e) => handleMouseEnter(folder, e)}
              onMouseLeave={handleMouseLeave}
            >
              <FontAwesomeIcon icon={faFolder} size="4x" />
              <span>{folder.name}</span>
            </div>
          ))}

          {files.map((file) => (
            <div
              key={file.name}
              className={`finder-item ${selectedItem?.path === file.path ? "selected" : ""}`}
              onClick={(e) => selectItem(file, e)}
              onDoubleClick={() => openItem(file)}
              onContextMenu={(e) => selectItem(file, e)}
              onMouseEnter={(e) => handleMouseEnter(file, e)}
              onMouseLeave={handleMouseLeave}
            >
              <FontAwesomeIcon icon={faFilePdf} size="3x" />
              <span>{file.name}</span>
            </div>
          ))}
        </div>

        {contextMenuVisible && selectedItem && (
          <div className="context-menu" style={{ top: menuPosition.y, left: menuPosition.x }}>
            <button onClick={downloadSelected}>
              <FontAwesomeIcon icon={faDownload} /> Download
            </button>
            <button onClick={deleteSelected}>
              <FontAwesomeIcon icon={faTrash} /> Delete
            </button>
          </div>
        )}

        {/* Progress Bar */}
        {downloadProgress > 0 && downloadProgress < 100 && (
          <div className="progress-bar-container">
            <progress value={downloadProgress} max="100"></progress>
            <span>{Math.floor(downloadProgress)}%</span>
          </div>
        )}

        {/* Hover Tooltip */}
        {hoverInfo && (
          <div
            className="hover-tooltip"
            style={{
              top: hoverPosition.y + 10,
              left: hoverPosition.x + 10,
              position: "absolute",
              backgroundColor: "#333",
              color: "#fff",
              padding: "5px 10px",
              borderRadius: "4px",
              zIndex: 1000,
              pointerEvents: "none",
            }}
          >
            <div><strong>{hoverInfo.name}</strong></div>
            {hoverInfo.type === "folder" && (
              <div>Items: {hoverInfo.count}</div>
            )}
            <div>Created: {hoverInfo.createdAt}</div>
          </div>
        )}
      </div>
    </>
  );
};

export default Files;
