import { useState, useEffect, useRef } from "react";
import { storage, auth, db } from '../../../firebase';
import { getStorage, ref, uploadBytesResumable, getDownloadURL, list, listAll } from "firebase/storage";
import { getFunctions, httpsCallableFromURL, httpsCallable } from 'firebase/functions';
import PayerSelection from '../../Payers/PayerSelection';
import React from 'react';
import CollapsibleSection from "../../Claims/Subcomponents/CollapsibleSection";
import { doc, collection, query, where, orderBy, onSnapshot, getDoc, getDocs, limit } from 'firebase/firestore';
import styles from '../../Claims/Claims.css';
import ServiceLineTable from '../../Claims/Subcomponents/ServiceLineTable';
import HexSpinner from '../../General/Animations/Hexspinner';
import Confetti from '../../General/Animations/Confetti';
import ProviderSelection from '../../Providers/ProviderSelection';
import { ProviderProvider } from '../../../context/ProviderContext'
import useUID from '../../General/useUID'
import HelpArticleLink from "../../Articles/HelpArticleLink";
import useEmailVerified from "../../General/useEmailVerified";

function BatchClaims({ selectedPatientsData, onClose }) {
  console.log(selectedPatientsData)
  const [errorMessage, setErrorMessage] = useState(null);
  const [payerError, setPayerError] = useState("");
  const [activeSection, setActiveSection] = useState(1);
  const [rowCount, setRowCount] = useState(0);
  const [confirmationMessage, setConfirmationMessage] = useState(null);
  const [showSubmitButton, setShowSubmitButton] = useState(true);
  const [fileErrors, setFileErrors] = useState([]);
  const [errorTable, setErrorTable] = useState([]);
  const fileInputRef = useRef(null);
  const [AccountTier, setAccountTier] = useState('');
  const [hasRemainingRequests, setHasRemainingRequests] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [totalCharges, setTotalCharges] = useState(0);
  const [selectedBillingCodes, setSelectedBillingCodes] = useState([]);
  const [selectedServiceProviders, setSelectedServiceProviders] = useState([]);
  const [selectedRenderingProviders, setSelectedRenderingProviders] = useState([]);
  const [selectedBillingProviders, setSelectedBillingProviders] = useState([]);
  const [selectedReferringProviders, setSelectedReferringProviders] = useState([]);
  const [showReferringProviderButton, setShowReferringProviderButton] = useState(true);    const [DatesOfService, setDatesOfService] = useState("");
  const [filePath, setFilePath] = useState("");
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [successMessages, setSuccessMessages] = useState([]);
  const [successCount, setSuccessCount] = useState(0);
  const [failedPatients, setFailedPatients] = useState([]);
  const [totalApiRequests, setTotalApiRequests] = useState(0);
  const [isTestMode, setIsTestMode] = useState(false);
  const [payerNames, setPayerNames] = useState({});
  const [dateOfService, setDateOfService] = useState({}); 
  const [currentUser, setCurrentUser] = useState(null);
  const [AllPatientsData, setAllPatientsData] = useState(null);
  const [isValid, setIsValid] = useState(true);
  const [invalidPatientIndex, setInvalidPatientIndex] = useState(-1);
  const [validationError, setValidationError] = useState(null);
  const [invalidPatientIndices, setInvalidPatientIndices] = useState([]);
  const [validationErrors, setValidationErrors] = useState([]);
  const [errors2, setErrors2] = useState({});
  const [matchedPayerNames, setMatchedPayerNames] = useState({});
  const [convertedPayerData, setConvertedPayerData] = useState({});
  const [applyFirstDate, setApplyFirstDate] = useState(false);
  const [firstDateModified, setFirstDateModified] = useState(false);
  const [uid, subUserUID] = useUID();
  const isEmailVerified = useEmailVerified();

  const myRef = useRef(null);

    useEffect(() => {
      if (!uid) return;

      const fetchAccountTierAndCheckRemainingRequests = async () => {

        const userDocRef = doc(db, "users", uid);
        const userDocSnap = await getDoc(userDocRef);

        const AccountTier = userDocSnap.data().AccountTier || '';
        setAccountTier(AccountTier);

        const requestsRef = collection(db, "users", uid, "API");
        const q = query(requestsRef, orderBy("timestamp", "desc"));

        const unsubscribe = onSnapshot(q, (querySnapshot) => {
          let total = 0;
          querySnapshot.forEach((doc) => {
            const requestData = doc.data();

            if (requestData.APIRequests) {
              const apiRequestsCount = parseInt(requestData.APIRequests, 10);
              total += apiRequestsCount;
            }
          });

          setTotalApiRequests(total); // Set the state variable for totalApiRequests

          if (total >= 25 && AccountTier === 'Freebie') {
            setHasRemainingRequests(false);
          } else {
            setHasRemainingRequests(true);
          }
        });

        return () => unsubscribe();
      };

      if (uid) {
        fetchAccountTierAndCheckRemainingRequests();
      }
    }, [uid]);
    
    

    const handleSubmit = async (e) => {
      e.preventDefault();
  
      setErrorMessage(null);
      setPayerError("");
      setFailedPatients([]);
      setErrorTable([]);
      setSuccessMessages([]);
      setSuccessCount(0);
      setShowSuccessMessage(false);
  
      if (!isEmailVerified) {
          alert("Please verify your email before submitting claims.");
          return;
      }
  
      if (selectedPatientsData.length > 1000) {
          setErrorMessage("You can only process up to 1000 patients at a time.");
          return;  // this will exit the function early if there are more than 1000 patients
      }
  
      // Check if the number of claims exceeds 150
      if (selectedPatientsData.length > 150) {
          const totalSeconds = selectedPatientsData.length * 2.4;
          const minutes = Math.floor(totalSeconds / 60);
          const seconds = Math.round(totalSeconds % 60);
  
          const message = `It is estimated to take ${minutes} minutes and ${seconds} seconds to process your claims. You will get an email when complete. Thank you.`;
          setErrorMessage(message);
      }
  
      setIsLoading(true);
      setShowSubmitButton(false);
  
      const patientBatch = {};
  
      for (let index = 0; index < selectedPatientsData.length; index++) {
          const patientWrapper = selectedPatientsData[index].data.patient;
          const memberId = selectedPatientsData[index].data.patient.memberId || selectedPatientsData[index].data.payers.memberId;
  
          const {
              firstName,
              middleName,
              lastName,
              dob,
              gender,
              address,
          } = patientWrapper ?? {};
  
          const patientId = selectedPatientsData[index].id ?? {};
  
          let dos = dateOfService[index];
          if (dos) {
              dos = formatDateToMMDDYYYY(dos);
          }
  
          const dobFormatted = formatDateToMMDDYYYY(dob);
  
          const payersWrapper = selectedPatientsData[index].data.payers;
          const payerName = payersWrapper?.name;
          let payerId = payersWrapper?.CPID;
  
          // If no CPID in the patient's doc, search for it in the Payers collection
          if (!payerId && payerName) {
              const payersCollectionRef = collection(db, 'Payers');
              const payerQuery = query(payersCollectionRef, where('payerName', '==', payerName), limit(1));
              const payerQuerySnapshot = await getDocs(payerQuery);
              if (!payerQuerySnapshot.empty) {
                  const payerDoc = payerQuerySnapshot.docs[0].data();
                  payerId = payerDoc.CPID;
              }
          }
  
          patientBatch[index] = {
              firstName: firstName || '',
              middleName: middleName || '',
              lastName: lastName || '',
              dob: dobFormatted || '',
              gender: gender || '',
              address1: address?.address1 || '',
              address2: address?.address2 || '', 
              city: address?.city || '',
              state: address?.state || '',
              zip: address?.zip || '',
              memberId: memberId,
              dos: dos || '',
              payerName: payerName || '',
              patientId: patientId || '',
              payerId: payerId,
              selectedBillingCodes,
              totalCharges,
          };
      }
  
      const claimData = {
          patientBatch,
          uid,
          selectedServiceProviders,
          selectedRenderingProviders,
          selectedBillingProviders,
          selectedReferringProviders,
          usageIndicator: isTestMode ? 'T' : 'P'  // 'P' for production, 'T' for test
      };
  
      const totalRequests = rowCount;
      const isFreeTrial = AccountTier === 'Freebie';
      const reachedFreeTrialLimit = (totalApiRequests + totalRequests) > 25 && isFreeTrial;
      if (reachedFreeTrialLimit) {
          setErrorMessage(<span>You can only make up to 25 API requests in the free trial. You have made {totalApiRequests} requests, and you are trying to make {totalRequests} more. Please <a href="https://popularishealth.com/payment" target="_blank" rel="noopener noreferrer">upgrade your account</a> to continue.</span>);
          return;
      }
  
      const functions = getFunctions();
      const batchclaims = httpsCallable(functions, "batchClaimsJson");
  
      batchclaims({ claimData })
          .then((result) => {
              if (result.data.errors && result.data.errorMessage) {
                  setErrorMessage(result.data.errorMessage);
                  setFailedPatients(result.data.errors);
                  setShowSubmitButton(true); 
  
                  failedPatients.forEach(failedPatient => {
                  });
  
                  if (result.data.successCount && result.data.successCount > 0) {
                      // Set success messages and count only if there were successful claims
                      setSuccessMessages(result.data.successMessages);
                      setSuccessCount(result.data.successCount);
                  }
  
                  setShowSuccessMessage(true);
                  setIsLoading(false);
                  setShowSubmitButton(true);
              } else {
                  setConfirmationMessage('Your submission was successful');
                  setSuccessCount(result.data.successCount); // Set the success count
                  setIsLoading(false);
                  setShowSuccessMessage(true);
              }
          })
          .catch((error) => {
              console.log('Error calling batchClaims:', error);
              setShowSubmitButton(true); 
          });
  };
  

    const handleBillingProviderSelection = (data) => {
      setSelectedBillingProviders(data);
    };
  
    const handleUnifiedProviderSelection = (data) => {
      setSelectedServiceProviders(data);
      setSelectedRenderingProviders(data);
      // setselectedReferringProviders(providerData);
    };
  
    const handleReferringProviderSelection = (data) => {
      setSelectedReferringProviders(data);
    };
    
  const handleAddReferringProvider = () => {
    setShowReferringProviderButton(false);
  };
  
  const handleBillingCodesSelect = (selectedCodes) => {
    setSelectedBillingCodes(selectedCodes);
  };
  
  const handleTotalChargesChange = (charges) => {
    setTotalCharges(charges);
  };

const handleNext = () => {
    const errors = validateSelectedPatients();
    setValidationErrors(errors);

    if (Object.keys(errors).length === 0) {
        setActiveSection(prevSection => prevSection + 1);
    } else {
        console.log(`Validation failed for patients at indices: ${Object.keys(errors).join(', ')}`);
    }
}

const formatDateToMMDDYYYY = (dateString) => {
    const date = new Date(dateString);
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const year = date.getFullYear();

    return `${month}/${day}/${year}`;
};

const validateSelectedPatients = () => {
    let errors = {};

    selectedPatientsData.forEach((patientWrapper, index) => {
        let patientErrors = [];
        
        const patientData = patientWrapper?.data?.patient;
        const payerData = patientWrapper?.data?.payers;
        const { address } = patientData || {};
        
        // Check if address is missing
        if (!address || !address.address1) {
            patientErrors.push("Address is missing.");
        }

        // Check if memberId is missing in both patient and payer data
        const memberId = patientData?.memberId || payerData?.memberId;
        if (!memberId) {
            patientErrors.push("Member ID is missing.");
        }

        // Check if payer information is missing
        if (!payerData || !payerData.name) {
            patientErrors.push("Payer information is missing.");
        }

        if (patientErrors.length) {
            errors[index] = patientErrors;
        }
    });

    return errors;
}

useEffect(() => {
    const errors = validateSelectedPatients();
    setValidationErrors(errors);

    if (Object.keys(errors).length > 0) {
        setIsValid(false);
    } else {
        setIsValid(true);
    }
}, [selectedPatientsData, payerNames]);

const validateSection2 = () => {
    let sectionErrors = {};
    if (selectedBillingCodes.length === 0) sectionErrors.billingCode = "Billing code is required";
    setErrors2(sectionErrors);
    return Object.keys(sectionErrors).length === 0;
  }
  
  const canProceedToNextSection = () => {
    const isProviderSelected = (provider) => {
      const isSelected = provider && Object.keys(provider).length > 0;
      return isSelected;
    };
  
    const areAllProvidersSelected = isProviderSelected(selectedBillingProviders)
      && isProviderSelected(selectedServiceProviders)
      && isProviderSelected(selectedRenderingProviders)
      // && isProviderSelected(selectedReferringProviders);
      return areAllProvidersSelected;
  };

  const setAllDatesToFirstDate = () => {
    const firstDate = dateOfService[0];
    const updatedDates = {};

    selectedPatientsData.forEach((_, index) => {
        updatedDates[index] = firstDate;
    });

    setDateOfService(updatedDates);
};

useEffect(() => {
  if (applyFirstDate) {
      setAllDatesToFirstDate();
      setApplyFirstDate(false);
  }
}, [applyFirstDate]);

useEffect(() => {
  if (activeSection === 3) {
    myRef.current.scrollIntoView({ behavior: "smooth" });
  }
}, [activeSection]);



return (
    <div className="actionContainer">
        <div className="ClaimContainer">
        <button onClick={onClose} className="filesCloseButton">X</button>
            <div className="checkoutPatients">
                <CollapsibleSection
                    title="New Batch"
                    number={1}
                    activeSection={activeSection}
                    setActiveSection={setActiveSection}
                >
                    <div className="checkoutformContainer">
                        {selectedPatientsData && selectedPatientsData.length > 0 ? (
                            <>
                            <table className="patient-data-table">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Date of Birth</th>
                                        <th>Gender</th>
                                        <th>Address</th>
                                        <th>Member ID</th>
                                        <th>Insurance</th>
                                        <th>Date of Service</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {selectedPatientsData.map((patientWrapper, index) => {
                                    const { firstName, lastName, dob, gender, address } = patientWrapper?.data?.patient || {};
                                    let memberId = patientWrapper?.data?.patient?.memberId || patientWrapper?.data?.payers?.memberId;
                                    const { name } = patientWrapper?.data?.payers || {};
                                    const rowClass = validationErrors[index] ? 'invalid-row' : '';

                                        const handleDateChange = (e, rowIndex) => {
                                          const updatedDates = { ...dateOfService };
                                          updatedDates[rowIndex] = e.target.value;
                                          setDateOfService(updatedDates);
                                      
                                          if (rowIndex === 0) {
                                              setFirstDateModified(true);
                                          }
                                      };
                                      
                                        
                                        if (!dateOfService[index]) {
                                            const today = new Date().toISOString().split('T')[0];
                                            setDateOfService(prev => ({ ...prev, [index]: today }));
                                        }

                                        return (
                                            <>
                                                <tr key={index} className={rowClass}>
                                                    <td>{`${firstName} ${lastName}`}</td>
                                                    <td>{dob}</td>
                                                    <td>{gender}</td>
                                                    <td>{`${address?.address1 ?? ''}, ${address?.city ?? ''}, ${address?.state ?? ''}, ${address?.zip ?? ''}`}</td>
                                                    <td>{memberId}</td>
                                                    <td>{name}</td>
                                                    <td>
                                                      <input type="date" value={dateOfService[index]} onChange={(e) => handleDateChange(e, index)} />
                                                      {index === 0 && firstDateModified && (
                                                          <button onClick={setAllDatesToFirstDate}>
                                                              Set All Dates
                                                          </button>
                                                      )}
                                                  </td>
                                                </tr>
                                                {validationErrors[index] && (
                                                    <tr key={`error-${index}`} className="error-row">
                                                        <td colSpan="7">
                                                            {validationErrors[index].map((error, eIdx) => (
                                                                <p key={eIdx} className="inline-error-message">{error}</p>
                                                            ))}
                                                        </td>
                                                    </tr>
                                                )}
                                            </>
                                        );
                                    })}
                                </tbody>

                            </table>
                            <button className="next-btn" onClick={handleNext} disabled={!isValid}>Next</button>
                            </>
                        ) : (
                            <p>No patient data available.</p>
                        )}
                    {validationErrors.length > 0 && (
                        <div className="error-messages">
                            {validationErrors.map((error, idx) => <p key={idx} className="validation-message">{error}</p>)}
                        </div>
                    )}
                    {errorTable.length > 0 && (
                        <div className="error-table-container">
                        <h3>Errors found in the file:</h3>
                        <table>
                            <thead>
                            <tr>
                                <th>Row</th>
                                <th>Error Message</th>
                            </tr>
                            </thead>
                            <tbody>
                            {errorTable.map((error, index) => (
                                <tr key={index}>
                                <td>{error.row+1}</td>
                                <td>{error.message}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                        </div>
                    )}
        </div>
      </CollapsibleSection>
      <CollapsibleSection
            title="Codes"
            number={2}
            activeSection={activeSection}
            setActiveSection={setActiveSection}
            >
            
                    <ServiceLineTable
                    onBillingCodesSelect={handleBillingCodesSelect}
                    onTotalChargesChange={handleTotalChargesChange}
                    required
                    />
                   
        <div className='errors2Section'>
            {errors2.billingCode && <div className="error-text">{errors2.billingCode}</div>}
        </div>


        <button
              type="button"
              onClick={() => {
                if (validateSection2()) {
                  setActiveSection((prevSection) => prevSection + 1);
                }
              }}
            >
              Next
            </button>           
          </CollapsibleSection>
          <CollapsibleSection
          ref={myRef}
          title="Provider"
          number={3}
          activeSection={activeSection}
          setActiveSection={setActiveSection}
          style={{ paddingLeft: 0 }}
      >
        <ProviderProvider uid={uid}>
        <h3>a. Select a Billing Provider</h3>
          <h4>The important one. Add medicare number, medicaid number, and CLIA here.</h4>
          <ProviderSelection
              type="billing"
              onProviderSelect={handleBillingProviderSelection}
          />
       <div style={{ height: '1px', backgroundColor: 'black', marginTop: '10px', marginBottom: '10px' }}></div>
        <h3>b. Select a Service Provider</h3>
          <h4>Servicing and rendering provider.</h4>              
          <ProviderSelection
          type="unified"
          onProviderSelect={handleUnifiedProviderSelection}
              />

          {!showReferringProviderButton && (              <>
                          <h3>c. Select a Refering Provider</h3>
                            <h4>Servicing and rendering provider.</h4>
                            <ProviderSelection
                              type="refering"
                              onProviderSelect={handleReferringProviderSelection}
                            />
                      </>
                    )}

                      {showReferringProviderButton && (
                        <button type="button" onClick={handleAddReferringProvider}>
                          Add Referring Provider
                        </button>
                      )}

            <div className="help-article-wide">
              <HelpArticleLink 
                      article={{ 
                      title: 'Adding Providers', 
                      link: 'https://popularishealth.com/article/Adding-Providers' 
                      }} 
                      />
                  </div>
          <div className={styles.nextButtonContainer}>
              <button
                  type="button"
                  disabled={!canProceedToNextSection()}
                  onClick={() => {
                      setActiveSection(activeSection + 1);
                  }}
              >
                  Next
              </button>
              {!canProceedToNextSection() && <span className="error-text">Please select all providers</span>}

          </div>
          </ProviderProvider>

      </CollapsibleSection>

      <CollapsibleSection
          title="Review and Submit"
          number={4}
          activeSection={activeSection}
          setActiveSection={setActiveSection}
        >
          <div className="reviewAndSubmit">
            <p style={{ borderBottom: '1px solid black' }}>
                Number of claims: {selectedPatientsData.length}
            </p>            
            <div className={styles.reviewItem}><span className={styles.lightText}>Billing Provider:</span> <strong>{selectedBillingProviders.firstName}{' '}{selectedBillingProviders.lastName}{' | NPI:'}{selectedBillingProviders.npi}</strong></div>
            <div className={styles.reviewItem}><span className={styles.lightText}>Service Provider:</span> <strong>{selectedServiceProviders.firstName}{' '}{selectedServiceProviders.lastName}{' | NPI:'}{selectedServiceProviders.npi}</strong></div>
            <div className={styles.reviewItem}>
              <span className={styles.lightText}>Diagnosis Codes:</span> 
              <strong>
                {
                  selectedBillingCodes.map(code => code.diagnosisCode.join(', ')).join('; ')
                }
              </strong>
            </div>
            <div className={styles.reviewSection}>
                <h4>Service Lines</h4>
                <table className={styles.serviceLinesTable}>
                  <thead>
                    <tr>
                      <th>Code</th>
                      <th>Name</th>
                      <th>Diagnosis Code</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedBillingCodes.map((code, index) => (
                      <tr key={index}>
                        <td>{code.code}</td>
                        <td>{code.name}</td>
                        <td>{code.diagnosisCode.join(', ')}</td>
                        <td>${code.billAmount.toFixed(2)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

           
            
            <div className="button-container">
            <div className={styles.searchButtonContainer}>
              {isLoading ? (
                <HexSpinner />
              ) : (
                <div>
                {successCount > 0 && (
                        <div>
                            <h3>
                                The batch of claims have been successfully submitted.
                            </h3>
                            <p>Successful submissions: {successCount}. </p>
                            <button onClick={() => window.location.href = '/claims'}>
                                View in Claims
                            </button>
                            <Confetti />
                        </div>
                    )}
                {/* Display errors for failed patients */}
                {errorMessage && 
                    <div>
                        <h3>Failed Patients</h3>
                        <table>
                            <thead>
                                <tr>
                                    <th>First Name</th>
                                    <th>Last Name</th>
                                    <th>Error</th>
                                </tr>
                            </thead>
                            <tbody>
                                {failedPatients.map(patient => 
                                    <tr key={`${patient.requestData.subscriber.firstName}-${patient.requestData.subscriber.lastName}`}>
                                        <td>{patient.requestData.subscriber.firstName}</td>
                                        <td>{patient.requestData.subscriber.lastName}</td>
                                        <td>{patient.error}</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                }


                {/* Show submit button when not loading and no success message is shown */}
                {!isLoading && showSubmitButton && 
                 <>
                  <div>
                  <button type="button" onClick={handleSubmit}>
                    Submit Claims
                  </button>
                  </div>
                  <div className="switchTestModeContainer">
                      <label className="switch">
                      <input type="checkbox" checked={isTestMode} onChange={() => setIsTestMode(!isTestMode)} />
                      <span className="slider round"></span>
                    </label>
                    <p>{isTestMode ? 'Test Mode is ON' : 'Test Mode is OFF'}</p>
                </div>
                </>
                }
            </div>

              )}
            </div>
          </div>
          </div> 
        </CollapsibleSection>
        {errorMessage && <div className="error-message">{errorMessage}</div>}
        {payerError && <div className="error-message">{payerError}</div>}
        <div className="help-article-wide">
              <HelpArticleLink 
              article={{ 
              title: 'Billing from Patients', 
              link: 'https://popularishealth.com/article/Billing-for-Patients' 
              }} 
            />
         </div>
      </div>
    </div>
 </div>
);


}
export default BatchClaims;

