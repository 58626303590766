import React, { useState, useEffect } from "react";
import { getFirestore, collection, getDocs, doc } from 'firebase/firestore';
import "./styles/ai.modules.css";
import Topbar from "../General/Topbar";
import Chat from "./chat";
import Documents from "./Documents";
import { auth } from '../../firebase';
import useUID from "../General/useUID";

const db = getFirestore();

function AI() {
  const [isDatabaseComplete, setIsDatabaseComplete] = useState(false); // State to track database status
  const [isLoading, setIsLoading] = useState(true); // State to track loading status
  const [uid, subUserUID] = useUID();

  
  useEffect(() => {
    if (!uid) return;

    const fetchData = async () => {
      const aiLogsRef = collection(doc(db, 'users', uid), 'aiLogs');
      const snapshot = await getDocs(aiLogsRef);
      
      snapshot.forEach((doc) => {
        if (doc.data().log === 'Preloaded vector database complete') {
          setIsDatabaseComplete(true);
        }
      });

      setIsLoading(false);
    };

    fetchData();
  }, [uid]);

  // While the data is loading
  if (isLoading) {
    return null; // Or return a loading indicator
  }


  // If the database is ready
  return (
    <div className="aibackground">
      <Topbar />
      <div className="chat">
      <Chat special={false} />
      </div>
      {/* <div className="sidebarContainer">
        <Documents />
      </div> */}
    </div>
  );
}

export default AI;
