import React, { useState, useEffect, useCallback, useRef } from 'react';
import { db } from '../../../firebase';
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faCircle } from '@fortawesome/free-solid-svg-icons';
import useUID from "../../General/useUID";

const CallbackSelection = ({ onSelect }) => {
    const [apiSpecs, setApiSpecs] = useState([]);
    const [selectedCallback, setSelectedCallback] = useState(null);
    const onSelectRef = useRef(onSelect);
    const [uid, subUserUID] = useUID();

    useEffect(() => {
        onSelectRef.current = onSelect; // Keep the ref updated
    }, [onSelect]);

    useEffect(() => {
        if (!uid) {
            setApiSpecs([]);
            setSelectedCallback(null);
            return;
        }
    
        const apiSpecsRef = collection(db, 'APISpecs');
        const q = query(apiSpecsRef, where('UserID', '==', uid)); // ✅ Filter by UserID
    
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const specs = querySnapshot.docs.map(doc => {
                const data = doc.data();
                return {
                    id: doc.id,
                    ...data,
                    messageMappings: data.messageMappings?.default || data.messageMappings || {}
                };
            });
    
            console.log("📌 Encounter Callbacks for Current User:", specs);
            setApiSpecs(specs);
        });
    
        return () => unsubscribe();
    }, [uid]);
    

    useEffect(() => {
        onSelectRef.current(selectedCallback ? [selectedCallback] : []);
    }, [selectedCallback]);

    const handleCallbackSelection = useCallback((callback) => {
        setSelectedCallback(prev => (prev?.id === callback.id ? null : callback));
    }, []);

    return (
        <div className='callbacks-container'>
            {apiSpecs.length > 0 && (
                <label className="mt-4">Select a destination for the encounter via API (optional):</label>
            )}
            {apiSpecs.map((spec) => (
                <div 
                    onClick={() => handleCallbackSelection(spec)} 
                    key={spec.id} 
                    className={`Card ${selectedCallback?.id === spec.id ? 'selected' : ''}`}
                >
                    <div className="callbacks-content-list">
                        <FontAwesomeIcon 
                            icon={selectedCallback?.id === spec.id ? faCheckCircle : faCircle} 
                            className="selection-icon" 
                        />
                        <h4>{spec.Name || 'Unnamed Callback'}</h4>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default CallbackSelection;
