import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PaymentFormWrapper from './PaymentForm';
import PricingTable from './PricingTable';
import { getFirestore, doc, setDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import useUID from "../General/useUID";

function SignupStripe() {
  const navigate = useNavigate();
  const [showForm, setShowForm] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [selectedPlanDetails, setSelectedPlanDetails] = useState(null);
  const [uid, subUserUID] = useUID();

  const showPaymentForm = async (plan) => {
    if (plan.name === "Freebie") {
      const auth = getAuth();
      const db = getFirestore();
      const userDocRef = doc(db, "users", uid);

      await setDoc(userDocRef, { AccountTier: "Freebie" }, { merge: true });

      navigate("/patients");
    } else {
      setSelectedPlan({ plan: plan.name, details: plan.details, price: plan.price });
      setShowForm(true);
    }
  };

  const closePaymentForm = () => {
    setShowForm(false);
  };

  return (
    <div className="background">
      <div className='pricingContainerAuth'>
      <div className="AUTH-container-PRICING">
        <PricingTable showPaymentForm={showPaymentForm} />
      </div>
      </div>
      {showForm && (
        <div className="payment-form-popup">
          <div className="popup-overlay" onClick={closePaymentForm} />
          <PaymentFormWrapper plan={selectedPlan.plan} planDetails={selectedPlan.details} price={selectedPlan.price} />
        </div>
      )}
    </div>
  );
}

export default SignupStripe;
