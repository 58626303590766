// src/components/BillingCodes/BillingCodeCard.jsx
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import ICD10 from "./ICD10";
function BillingCodeCard({
  billingCode,
  isSelected,
  onSelect,
  onEdit,
  onDelete,
  onDiagnosisCodeSelect,
  userRole, // new prop to pass the user's role
}) {
  return (
    <div
      className={`w-90 content-center py-4 px-2 ${
        isSelected ? "blue-card card-selected" : "white-card"
      }`}
      onClick={() => onSelect(billingCode.id)}
    >
      <h4>{billingCode.name}</h4>
      <p>
        Billing Code: <strong>{billingCode.code}</strong>
      </p>
      {/* Only show bill amount if user is not a nurse */}
      {billingCode.billAmount && userRole !== "nurse" && (
        <p>
          Bill Amount:{" "}
          <strong>
            $
            {billingCode.billAmount
              ? parseFloat(billingCode.billAmount).toFixed(2)
              : "0.00"}
          </strong>
        </p>
      )}
      {billingCode.modifierA && (
        <p>
          Modifier A: <strong>{billingCode.modifierA}</strong>
        </p>
      )}
      {billingCode.modifierB && (
        <p>
          Modifier B: <strong>{billingCode.modifierB}</strong>
        </p>
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "10px",
        }}
      >
        <button
          className={isSelected ? "primary" : "secondary"}
          onClick={(e) => {
            e.stopPropagation();
            onEdit(billingCode);
          }}
          title="Edit codes"
        >
          <FontAwesomeIcon className="m-0" icon={faEdit} />
        </button>
        <button
          className={isSelected ? "primary" : "secondary"}
          onClick={(e) => {
            e.stopPropagation();
            onDelete(billingCode.id);
          }}
          title="Delete code"
        >
          <FontAwesomeIcon className="m-0" icon={faTrash} />
        </button>
      </div>
      {isSelected && (
        <div className="diagnosis-code-selector">
          <h5>Select Diagnosis Codes</h5>
          <ICD10
            billingCodeId={billingCode.id}
            onCodeSelect={(selectedCodes) =>
              onDiagnosisCodeSelect(billingCode.id, selectedCodes)
            }
            initialSelectedCodes={
              billingCode.diagnosisCode
                ? billingCode.diagnosisCode.map((code) => ({ code }))
                : []
            }
          />
        </div>
      )}
    </div>
  );
}

export default BillingCodeCard;
