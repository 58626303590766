import React, { useEffect, useState, useRef } from 'react';
import {
  collection,
  query,
  getDocs,
  orderBy,
  where,
  doc,
  updateDoc,
  getDoc,
  limit,
  startAfter,
} from 'firebase/firestore';
import useUID from '../../General/useUID';
import { db } from "../../../firebase";
import Topbar from '../../General/Topbar';
import { Calendar, momentLocalizer } from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import AppointmentDetails from './AppointmentDetails';
import Modal from "react-modal";
import { useNavigate, useParams } from 'react-router-dom';
import { parse } from "date-fns";
import HexagonSpinner from '../../General/Animations/Hexspinner';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { getFunctions, httpsCallable } from "firebase/functions";

const localizer = momentLocalizer(moment);
const DnDCalendar = withDragAndDrop(Calendar);

Modal.setAppElement('#root');

function AppointmentsCalendar() {
  const { clinicIdURL } = useParams();
  const [uid] = useUID();
  const [appointments, setAppointments] = useState([]);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalStyle, setModalStyle] = useState({});
  const [clinics, setClinics] = useState([]);
  const [selectedClinic, setSelectedClinic] = useState(clinicIdURL || 'all');
  const [loading, setLoading] = useState(true);

  const functions = getFunctions();
  const decryptFunction = httpsCallable(functions, "decrypt");
  const navigate = useNavigate();

  useEffect(() => {
    if (!uid) return;

    const fetchClinics = async () => {
      try {
        const clinicsRef = collection(db, "clinics");
        const clinicsQuery = query(clinicsRef, where("ownerId", "==", uid));
        const clinicSnapshot = await getDocs(clinicsQuery);

        if (!clinicSnapshot.empty) {
          const clinicsData = clinicSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setClinics(clinicsData);
        }
      } catch (error) {
        console.error("Error fetching clinics:", error);
      }
    };

    fetchClinics();
  }, [uid]);

  useEffect(() => {
    if (!uid || clinics.length === 0) return;

    const fetchAppointments = async () => {
      try {
        const allAppointments = [];

        for (const clinic of clinics) {
          if (clinicIdURL && clinic.id !== clinicIdURL && clinicIdURL !== 'all') continue;
          const appointmentsRef = collection(db, "clinics", clinic.id, "appointments");
          const appointmentsQuery = query(appointmentsRef, orderBy("time", "desc"));
          const appointmentsSnapshot = await getDocs(appointmentsQuery);

          if (!appointmentsSnapshot.empty) {
            const clinicAppointments = await Promise.all(
              appointmentsSnapshot.docs.map(async (appointmentDoc) => {
                const data = appointmentDoc.data();
                const dateString = `${data.date} ${new Date().getFullYear()} ${data.time}`;
                const start = parse(dateString, "MMMM do yyyy HH:mm", new Date());
                const end = new Date(start.getTime() + (data.appointmentDuration || 30) * 60000);

                if (isNaN(start) || isNaN(end)) {
                  console.error(`Invalid date for appointment ID: ${appointmentDoc.id}`, data);
                  return null;
                }

                return {
                  id: appointmentDoc.id,
                  title: `${data.appointmentType}`,
                  start,
                  end,
                  allDay: false,
                  clinicName: clinic.clinicName,
                  ...data,
                  clinicId: clinic.id,
                };
              })
            );

            allAppointments.push(...clinicAppointments.filter((app) => app !== null));
          }
        }

        setAppointments(allAppointments);
      } catch (error) {
        console.error("Error fetching appointments:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAppointments();
  }, [uid, clinics, clinicIdURL]);

  const handleEventDrop = async ({ event, start }) => {
  
    // Recompute the end time based on the duration or use event.end
    // If you want to keep the same duration, do something like:
    const durationInMinutes = event.appointmentDuration || 30; 
    const newEnd = moment(start).add(durationInMinutes, 'minutes').toDate();
  
    // Build a new event object with start/end included
    const updatedEvent = {
      ...event,
      start: new Date(start),  // ensure this is a Date object
      end: newEnd,
      appointmentDuration: durationInMinutes,
      date: moment(start).format("MMMM Do"),
      time: moment(start).format("HH:mm"),
      timeZone: event.timeZone, // if needed
    };
  
    // Update local state
    setAppointments((prevAppointments) =>
      prevAppointments.map((appt) => 
        appt.id === event.id ? updatedEvent : appt
      )
    );
  
    // Update Firestore with whichever fields you want to store
    try {
      const appointmentRef = doc(
        db,
        "clinics",
        event.clinicId,
        "appointments",
        event.id
      );
      await updateDoc(appointmentRef, {
        appointmentDuration: updatedEvent.appointmentDuration,
        appointmentType: updatedEvent.appointmentType,
        patientId: updatedEvent.patientId,
        date: updatedEvent.date,
        time: updatedEvent.time,
        timeZone: updatedEvent.timeZone,
        // You might not store 'start' and 'end' directly in Firestore,
        // but you do store date/time so you can reconstruct them later.
      });
    } catch (error) {
      console.error("Error updating appointment in Firestore:", error);
    }
  };
  

  const handleEventResize = async ({ event, start, end }) => {
  
    // Calculate new duration based on the difference
    const updatedDuration = Math.ceil((end - start) / (1000 * 60)); // minutes
  
    // Build a new event object with start/end included
    const updatedEvent = {
      ...event,
      start: new Date(start),
      end: new Date(end),
      appointmentDuration: updatedDuration,
      date: moment(start).format("MMMM Do"),
      time: moment(start).format("HH:mm"),
      timeZone: event.timeZone,
    };
  
    // Update local state
    setAppointments((prevAppointments) =>
      prevAppointments.map((appt) =>
        appt.id === event.id ? updatedEvent : appt
      )
    );
  
    try {
      const appointmentRef = doc(
        db,
        "clinics",
        event.clinicId,
        "appointments",
        event.id
      );
      await updateDoc(appointmentRef, {
        appointmentDuration: updatedDuration,
        appointmentType: updatedEvent.appointmentType,
        patientId: updatedEvent.patientId,
        date: updatedEvent.date,
        time: updatedEvent.time,
        timeZone: updatedEvent.timeZone,
      });
    } catch (error) {
      console.error("Error resizing appointment in Firestore:", error);
    }
  };
  
  
  

  const handleSelectEvent = (event, e) => {
    setSelectedAppointment(event);
    const rect = e.target.getBoundingClientRect();
  
    // Get viewport dimensions
    const viewportHeight = window.innerHeight;
    const viewportWidth = window.innerWidth;
  
    // Calculate initial position
    let top = rect.bottom + 10; // Add some space below the clicked element
    let left = rect.left;
  
    // Adjust position if the modal would overflow the viewport
    const modalHeight = 300; // Estimate the modal height (or calculate dynamically if possible)
    const modalWidth = 400; // Estimate the modal width (or calculate dynamically if possible)
  
    if (top + modalHeight > viewportHeight) {
      top = viewportHeight - modalHeight - 10; // Position it within the viewport with some padding
    }
  
    if (left + modalWidth > viewportWidth) {
      left = viewportWidth - modalWidth - 10; // Adjust left to fit within the viewport
    }
  
    if (left < 10) {
      left = 10; // Ensure it doesn't go off the left edge
    }
  
    const modalStyle = {
      position: 'absolute',
      top: `${top}px`,
      left: `${left}px`,
      transform: 'translate(-50%, 0)',
    };
  
    setModalStyle(modalStyle);
    setModalIsOpen(true);
  };
  

  const closeModal = () => setModalIsOpen(false);

  const filteredAppointments = selectedClinic === 'all'
    ? appointments
    : appointments.filter((appointment) => appointment.clinicId === selectedClinic);

  const handleClinicChange = (e) => {
    const clinicId = e.target.value;
    setSelectedClinic(clinicId);
    navigate(`/appointmentscalendar/${clinicId === 'all' ? 'all' : clinicId}`);
  };

  const modalRef = useRef(null);

  
  const fetchAndBatchDecryptPatients = async () => {
    try {
      let allEncryptedPatients = [];
      let lastDoc = null;

      while (true) {
        const queryConstraints = [
          where("deleted", "==", false),
          orderBy("__name__"),
          limit(50),
        ];
        if (lastDoc) queryConstraints.push(startAfter(lastDoc));

        const patientQuery = query(
          collection(db, `patients/${uid}/patientData`),
          ...queryConstraints
        );
        const querySnapshot = await getDocs(patientQuery);

        if (querySnapshot.empty) break;

        allEncryptedPatients.push(
          ...querySnapshot.docs.map((doc) => ({
            id: doc.id,
            encryptedData: doc.data().patient,
          }))
        );

        lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];

        if (querySnapshot.docs.length < 50) break;
      }


      const batchDecrypt = httpsCallable(functions, "batchDecryptPatients");
      const decryptedResult = await batchDecrypt({
        patients: allEncryptedPatients.map((p) => p.encryptedData),
      });

      const decryptedPatients = decryptedResult.data.decryptedPatients.map(
        (decrypted, index) => ({
          id: allEncryptedPatients[index].id,
          data: decrypted,
        })
      );

      return decryptedPatients;
    } catch (error) {
      console.error("Error batch decrypting patients:", error);
      return [];
    }
  };

  const fetchIntakeForms = async (clinicId, patientId) => {
    try {
      const intakeFormsRef = collection(db, `clinics/${clinicId}/intakeForms`);
      const intakeFormsQuery = query(intakeFormsRef, where("patientId", "==", patientId));
      const intakeFormsSnapshot = await getDocs(intakeFormsQuery);

      if (!intakeFormsSnapshot.empty) {
        const decryptedForms = await Promise.all(
          intakeFormsSnapshot.docs.map(async (docSnap) => {
            const { ciphertext, iv } = docSnap.data();
            if (ciphertext && iv) {
              const decryptedResponse = await decryptFunction({ ciphertext, iv });
              return decryptedResponse.data.data || {};
            }
            return {};
          })
        );
        return decryptedForms;
      }
      return [];
    } catch (error) {
      console.error("Error fetching intake forms:", error);
      return [];
    }
  };

 
// Function to remove ordinal suffix
const removeOrdinalSuffix = (dateString) => {
  return dateString.replace(/(\d+)(st|nd|rd|th)/, "$1");
};

const generateCSV = async () => {
  if (appointments.length === 0) {
    alert("No appointments to export.");
    return;
  }

  try {
    const decryptedPatients = await fetchAndBatchDecryptPatients();

    const csvData = await Promise.all(
      appointments.map(async (appointment) => {

        // Preprocess the date to remove ordinal suffix
        const cleanedDate = removeOrdinalSuffix(appointment.date);

        const combinedDateTime = `${cleanedDate} ${new Date().getFullYear()} ${appointment.time}`;

        let formattedAppointmentDate = "";
        try {
          formattedAppointmentDate = moment(combinedDateTime, "MMMM D YYYY HH:mm").format("MM/DD/YYYY");
        } catch (error) {
          console.error("Error formatting appointment date:", error);
        }

        const patient = decryptedPatients.find(
          (p) => p.id === appointment.patientId
        );

        const intakeForms = await fetchIntakeForms(appointment.clinicId, appointment.patientId);
        const intakeFormFields = intakeForms
          .flatMap((form) => form)
          .map((input) =>
            input.fields
              .filter((item) => item.isVisible)
              .reduce(
                (acc, item) => ({
                  ...acc,
                  [item.label]: item.value,
                }),
                {}
              )
          )
          .reduce((acc, obj) => ({ ...acc, ...obj }), {});

        return {
          firstName: patient?.data?.patient?.firstName || "",
          middleName: patient?.data?.patient?.middleName || "",
          lastName: patient?.data?.patient?.lastName || "",
          dateOfBirth: patient?.data?.patient?.dob || "",
          gender: patient?.data?.patient?.gender || "",
          address1: patient?.data?.patient?.address?.address1 || "",
          address2: patient?.data?.patient?.address?.address2 || "",
          city: patient?.data?.patient?.address?.city || "",
          state: patient?.data?.patient?.address?.state || "",
          zip: patient?.data?.patient?.address?.zip || "",
          payer: patient?.data?.payers?.name || "",
          memberId: patient?.data?.payers?.memberId || "",
          appointmentDate: formattedAppointmentDate,
          ...intakeFormFields, // Include intake form fields
        };
      })
    );

    // Convert JSON to CSV
    const worksheet = XLSX.utils.json_to_sheet(csvData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Appointments");

    const csvBlob = new Blob([XLSX.write(workbook, { bookType: "csv", type: "array" })], {
      type: "text/csv;charset=utf-8;",
    });
    saveAs(csvBlob, `appointments_${selectedClinic}.csv`);
  } catch (error) {
    console.error("Error generating CSV:", error);
    alert("Failed to generate CSV.");
  }
};

  return (
    <>
      <Topbar />
      <div style={{ marginTop: '3rem' }} className="calendarContainer">
        <div className="closeButtonContainer">
          <button onClick={() => navigate('/patients')} className="filesCloseButton">X</button>
          <p className="closeBarNav">Appointments Calendar</p>
        </div>
        <div>
          <div className="showByDropdown">
            <label>
              Select a Clinic:
              <select value={selectedClinic} onChange={handleClinicChange} style={{ marginLeft: "1rem" }}>
                <option value="all">All Clinics</option>
                {clinics.map((clinic) => (
                  <option key={clinic.id} value={clinic.id}>
                    {clinic.clinicName}
                  </option>
                ))}
              </select>
            </label>  <button onClick={generateCSV} className="downloadButton">
        Download CSV
      </button>
          </div>
 
          <div style={{ height: 'calc(100vh - 17rem)' }}>
            {loading ? (
              <HexagonSpinner />
            ) : (
              <DnDCalendar
                localizer={localizer}
                events={filteredAppointments}
                startAccessor="start"
                endAccessor="end"
                defaultView="week"
                onSelectEvent={handleSelectEvent}
                onEventDrop={handleEventDrop}
                onEventResize={handleEventResize}
                resizableAccessor={() => true}
                draggableAccessor={() => true}
              />
            )}
          </div>
        </div>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Appointment Details"
          className="calendarModal"
          style={{ content: modalStyle }}
          ref={modalRef}
        >
          <button onClick={closeModal} className="filesCloseButton">X</button>
          {selectedAppointment && <AppointmentDetails appointment={selectedAppointment} />}
        </Modal>

      </div>
    </>
  );
}

export default AppointmentsCalendar;
