import React, { useState, useEffect } from "react";
import QRCode from "qrcode.react";
import Modal from "react-modal";
import HexagonSpinner from "../../General/Animations/Hexspinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHospital,
  faEdit,
  faTrash,
  faCopy,
  faCalendar,
  faCaretDown
} from "@fortawesome/free-solid-svg-icons";

import useUID from "../../General/useUID";
import {
  collection,
  doc,
  onSnapshot,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../../../firebase";
import ClinicEditModal from "./ClinicEditModal";
import ClinicIntakeModal from "./ClinicIntakeModal";
import HelpArticleLink from "../../Articles/HelpArticleLink";
import AddClinic from "./AddClinic";
import { useNavigate } from "react-router-dom";

Modal.setAppElement("#root");

function ClinicsPopup({toggleClinicPopup, isAddClinicOpen }) {
  const [isLoading, setIsLoading] = useState(false);
  const [clinics, setClinics] = useState([]);
  const [uid] = useUID();
  const [selectedClinic, setSelectedClinic] = useState(null);
  const [modalType, setModalType] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [clinicToDelete, setClinicToDelete] = useState(null);
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth < 708);
    }
    window.addEventListener("resize", handleResize);
    // Trigger on mount
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const getClinics = () => {
    const clinicsSnapshot = collection(db, "clinics");
    const q = query(
      clinicsSnapshot,
      where("ownerId", "==", uid)
    );

    const unsubscribe = onSnapshot(
      q,
      (snapshot) => {
        const clinicsList = [];
        snapshot.forEach((doc) => {
          const clinicData = doc.data();
          const createdDate = clinicData.created
            ? clinicData.created.toDate()
            : null;

          // Treat missing `deleted` field as `false`
          if (!clinicData.deleted || clinicData.deleted === false) {
            clinicsList.push({
              id: doc.id,
              name: clinicData.clinicName,
              link: clinicData.link,
              created: createdDate,
              formData: clinicData.formData,
              appointmentsEnabled: clinicData.appointmentsEnabled,
            });
          }
        });
        setClinics(clinicsList);
      },
      (error) => {
        console.error("Error fetching clinics:", error);
      }
    );

    return unsubscribe;
  };


  useEffect(() => {
    const unsubscribe = getClinics();
    return unsubscribe;
  }, [uid]);

  const downloadQRCode = (id, clinicName) => {
    const canvas = document.getElementById(id);
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    const downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    // Use the clinic name for the file name, replacing spaces with underscores
    downloadLink.download = `${clinicName.replace(/\s+/g, "_")}_QRCode.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const handleDeleteConfirmation = (clinicId) => {
    setClinicToDelete(clinicId);
    setShowDeleteConfirmation(true);
  };

  const handleCancelDelete = () => {
    setClinicToDelete(null);
    setShowDeleteConfirmation(false);
  };

  const handleConfirmDelete = async () => {
    if (clinicToDelete) {
      try {
        const clinicRef = doc(db, "clinics", clinicToDelete);
        await updateDoc(clinicRef, { deleted: true }); // Soft delete the clinic
        setClinics(clinics.filter((clinic) => clinic.id !== clinicToDelete));
      } catch (error) {
        console.error("Error soft deleting clinic:", error);
      }
      setClinicToDelete(null);
      setShowDeleteConfirmation(false);
    }
  };

  const handleEditClinic = (clinic) => {
    setSelectedClinic(clinic);
    setModalType("edit");
  };

  const handleEditIntake = (clinic) => {
    setSelectedClinic(clinic);
    setModalType("intake");
  };

  const handleViewAppointments = (clinicId) => {
    navigate(`/appointmentscalendar/${clinicId}`);
  };

  const handleViewRoster = (clinicId) => {
    navigate(`/intakeview/${clinicId}`);
  };

  return (
    <div className="addClinic">
      {/* Intake Modal */}
      {modalType === "intake" && (
        <ClinicIntakeModal
          selectedClinic={selectedClinic}
          setSelectedClinic={setSelectedClinic}
          onClose={() => setModalType(null)}
        />
      )}

      {/* Edit Modal */}
      {modalType === "edit" && (
        <ClinicEditModal
          selectedClinic={selectedClinic}
          setSelectedClinic={setSelectedClinic}
          onClinicsUpdated={() => { }}
          onClose={() => setModalType(null)}
        />
      )}

      {/* Delete Confirmation Modal */}
      <Modal
        isOpen={showDeleteConfirmation}
        onRequestClose={handleCancelDelete}
        className="confirmModal"
        overlayClassName="confirmModalOverlay"
      >
        <h2>Are you sure you want to delete this clinic?</h2>
        <div className="confirmButtons">
          <button onClick={handleConfirmDelete} className="dangerButton">
            Yes
          </button>
          <button onClick={handleCancelDelete} className="secondaryButton">
            No
          </button>
        </div>
      </Modal>

      {/* The popup containing clinics table or list */}
      {isAddClinicOpen && (
        <div className="popupContainer">
          <div className="clinicWindow">
            <div className="closeButtonContainer">
              <button onClick={toggleClinicPopup} className="filesCloseButton">
                X
              </button>
              <p className="closeBarNav">Clinics</p>
            </div>

            <div className="newClinic">
              <AddClinic />
              {isLoading && <HexagonSpinner />}
            </div>

            <div className="clinicsList">
              {/* Table for desktop; single-col or condensed for mobile */}
              <table>
                {/* Render table header only if not mobile */}
                {!isMobile && (
                  <thead>
                    <tr>
                      <th>Clinic Name</th>
                      <th>Clinic Link</th>
                      <th>QR Code</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                )}

                <tbody>
                  {clinics.map((clinic) => (
                    <React.Fragment key={clinic.id}>
                      {isMobile ? (
                        /** MOBILE LAYOUT **/
                        <tr>
                          <td>
                            <div className="mobileClinicRow">
                              {/* Clinic Name */}
                              <div className="clinicNameMobile">{clinic.name}</div>

                              {/* Single dropdown for all actions */}
                              <div className="actionsDropdown">

                                <div className="actionsDropdownContent">
                                  {/* Only show Copy Link + QR if there's a link */}
                                  {clinic.link && (
                                    <>
                                      <button
                                        onClick={() =>
                                          navigator.clipboard.writeText(clinic.link)
                                        }
                                      >
                                        <FontAwesomeIcon icon={faCopy} /> Copy Link
                                      </button>
                                      <button
                                        onClick={() =>
                                          downloadQRCode(
                                            `clinicQRCode-${clinic.id}`,
                                            clinic.name
                                          )
                                        }
                                      >
                                        Download QR
                                      </button>
                                    </>
                                  )}

                                  {clinic.appointmentsEnabled && (
                                    <button
                                      onClick={() =>
                                        handleViewAppointments(clinic.id)
                                      }
                                    >
                                      <FontAwesomeIcon icon={faCalendar} /> Calendar
                                    </button>
                                  )}

                                  <button
                                    onClick={() => handleViewRoster(clinic.id)}
                                  >
                                    <FontAwesomeIcon icon={faHospital} /> Intake
                                    View
                                  </button>
                                  <button onClick={() => handleEditClinic(clinic)}>
                                    <FontAwesomeIcon icon={faEdit} /> Edit Clinic
                                  </button>
                                  <button onClick={() => handleEditIntake(clinic)}>
                                    Edit Intake
                                  </button>
                                  <button
                                    className="dangerDropdownButton"
                                    onClick={() =>
                                      handleDeleteConfirmation(clinic.id)
                                    }
                                  >
                                    <FontAwesomeIcon icon={faTrash} /> Delete
                                  </button>
                                </div>
                              </div>
                              {/* Hidden QR code canvas (for download) */}
                              {clinic.link && (
                                <QRCode
                                  style={{ display: "none" }}
                                  id={`clinicQRCode-${clinic.id}`}
                                  value={clinic.link}
                                />
                              )}
                            </div>
                          </td>
                        </tr>
                      ) : (
                        /** DESKTOP LAYOUT **/
                        <tr>
                          <td style={{ textAlign: "center" }}>{clinic.name}</td>

                          <td style={{ textAlign: "center" }}>
                            {clinic.link ? (
                              <button
                                onClick={() =>
                                  navigator.clipboard.writeText(clinic.link)
                                }
                              >
                                <FontAwesomeIcon className="mr-3" icon={faCopy} />
                                Copy Link
                              </button>
                            ) : (
                              <span>Private Clinic</span>
                            )}
                          </td>

                          <td style={{ textAlign: "center" }}>
                            {clinic.link && (
                              <QRCode
                                id={`clinicQRCode-${clinic.id}`}
                                value={clinic.link}
                                size={64}
                                onClick={() =>
                                  downloadQRCode(
                                    `clinicQRCode-${clinic.id}`,
                                    clinic.name
                                  )
                                }
                              />
                            )}
                          </td>

                          <td style={{ textAlign: "center" }}>
                            <div className="display-flex m-auto">
                              {clinic.appointmentsEnabled && (
                                <button
                                  onClick={() => handleViewAppointments(clinic.id)}
                                  className="iconButton"
                                >
                                  <FontAwesomeIcon icon={faCalendar} />
                                </button>
                              )}
                              <button
                                onClick={() => handleViewRoster(clinic.id)}
                                className="iconButton"
                              >
                                <FontAwesomeIcon icon={faHospital} /> Intake View
                              </button>

                              {/* Example: You could also nest a dropdown here if desired */}
                              <div className="customDropdown">
                                <button className="customDropdownButton">
                                  <FontAwesomeIcon icon={faEdit} />
                                  <FontAwesomeIcon icon={faCaretDown} />
                                </button>
                                <div className="customDropdownContent">
                                  <button
                                    className="customDropdownContentButton"
                                    onClick={() => handleEditClinic(clinic)}
                                  >
                                    Edit Clinic
                                  </button>
                                  <button
                                    className="customDropdownContentButton"
                                    onClick={() => handleEditIntake(clinic)}
                                  >
                                    Edit Intake
                                  </button>
                                </div>
                              </div>

                              <button
                                onClick={() => handleDeleteConfirmation(clinic.id)}
                                className="iconButton danger"
                              >
                                <FontAwesomeIcon icon={faTrash} />
                              </button>
                            </div>
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>

              <div className="help-article-wide">
                <HelpArticleLink
                  article={{
                    title: "Clinics",
                    link: "https://popularishealth.com/article/Clinics",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ClinicsPopup;
