import React, { useState, useEffect } from "react";
import { db } from "../../../firebase";
import { getDoc, doc, updateDoc } from "firebase/firestore";
import ReactModal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";

const ClinicEditModal = ({
  selectedClinic,
  setSelectedClinic,
  onClinicsUpdated,
  onClose,
}) => {
  const [clinicData, setClinicData] = useState(null);

  useEffect(() => {
    const fetchClinicData = async () => {
      if (selectedClinic && selectedClinic.id) {
        const clinicRef = doc(db, "clinics", selectedClinic.id);
        const clinicSnapshot = await getDoc(clinicRef);

        if (clinicSnapshot.exists()) {
          setClinicData(clinicSnapshot.data());
        }
      }
    };

    fetchClinicData();
  }, [selectedClinic]);

  const handleSaveEdit = async () => {
    if (clinicData) {
      try {
        const clinicRef = doc(db, "clinics", selectedClinic.id);

        await updateDoc(clinicRef, clinicData);

        setSelectedClinic(null); // Close the modal after updating
        onClinicsUpdated(); // Notify the parent component to refetch or update its state
      } catch (error) {
        console.error("Error updating clinic:", error);
      }
    }
  };

  const handleToggleAppointments = () => {
    setClinicData((prev) => ({
      ...prev,
      appointmentsEnabled: !prev.appointmentsEnabled,
    }));
  };

  const handleAddAppointmentType = () => {
    setClinicData((prev) => ({
      ...prev,
      appointmentTypes: [
        ...(prev.appointmentTypes || []),
        { name: "", duration: "" },
      ],
    }));
  };

  const handleUpdateAppointmentType = (index, field, value) => {
    const updatedTypes = [...clinicData.appointmentTypes];
    updatedTypes[index][field] = value;
    setClinicData((prev) => ({
      ...prev,
      appointmentTypes: updatedTypes,
    }));
  };

  const handleDeleteAppointmentType = (index) => {
    const updatedTypes = clinicData.appointmentTypes.filter((_, i) => i !== index);
    setClinicData((prev) => ({
      ...prev,
      appointmentTypes: updatedTypes,
    }));
  };

  const handleWorkingHoursChange = (day, field, value) => {
    setClinicData((prev) => ({
      ...prev,
      workingHours: {
        ...prev.workingHours,
        [day]: {
          ...prev.workingHours[day],
          [field]: value,
        },
      },
    }));
  };

  const handleClose = () => {
    setSelectedClinic(null);
    onClose();
  };

  return (
    <ReactModal
      isOpen={!!selectedClinic}
      onRequestClose={() => setSelectedClinic(null)}
      className="clear"
      overlayClassName="overlay"
    >
      <div className="overlay">
        <div className="modalBox">
          <div className="modalContainer">
            <button className="filesCloseButton" onClick={handleClose}>
              X
            </button>

            <h3 className="mt-4">Edit Clinic</h3>

            <label>Clinic Name:</label>
            <input
              type="text"
              value={clinicData?.clinicName || ""}
              onChange={(e) =>
                setClinicData((prev) => ({
                  ...prev,
                  clinicName: e.target.value,
                }))
              }
            />

            <label>Location:</label>
            <input
              type="text"
              value={clinicData?.location || ""}
              onChange={(e) =>
                setClinicData((prev) => ({
                  ...prev,
                  location: e.target.value,
                }))
              }
            />

            <div className="checkbox-row">
              <label>Enable Appointments:</label>
              <input
                type="checkbox"
                className="custom-checkbox"
                checked={clinicData?.appointmentsEnabled || false}
                onChange={handleToggleAppointments}
              />
            </div>

            {clinicData?.appointmentsEnabled && (
              <>
                <h4>Working Hours:</h4>
                {clinicData?.workingHours &&
                  Object.entries(clinicData.workingHours).map(
                    ([day, { open, start, end }]) => (
                      <div key={day}>
                        <label>
                          <input
                            type="checkbox"
                            className="custom-checkbox"
                            checked={open}
                            onChange={(e) =>
                              handleWorkingHoursChange(day, "open", e.target.checked)
                            }
                          />
                          {day}
                        </label>
                        {open && (
                          <div>
                            <input
                              type="time"
                              value={start}
                              onChange={(e) =>
                                handleWorkingHoursChange(day, "start", e.target.value)
                              }
                            />
                            <input
                              type="time"
                              value={end}
                              onChange={(e) =>
                                handleWorkingHoursChange(day, "end", e.target.value)
                              }
                            />
                          </div>
                        )}
                      </div>
                    )
                  )}

                <h4>Appointment Types:</h4>
                {clinicData?.appointmentTypes?.map((type, index) => (
                  <div key={index}>
                    <input
                      type="text"
                      value={type.name}
                      placeholder="Appointment Name"
                      onChange={(e) =>
                        handleUpdateAppointmentType(index, "name", e.target.value)
                      }
                    />
                    <input
                      type="number"
                      value={type.duration}
                      placeholder="Duration (minutes)"
                      onChange={(e) =>
                        handleUpdateAppointmentType(index, "duration", e.target.value)
                      }
                    />
                    <button onClick={() => handleDeleteAppointmentType(index)}>
                      Delete
                    </button>
                  </div>
                ))}
                <button onClick={handleAddAppointmentType}>
                  Add Appointment Type
                </button>

                <h4>Hours in Advance for Appointments:</h4>
                <input
                  type="number"
                  value={clinicData?.hoursBefore || ""}
                  placeholder="Hours in Advance"
                  onChange={(e) =>
                    setClinicData((prev) => ({
                      ...prev,
                      hoursBefore: e.target.value,
                    }))
                  }
                />
              </>
            )}

            <button onClick={handleSaveEdit}>
              <FontAwesomeIcon icon={faSave} /> Save Changes
            </button>
          </div>
        </div>
      </div>
    </ReactModal>
  );
};

export default ClinicEditModal;
