import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import '../Website.css';
import logo from '../../../popularis_logos/Popularis_logo_wide.png';
import { faCircleUser, faUserPlus, faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const navItems = [
  { label: 'Patients', path: '/trypatients' },
  // { label: 'Claims', path: '/tryclaims' },
  { label: 'Tech Specs', path: '/techspecs' },
  { label: 'Pricing', path: '/pricing' },
  { label: 'Support', path: '/support' },
  // Removed 'Sign Up' from here
];

const SignInButton = () => (
  <li  className='globalNav-submenu-item'>
    <a className='globalNav-link'>
      <div className='sign-in-button'>
        <Link to="/signin" className="login-btn">
       
          <>
            Sign in
          </>
        </Link>
      </div>
    </a>
  </li>
);

const SignUpButton = () => (
  <li style={{ marginLeft: "0rem"}} className='globalNav-submenu-item'>
    <a className='globalNav-link'>
      <div className='sign-up-button'>
        <Link to="/sales" className="signup-btn-header">
    
          <>
            Contact sales
          </>
        </Link>
      </div>
    </a>
  </li>
);

const Header = () => {
  const location = useLocation();
  const [isScrolled, setIsScrolled] = useState(false);
  const [headerHeight, setHeaderHeight] = useState(0);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const headerRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0.1) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    if (headerRef.current) {
      setHeaderHeight(headerRef.current.offsetHeight);
    }
  }, [headerRef.current]);

  const isActive = (path) => location.pathname === path;
  const toggleMobileMenu = () => setIsMobileMenuOpen(!isMobileMenuOpen);

  return (
    <div>
      {/* Placeholder div to avoid content jump */}
      <div style={{ height: isScrolled ? `${headerHeight}px` : '0px' }}></div>

      <div className='globalHeader' ref={headerRef}>
        <nav className={`local-nav ${isScrolled ? 'sticky' : ''}`}>
          <div className={`ln-background ${isScrolled ? 'scrolled' : ''}`}>
            <div className='ln-content'>
              <Link to="/">
                <img src={logo} alt="Logo" className="logoHome" />
              </Link>
              <div className="menu-Tray">
                <ul className='globalNav-list desktop-nav'>
                  {navItems.map((item, index) => (
                    <li key={index} className='globalNav-submenu-item'>
                      <a href={item.path} className='globalNav-link'>
                        {item.label}
                      </a>
                    </li>
                  ))}                  
                  <SignInButton />               
                  <SignUpButton />

                </ul>
              </div>
              <button className="menu-toggle" onClick={toggleMobileMenu}>
                <FontAwesomeIcon icon={faBars} />Menu
              </button>
              <div className="mobile-signin-button">
               <div className='sign-in-button'>
                    <Link to="/signin" className="login-btn">
                  
                      <>
                        Sign in
                      </>
                    </Link>
                  </div>
                </div>
            </div>
          </div>
        </nav>
        <div className={`mobile-menu ${isMobileMenuOpen ? 'open' : ''}`}>
          <ul className='globalNav-list mobile-nav'>
            <li className=''>
              <a href="/" className=''>
                <img src={logo} alt="Logo" className="logoHome" />
              </a>
              <button className="menu-toggle-close" onClick={toggleMobileMenu}>
                &times;
              </button>
            </li>
            {navItems.map((item, index) => (
              <li key={index} className='globalNav-submenu-item'>
                <a href={item.path} className='globalNav-link'>
                  {item.label}
                </a>
              </li>
            ))}
            <SignInButton />
            <SignUpButton />            

          </ul>
        </div>
      </div>
    </div>
  );
};

export default Header;
