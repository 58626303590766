import React, { useState, useEffect } from "react";
import { auth, db } from "../../../firebase";
import {
  collection,
  query,
  onSnapshot,
  orderBy,
  getDoc,
  where, 
  getDocs
} from "firebase/firestore";
import { doc, deleteDoc, updateDoc } from "firebase/firestore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrashAlt,
  faFileArrowDown,
  faBrain,
  faEnvelope,
  faSms
} from "@fortawesome/free-solid-svg-icons";
import DoctorsNote from "./DoctorsNote";
import PdfGenerator from "./PdfGenerator";
import "./Notes.css";
import useUID from "../../General/useUID";
import HelpArticleLink from "../../Articles/HelpArticleLink";
import { getFunctions, httpsCallable } from "firebase/functions";
import { isConstructorDeclaration } from "typescript";
import HexagonSpinner from "../../General/Animations/Hexspinner";
import { openEmailClient } from "./emailUtil";
import Modal from 'react-modal';

const functions = getFunctions();

function Notes({ patientId, patientData, onClose }) {
  const [notes, setNotes] = useState([]);
  const [selectedNote, setSelectedNote] = useState(null);
  const [body, setBody] = useState("");
  const [selectedTab, setSelectedTab] = useState("all");
  const [noteData, setNoteData] = useState("");
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [noteToDelete, setNoteToDelete] = useState(null);
  const [uid, subUserUID] = useUID();
  const [editingNoteId, setEditingNoteId] = useState(null);
  const [editingText, setEditingText] = useState("");
  const [loading, setLoading] = useState(true); 
  const [defaultProvider, setDefaultProvider] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [noteToSend, setNoteToSend] = useState(null);

  useEffect(() => {
    if (!uid) return;
    setLoading(true); 
    const notesRef = collection(
      db,
      "patients",
      uid,
      "patientData",
      patientId,
      "notes"
    );
    const q = query(notesRef, orderBy("timestamp", "desc"));
    const decryptFunction = httpsCallable(functions, "decrypt");

    const unsubscribe = onSnapshot(q, async (querySnapshot) => {
      const notesData = await Promise.all(
        querySnapshot.docs.map(async (doc) => {
          const { ciphertext, iv, authorName, timestamp, signatureId, signatureData } = doc.data();
          const decryptedResponse = await decryptFunction({ ciphertext, iv });
          const decryptedText = decryptedResponse.data.text;

          return {
            id: doc.id,
            text: decryptedText,
            authorName,
            timestamp: timestamp,
            signatureId,
            signatureData,
          };
        })
      );
      setNotes(notesData);
      setLoading(false); 
    });

    return () => {
      unsubscribe();
    };
  }, [patientId, uid]);

  const formatDate = (timestamp) => {
    // Attempt to convert Firestore Timestamp to JavaScript Date if possible
    const date =
      timestamp instanceof Date
        ? timestamp
        : timestamp?.toDate instanceof Function
        ? timestamp.toDate()
        : null;

    // Validate and format the date
    if (date && !isNaN(date.getTime())) {
      return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
    }

    // Return placeholder for null or invalid dates
    return "Invalid or pending date...";
  };

  const handleNoteClick = (noteId) => {
    setSelectedNote((prevSelectedNote) =>
      prevSelectedNote === noteId ? null : noteId
    );
  };

  const toggleTab = (tab) => {
    if (selectedTab === tab) {
      setSelectedTab("all");
    } else {
      setBody("");
      setSelectedTab(tab);
    }
  };

  const handleDownload = async (note) => {
    if (!note) return;

    try {
      let signatureData = null

      if (!note.signatureData) {
        const signatureId = note.signatureId || null;
        if (!signatureId) {
          return;
        }
  
        const signatureDocRef = doc(db, "signatures", signatureId);
        const signatureDoc = await getDoc(signatureDocRef);
  
        if (signatureDoc.exists()) {
          signatureData = signatureDoc.data();
        } else {
        }
      } else {
        signatureData = note.signatureData
      }

      const pdfGenerator = new PdfGenerator();
      await pdfGenerator.downloadPdf(
        note.text,
        patientData,
        signatureData,
        uid,
        subUserUID,
        note?.authorName,
      );
      
    } catch (error) {
      console.error("Error fetching and processing signature data:", error);
    }
  };

  const handleBrainIconClick = (note) => {
    if (!note) return;
    setBody(note.text);
    setSelectedTab("ai");
    setNoteData(note);
  };

  const handleDeleteNote = async (noteId) => {
    setNoteToDelete(noteId);
    setShowDeleteConfirmation(
      noteId != noteToDelete ? true : !showDeleteConfirmation
    );
  };

  const handleCancelDelete = () => {
    setNoteToDelete(null);
    setShowDeleteConfirmation(false);
  };

  const handleConfirmDelete = async () => {
    if (noteToDelete) {
      const noteRef = doc(
        db,
        "patients",
        uid,
        "patientData",
        patientId,
        "notes",
        noteToDelete
      );
      await deleteDoc(noteRef);
      setNoteToDelete(null);
      setShowDeleteConfirmation(false);
    }
  };

  const getNoteSnippet = (text, maxLength = 20) => {
    return text.length > maxLength
      ? text.substring(0, maxLength) + "..."
      : text;
  };

  const handleEditClick = (noteId, noteText) => {
    setEditingNoteId(noteId);
    setEditingText(noteText);
  };

  const handleEditChange = (e) => {
    setEditingText(e.target.value);
  };

  const handleSaveEdit = async () => {
    if (editingNoteId && editingText.trim()) {
      try {
        const noteRef = doc(
          db,
          "patients",
          uid,
          "patientData",
          patientId,
          "notes",
          editingNoteId
        );

        // Encrypt the updated note
        const encryptFunction = httpsCallable(functions, "encrypt");
        const encryptedResponse = await encryptFunction({ text: editingText });
        const { ciphertext, iv } = encryptedResponse.data;

        // Update the note in Firestore
        await updateDoc(noteRef, { ciphertext, iv });

        setEditingNoteId(null);
        setEditingText("");
      } catch (error) {
        console.error("Error saving edited note:", error);
      }
    }
  };


useEffect(() => {
  const fetchDefaultProvider = async () => {
    const providersRef = collection(db, `users/${uid}/providers`);
    const defaultProviderQuery = query(providersRef, where("isDefault", "==", true));
    const querySnapshot = await getDocs(defaultProviderQuery);

    if (!querySnapshot.empty) {
      const defaultProviderDoc = querySnapshot.docs[0];
      const encryptedData = defaultProviderDoc.data();

      const decrypt = httpsCallable(functions, "decrypt");
      const decryptedResponse = await decrypt({
        ciphertext: encryptedData.ciphertext,
        iv: encryptedData.iv,
      });

      const decryptedProvider = {
        ...decryptedResponse.data,
        id: defaultProviderDoc.id,
      };

      setDefaultProvider(decryptedProvider);
    }
  };

  if (uid) fetchDefaultProvider();
}, [uid]);

const handleSendSms = async (noteContent) => {
  // Check if confirmation is needed
  if (!localStorage.getItem("confirmedSmsSend")) {
    setNoteToSend(noteContent); // Store the note content for later
    setShowConfirmationModal(true); // Show the confirmation modal
    return;
  }

  try {
    const sendSmsFunction = httpsCallable(functions, "sendPatientsms");
    await sendSmsFunction({
      phoneNumber: patientData.patient.phone, // Use the patient's phone number
      patientMessage: noteContent, // The note content to send
    });
    alert("SMS sent successfully!");
  } catch (error) {
    console.error("Error sending SMS:", error);
    alert("Failed to send SMS. Please try again.");
  }
};

const confirmSendSms = async () => {
  setShowConfirmationModal(false); // Hide the modal
  localStorage.setItem("confirmedSmsSend", "true"); // Remember the user's confirmation
  if (noteToSend) {
    await handleSendSms(noteToSend); // Send the SMS now
    setNoteToSend(null); // Clear the stored note
  }
};


  return (
    <>
       <div className="closeButtonContainer">
          <button onClick={onClose} className="filesCloseButton">
            X
          </button>
          <p className="closeBarNav">Notes</p>
        </div>

        {showConfirmationModal && (
        <Modal
          isOpen={showConfirmationModal}
          onRequestClose={() => setShowConfirmationModal(false)}
          contentLabel="Confirm SMS Send"
          className="confirmModal"
        >
          <h2>Confirm Sending SMS</h2>
          <p>Are you sure you want to send this note to the patient via SMS?</p>
          <div className="modalActions">
            <button
              onClick={confirmSendSms}
              className="confirmButton"
            >
              Yes, Send SMS
            </button>
            <button
              onClick={() => setShowConfirmationModal(false)}
            >
              Cancel
            </button>
          </div>
        </Modal>
      )}
    
    <div className="notesContainer">
      <div className="buttonsContainer">
        <button
          onClick={() => toggleTab("all")}
          className={`greyButton ${
            selectedTab === "all" ? "selectedButton" : ""
          }`}
        >
          All Notes
        </button>

        <button
          onClick={() => toggleTab("write")}
          className={`greyButton ${
            selectedTab === "write" ? "selectedButton" : ""
          }`}
        >
          Write a Note
        </button>

        <button
          onClick={() => toggleTab("ai")}
          className={`greyButton ${
            selectedTab === "ai" ? "selectedButton" : ""
          }`}
        >
          AI Note Writer
        </button>
      </div>

      {selectedTab === "all" && (
     <div className="notes">
     {loading ? (
       <HexagonSpinner />
     ) : notes.length === 0 ? (
       <label className="content-center mt-3">
         <h5>No notes available.</h5>
       </label>
     ) : (
       <ul>
          {notes.map((note) => (
            <div onClick={() => handleNoteClick(note.id)} key={note.id} className="noteItem">
              {editingNoteId === note.id ? (
                // Edit View
                <div>
                  <div className="noteContent">
                    <span>Author: {note.authorName || "Unknown Author"}</span>
                    <br />
                    <small>{formatDate(note.timestamp)}</small>
                  </div>
                  <textarea
                    value={editingText}
                    onChange={handleEditChange}
                    className="expandedNoteContent"
                    placeholder="Edit your note here..."
                  />
                  <div className="noteActions">
                    <button title="Save Note" onClick={handleSaveEdit}>
                      Save
                    </button>
                    <button
                      title="Cancel Edit"
                      onClick={() => {
                        setEditingNoteId(null);
                        setEditingText("");
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              ) : (
                // Default View
                <>
                  <div >
                    <div className="noteContent">
                      <span>Author: {note.authorName || "Unknown Author"}</span>
                      <br />
                      <small>{formatDate(note.timestamp)}</small>
                    </div>
                    {selectedNote === note.id ? (
                      <div className="expandedNoteContent">{note.text}</div>
                    ) : (
                      <div className="notePreview">{getNoteSnippet(note.text)}</div>
                    )}
                  </div>
                  <div className="noteActions">
                    <button
                      title="Edit Note"
                      onClick={() => handleEditClick(note.id, note.text)}
                    >
                      Edit
                    </button>
                    <button onClick={() => handleDownload(note)}>
                      <FontAwesomeIcon
                        title="Download Note"
                        icon={faFileArrowDown}
                      />
                    </button>

                    <button
                        onClick={() =>
                          openEmailClient({
                            patientData,
                            defaultProvider,
                            noteContent: note.text,
                          })
                        }
                        disabled={!defaultProvider || !patientData?.patient?.email}
                        className="emailPatientButton"
                      >
                        <FontAwesomeIcon title="Email Patient" icon={faEnvelope} />
                      </button>

                      <button
                        onClick={() => handleSendSms(note.text)}
                        disabled={!patientData?.patient?.phone}
                        className="smsPatientButton"
                      >
                        <FontAwesomeIcon title="Send SMS" icon={faSms} />
                      </button>
                    <button
                      onClick={() => handleBrainIconClick(note)}
                      disabled={note.isAIGenerated}
                      className={note.isAIGenerated ? "disabled" : ""}
                    >
                      <FontAwesomeIcon
                        title="Run through the AI"
                        icon={faBrain}
                      />
                    </button>
                    {(!showDeleteConfirmation || note.id !== noteToDelete) && (
                      <button
                        onClick={() => handleDeleteNote(note.id)}
                        className="deleteNoteButton"
                      >
                        <FontAwesomeIcon icon={faTrashAlt} />
                      </button>
                    )}
                  </div>
                  {showDeleteConfirmation && note.id === noteToDelete && (
                    <div className="confirmationDialog slide-in">
                      <p>Are you sure you want to delete this note?</p>
                      <button onClick={handleConfirmDelete}>Yes</button>
                      <button onClick={handleCancelDelete}>No</button>
                    </div>
                  )}
                </>
              )}
            </div>
          ))}
        </ul>
      )}
      <div className="help-article-wide">
        <HelpArticleLink
          article={{
            title: "Using Notes",
            link: "https://popularishealth.com/article/Using-Note",
          }}
        />
      </div>
    </div>
    
      )}

      {selectedTab === "write" && (
        <DoctorsNote patientId={patientId} body={body} manualInputMode={true} />
      )}

      {selectedTab === "ai" && (
        <DoctorsNote patientId={patientId} body={body} note={noteData} />
      )}
    </div>
    </>
  );
}

export default Notes;
