import React, { useState, useEffect } from "react";
import { PosCodeDropdown } from '../Claims/Subcomponents/PosCodes';
import HexagonSpinner from '../General/Animations/Hexspinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faPencilAlt, faCheck } from '@fortawesome/free-solid-svg-icons';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { auth, db } from "../../firebase";
import {
    collection,
    onSnapshot,
    getDocs,
    setDoc,
    addDoc,
    deleteDoc,
    doc,
    updateDoc
} from "firebase/firestore";
import useUID from '../General/useUID';
import InputMask from 'react-input-mask';

function ProviderForm(props) {
    const { provider, onClose } = props;
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [organizationName, setOrganizationName] = useState('');
    const [npi, setNpi] = useState('');
    const [ein, setEin] = useState('');
    const [medicareNumber, setMedicareNumber] = useState('');
    const [medicaidNumber, setMedicaidNumber] = useState('');
    const [cliaNumber, setCliaNumber] = useState('');
    const [address1, setAddress1] = useState('');
    const [address2, setAddress2] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [zip, setZip] = useState('');
    const [phone, setPhone] = useState('');
    const [selectedPosCode, setSelectedPosCode] = useState('');
    const [error, setError] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [editing, setEditing] = useState(false);
    const [currentEditingId, setCurrentEditingId] = useState(null);
    const [uid, subUserUID, isLoading] = useUID();
    const [linkRequired, setLinkRequired] = useState(false);
    const [isDefaultProvider, setIsDefaultProvider] = useState(true);

    // Track whether NPI is verified
    // null = not checked yet, true = verified match, false = verified fail
    const [npiVerified, setNpiVerified] = useState(null);

    const functions = getFunctions();
    const providersRef = collection(db, `users/${uid}/providers`);

    // State abbreviations
    const states = [
        'AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA',
        'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD',
        'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ',
        'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC',
        'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'
    ];

    // Populate form if editing
    useEffect(() => {
        if (provider) {
            populateFormForEdit(provider);
            setEditing(true);
        }
    }, [uid, provider]);

    function populateFormForEdit(provider) {
        setOrganizationName(provider.organizationName);
        setFirstName(provider.firstName);
        setLastName(provider.lastName);
        setNpi(provider.npi);
        setEin(provider.ein);
        setAddress1(provider.address1);
        setAddress2(provider.address2 || "");
        setCity(provider.city);
        setState(provider.state);
        setZip(provider.zip);
        setPhone(provider.phone);
        setSelectedPosCode(provider.posCode);
        setMedicareNumber(provider.medicareNumber || "");
        setMedicaidNumber(provider.medicaidNumber || "");
        setCliaNumber(provider.cliaNumber || "");
        setEditing(true);
        setCurrentEditingId(provider.id);
    }

    // Validate address so we don't store incomplete data
    function validateAddress(address, city, state, zip) {
        let errors = [];
        if (!address.trim()) {
            errors.push("Address is required.");
        }
        if (!city.trim()) {
            errors.push("City is required.");
        }
        if (!state.trim()) {
            errors.push("State is required.");
        }
        if (!zip.trim()) {
            errors.push("Zip code is required.");
        } else if (!/^\d{9}$/.test(zip)) {
            errors.push("Zip code must be exactly 9 digits (xxxxxxxxx).");
        }
        return errors;
    }

    // Closes the form
    function closeAddProvider() {
        if (onClose) onClose();
    }

    // This is the same verification that used to block saving, but now we only store the result
    // We call it before submission (like “before adding the checkmark check the NPI like before”).
    async function verifyNpiLikeBefore() {
        // Skip if not 10 digits
        if (!/^\d{10}$/.test(npi)) {
            setNpiVerified(false);
            return false;
        }

        // In dev, just pretend it’s valid
        if (process.env.NODE_ENV === 'development') {
            console.log('Skipping real NPI check in development mode.');
            setNpiVerified(true);
            return true;
        }

        let matchFound = false;
        try {
            const verifyNpi = httpsCallable(functions, 'verifyNpi');
            const result = await verifyNpi({ npi: npi.toUpperCase() });

            if (!result.data.valid) {
                // If the API says “invalid NPI number”
                setNpiVerified(false);
                return false;
            }

            const npiData = result.data.npiData;
            for (const record of npiData.results) {
                const basic = record.basic;
                if (
                    basic.organization_name?.toUpperCase() === organizationName.toUpperCase() ||
                    (basic.first_name?.toUpperCase() === firstName.toUpperCase() &&
                     basic.last_name?.toUpperCase() === lastName.toUpperCase()) ||
                    (basic.authorized_official_first_name?.toUpperCase() === firstName.toUpperCase() &&
                     basic.authorized_official_last_name?.toUpperCase() === lastName.toUpperCase())
                ) {
                    matchFound = true;
                    break;
                }
            }

            setNpiVerified(matchFound);
            return matchFound;
        } catch (error) {
            console.error("NPI verification error:", error);
            // If error occurs in the call, mark as false
            setNpiVerified(false);
            return false;
        }
    }

    // Submit the form (save the provider)
    async function handleSubmit(e) {
        e.preventDefault();
        setError(null);
        setIsSubmitting(true);

        // Basic validations
        if (npi.length !== 10) {
            setError("NPI must be 10 digits.");
            setIsSubmitting(false);
            return;
        }
        if (ein.length !== 9) {
            setError("EIN must be 9 digits.");
            setIsSubmitting(false);
            return;
        }
        // Address validations
        const addressErrors = validateAddress(address1, city, state, zip);
        if (addressErrors.length > 0) {
            setError(addressErrors.join(" "));
            setIsSubmitting(false);
            return;
        }

        // Step 1: Check if user has reached the limit for new providers
        try {
            if (!editing) {
                const limiter = httpsCallable(functions, 'limiter');
                const limiterResponse = await limiter({
                    userId: uid,
                    limiterTag: 'provider'
                });
                if (limiterResponse.data.result === false) {
                    setError("You've reached the limit for adding providers at your tier.");
                    setLinkRequired(true);
                    setIsSubmitting(false);
                    return;
                }
            }
        } catch (error) {
            console.error("Error calling limiter function:", error);
            setError("Error calling limiter. Please try again.");
            setIsSubmitting(false);
            return;
        }

        // Step 2: Attempt to verify NPI *before* saving. We do NOT block if it fails.
        //         This sets npiVerified accordingly but doesn't return or block saving.
        await verifyNpiLikeBefore();

        // === We DO NOT block if verification fails. ===

        // Step 3: Encrypt + Save the data
        const providerData = {
            organizationName,
            firstName,
            lastName,
            npi,
            ein,
            address1,
            address2,
            city,
            state,
            zip,
            phone,
            posCode: selectedPosCode,
        };
        if (medicareNumber) providerData.medicareNumber = medicareNumber;
        if (medicaidNumber) providerData.medicaidNumber = medicaidNumber;
        if (cliaNumber) providerData.cliaNumber = cliaNumber;

        try {
            const encryptCall = httpsCallable(functions, 'encrypt');
            const encryptedResponse = await encryptCall(providerData);
            if (!encryptedResponse.data) {
                setError("Failed to encrypt data.");
                setIsSubmitting(false);
                return;
            }

            const { ciphertext, iv } = encryptedResponse.data;

            // If user wants this as default provider, turn off default for others
            if (isDefaultProvider) {
                const allProvidersSnapshot = await getDocs(providersRef);
                for (const docSnapshot of allProvidersSnapshot.docs) {
                    if (docSnapshot.id !== currentEditingId) {
                        await updateDoc(
                            doc(db, `users/${uid}/providers`, docSnapshot.id),
                            { isDefault: false }
                        );
                    }
                }
            }

            // We store ciphertext, iv, and isDefault
            const savePayload = { ciphertext, iv, isDefault: isDefaultProvider };

            if (editing) {
                const providerDocRef = doc(db, `users/${uid}/providers`, currentEditingId);
                await setDoc(providerDocRef, savePayload);
                setEditing(false);
                setCurrentEditingId(null);
            } else {
                await addDoc(providersRef, savePayload);
            }

            // Clear form
            setOrganizationName('');
            setFirstName('');
            setLastName('');
            setNpi('');
            setEin('');
            setAddress1('');
            setAddress2('');
            setCity('');
            setState('');
            setZip('');
            setPhone('');
            setSelectedPosCode('');
            setMedicareNumber('');
            setMedicaidNumber('');
            setCliaNumber('');
            setNpiVerified(null);

        } catch (error) {
            console.error("Error saving provider:", error);
            setError("Error saving provider. Please try again.");
        } finally {
            setIsSubmitting(false);
            if (onClose) onClose(true);
        }
    }

    function confirmDefaultProvider() {
        setIsDefaultProvider(true);
    }
    function notDefaultProvider() {
        setIsDefaultProvider(false);
    }

    return (
        <div className="add-popup over-add-popup">
            <div className="add-form">
                <div className="content-center">
                    <button className='filesCloseButton' onClick={closeAddProvider}>X</button>
                    <h3 style={{ marginTop: 30 }}>
                        {editing ? "Edit Provider" : "Add Provider"}
                    </h3>

                    <form onSubmit={handleSubmit}>
                        {/* Organization Name */}
                        <div className="input-group-row">
                            <div className="input-field">
                                <label htmlFor="organizationName">Organization Name</label>
                                <input
                                    type="text"
                                    name="organizationName"
                                    value={organizationName}
                                    onChange={(e) => setOrganizationName(e.target.value.toUpperCase())}
                                    required
                                />
                            </div>
                        </div>

                        {/* First/Last Name */}
                        <div className="input-group-row">
                            <div className="input-field">
                                <label htmlFor="firstName">First Name</label>
                                <input
                                    type="text"
                                    name="firstName"
                                    value={firstName}
                                    onChange={(e) => setFirstName(e.target.value.toUpperCase())}
                                    required
                                />
                            </div>
                            <div className="input-field">
                                <label htmlFor="lastName">Last Name</label>
                                <input
                                    type="text"
                                    name="lastName"
                                    value={lastName}
                                    onChange={(e) => setLastName(e.target.value.toUpperCase())}
                                    required
                                />
                            </div>
                        </div>

                        {/* NPI & EIN */}
                        <div className="input-group-row">
                            <div className="input-field">
                                <label htmlFor="npi">
                                    NPI
                                    {/* If npiVerified is true, show checkmark */}
                                    {npiVerified === true && (
                                        <FontAwesomeIcon icon={faCheck} style={{ marginLeft: 5 }} />
                                    )}
                                </label>
                                <input
                                    type="text"
                                    name="npi"
                                    maxLength="10"
                                    pattern="^\d{10}$"
                                    value={npi}
                                    onChange={(e) => setNpi(e.target.value)}
                                    required
                                />
                                {npiVerified === false && (
                                    <div style={{ color: 'red', fontSize: '0.8rem' }}>
                                        NPI not verified / mismatch
                                    </div>
                                )}
                            </div>
                            <div className="input-field">
                                <label htmlFor="ein">Tax ID</label>
                                <input
                                    type="text"
                                    name="ein"
                                    maxLength="9"
                                    pattern="^\d{9}$"
                                    value={ein}
                                    onChange={(e) => setEin(e.target.value)}
                                    required
                                />
                            </div>
                        </div>

                        {/* Medicare / Medicaid */}
                        <div className="input-group-row">
                            <div className="input-field">
                                <label htmlFor="medicareNumber">Medicare ID (optional)</label>
                                <input
                                    type="text"
                                    name="medicareNumber"
                                    value={medicareNumber}
                                    onChange={(e) => setMedicareNumber(e.target.value)}
                                />
                            </div>
                            <div className="input-field">
                                <label htmlFor="medicaidNumber">Medicaid Provider Number (optional)</label>
                                <input
                                    type="text"
                                    name="medicaidNumber"
                                    value={medicaidNumber}
                                    onChange={(e) => setMedicaidNumber(e.target.value)}
                                />
                            </div>
                        </div>

                        {/* CLIA */}
                        <div className="input-group-row">
                            <div className="input-field">
                                <label htmlFor="cliaNumber">CLIA Number (optional)</label>
                                <input
                                    type="text"
                                    name="cliaNumber"
                                    value={cliaNumber}
                                    onChange={(e) => setCliaNumber(e.target.value)}
                                />
                            </div>
                        </div>

                        {/* Address */}
                        <div className="input-group-row">
                            <div className="input-field">
                                <label htmlFor="address1">Address 1</label>
                                <input
                                    type="text"
                                    name="address1"
                                    value={address1}
                                    onChange={(e) => setAddress1(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="input-field">
                                <label htmlFor="address2">Address 2</label>
                                <input
                                    type="text"
                                    name="address2"
                                    value={address2}
                                    onChange={(e) => setAddress2(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className="input-group-row">
                            <div className="input-field">
                                <label htmlFor="city">City</label>
                                <input
                                    type="text"
                                    name="city"
                                    value={city}
                                    onChange={(e) => setCity(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="input-field">
                                <label htmlFor="state">State</label>
                                <select
                                    name="state"
                                    className="w-100"
                                    value={state}
                                    onChange={(e) => setState(e.target.value)}
                                    required
                                >
                                    <option value="">Select a State</option>
                                    {states.map((s) => (
                                        <option key={s} value={s}>
                                            {s}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="input-field">
                                <label htmlFor="zip">Zip (9 digit)</label>
                                <input
                                    type="text"
                                    name="zip"
                                    value={zip}
                                    onChange={(e) => setZip(e.target.value)}
                                    required
                                    maxLength="9"
                                    pattern="^\d{9}$"
                                    title="ZIP code must be 9 digits"
                                />
                            </div>
                        </div>

                        {/* Phone */}
                        <div className="input-field">
                            <label htmlFor="phone">Phone</label>
                            <InputMask
                                id="phone"
                                mask="(999) 999-9999"
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                            />
                        </div>

                        {/* Place of Service */}
                        <div className="input-field">
                            <label htmlFor="">Place of Service</label>
                            <PosCodeDropdown
                                selectedValue={selectedPosCode}
                                onChange={(e) => setSelectedPosCode(e.target.value)}
                            />
                        </div>

                        {/* Default provider choice */}
                        <h4>Make this my default provider?</h4>
                        <div className="gender-buttons">
                            <button
                                type="button"
                                className={`gender-button ${isDefaultProvider ? "selected" : ""}`}
                                onClick={confirmDefaultProvider}
                            >
                                Yes
                            </button>
                            <button
                                type="button"
                                className={`gender-button ${!isDefaultProvider ? "selected" : ""}`}
                                onClick={notDefaultProvider}
                            >
                                No
                            </button>
                        </div>

                        {/* Show error if any */}
                        {error && (
                            <div className="error">
                                {error}
                                {linkRequired && (
                                    <>
                                        {' '}
                                        Please{' '}
                                        <a
                                            href="https://popularishealth.com/payment"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            upgrade your account
                                        </a>.
                                    </>
                                )}
                            </div>
                        )}

                        {/* Show spinner if submitting, otherwise show buttons */}
                        {isSubmitting ? (
                            <>
                                <HexagonSpinner />
                                <p className="authMessage">Saving provider...</p>
                            </>
                        ) : (
                            <div className="display-flex mt-3">
                                <button className="primary" type="submit">
                                    {editing ? (
                                        <>
                                            <FontAwesomeIcon icon={faPencilAlt} className="mr-3" />
                                            Update Provider
                                        </>
                                    ) : (
                                        <>
                                            <FontAwesomeIcon icon={faPlus} className="mr-3" />
                                            Add Provider
                                        </>
                                    )}
                                </button>

                                <button
                                    type="button"
                                    onClick={closeAddProvider}
                                    className="primary"
                                >
                                    Cancel
                                </button>
                            </div>
                        )}
                    </form>
                </div>
            </div>
        </div>
    );
}

export default ProviderForm;
