import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import PatientList from "./PatientList";
import "./PatientFiles.modules.css";
import Topbar from "../General/Topbar";
import PatientView from "./PatientView";
import FolderControls from "./FolderControls";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";

function PatientFiles() {
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [selectedFolder, setSelectedFolder] = useState("All Patients");
  const [dataUpdated, setDataUpdated] = useState(false);
  const [resetView, setResetView] = useState(false);
  const [checkboxOpen, setCheckboxOpen] = useState(false);
  const [isAddPatientOpen, setIsAddPatientOpen] = useState(false);
  const [isBatchUploadOpen, setIsBatchUploadOpen] = useState(false);
  const [isAddClinicOpen, setIsAddClinicOpen] = useState(false);
  const [selectedPatientsData, setSelectedPatientsData] = useState([]);
  const [isClaimsOpen, setClaimsOpen] = useState(false);

  // States for sidebar visibility
  const [isPatientSidebarOpen, setIsPatientSidebarOpen] = useState(window.innerWidth > 709);
  const [isFolderSidebarOpen, setIsFolderSidebarOpen] = useState(window.innerWidth > 709);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 709);

  const { patientIdfromURL } = useParams();
  const scrollingTableRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 709) {
        setIsPatientSidebarOpen(true);
        setIsFolderSidebarOpen(true);
        setIsMobileView(false);
      } else {
        setIsPatientSidebarOpen(false);
        setIsFolderSidebarOpen(false);
        setIsMobileView(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleUserSelectPatient = (patient, isUserSelected) => {
    setSelectedPatient(patient);
    if (isUserSelected && isMobileView && !checkboxOpen) {
      setIsPatientSidebarOpen(false);
    }
  };

  const handleSelectClinicFolder = (folder) => {
    setSelectedFolder(folder);
    if (isMobileView) {
      setIsFolderSidebarOpen(false);
      setIsPatientSidebarOpen(true);
    }
  };

  const handleOpenFolder = (shouldOpen) => {
    if (shouldOpen && isMobileView) {
      setIsPatientSidebarOpen(false);
      setIsFolderSidebarOpen(true);
    }
  };

  const handleUpdateData = () => {
    setDataUpdated((prev) => !prev);
  };

  const togglePatientSidebar = () => {
    if (isMobileView) {
      setIsPatientSidebarOpen((prev) => !prev);
      if (!isPatientSidebarOpen) {
        setIsFolderSidebarOpen(false);
      }
    }
  };

  const toggleAddPatientPopup = () => {
    setIsAddPatientOpen(!isAddPatientOpen);
    if (!isAddPatientOpen) {
      setIsBatchUploadOpen(false);
      setIsAddClinicOpen(false);
    } 
    togglePatientSidebar();
  };
  
  const toggleBatchUploadPopup = () => {
    setIsBatchUploadOpen(!isBatchUploadOpen);
    if (!isBatchUploadOpen) {
      setIsAddPatientOpen(false);
      setIsAddClinicOpen(false);
    }
  };
  
  const toggleAddClinicPopup = () => {
    setIsAddClinicOpen(!isAddClinicOpen);
    if (!isAddClinicOpen) {
      setIsAddPatientOpen(false);
      setIsBatchUploadOpen(false);
    }
  };

   const toggleBatchClaimPopup = () => {
    setClaimsOpen((prev) => !prev);
    if (!isClaimsOpen) {
      // Optionally close other popups when opening claims
      setIsAddPatientOpen(false);
      setIsBatchUploadOpen(false);
      setIsAddClinicOpen(false);
    }
  };
console.log(selectedPatientsData)

  return (
    <div className="background">
      <Topbar />
      <div className="content">
        {isMobileView && (
          <button
            className={`toggleSidebarBtn patientBtn ${isPatientSidebarOpen ? "active" : ""}`}
            onClick={togglePatientSidebar}
          >
            <FontAwesomeIcon icon={isPatientSidebarOpen ? faChevronLeft : faChevronRight} />
          </button>
        )}

        <div className="patientWindow">
          <PatientView
            patientSelected={selectedPatient}
            onDataUpdated={handleUpdateData}
            resetView={resetView}
            isAddPatientOpen={isAddPatientOpen}
            isBatchUploadOpen={isBatchUploadOpen}
            isAddClinicOpen={isAddClinicOpen}
            toggleAddPatientPopup={toggleAddPatientPopup}
            toggleBatchUploadPopup={toggleBatchUploadPopup}
            toggleClinicPopup={toggleAddClinicPopup}
            showBatchClaimsModal={isClaimsOpen} 
            selectedFolder={selectedFolder}
            selectedPatientsData={selectedPatientsData} 
          />
        </div>

        <div className={`folderControlsContainer fullWidthSideBar ${isFolderSidebarOpen ? "visible" : "hidden slide-out"}`}>
          <FolderControls onFolderChange={handleSelectClinicFolder} selectedFolder={selectedFolder} toggleClinicPopup={toggleAddClinicPopup} />
        </div>

        <div className={`patientViewBar fullWidthSideBar ${isPatientSidebarOpen ? "visible" : "hidden slide-out"}`} ref={scrollingTableRef}>
          <PatientList
            onSelectPatient={handleUserSelectPatient}
            selectedFolder={selectedFolder}
            selectedPatient={selectedPatient}
            patientIdfromURL={patientIdfromURL}
            scrollingTableRef={scrollingTableRef}
            DataUpdated={dataUpdated}
            onOpenFolder={handleOpenFolder}
            isCheckboxOpen={setCheckboxOpen}
            toggleAddPatientPopup={toggleAddPatientPopup}
            toggleBatchUploadPopup={toggleBatchUploadPopup}
            toggleClaimsPopup={toggleBatchClaimPopup}
            setSelectedPatientsData={setSelectedPatientsData}
          />
        </div>
      </div>
    </div>
  );
}

export default PatientFiles;
