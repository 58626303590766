import React, { useState, useEffect, useRef } from 'react';
import {
  collection,
  query,
  orderBy,
  onSnapshot,
  addDoc,
  serverTimestamp,
} from 'firebase/firestore';
import { db, auth } from '../../../../firebase'; // Update the path as needed
import { getFunctions, httpsCallable } from 'firebase/functions';
import useUID from '../../../General/useUID'; // Hook to get the user's UID/subUID
import Topbar from '../../../General/Topbar'; // Optional top bar
import styles from '../../PatientView.module.css'; // Adjust the styling import

/**
 * Messages Component
 *
 * Props:
 *   - patientId (string): ID of the patient in Firestore (patients/{patientId})
 *   - phoneNumber (string): Patient’s phone number for sending SMS
 */
function Messages({ patientId, phoneNumber, onClose }) {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const messagesEndRef = useRef(null);

  const [uid, subUserUID] = useUID();
  const activeSenderId = subUserUID || uid; // The logged-in user's ID
  const functions = getFunctions();

  useEffect(() => {
    if (!uid) return;

    const messagesRef = collection(
      db,
      'patients',
      uid,
      'patientData',
      patientId,
      'messages'
    );
    const q = query(messagesRef, orderBy('timestamp', 'asc'));

    const unsubscribe = onSnapshot(q, async (snapshot) => {
      const decryptFunction = httpsCallable(functions, 'decrypt');
      const loadedMessages = [];

      for (const docSnap of snapshot.docs) {
        const data = docSnap.data();
        const { ciphertext, iv, senderId, timestamp } = data;

        let decryptedText = '';
        try {
          const response = await decryptFunction({ ciphertext, iv });
          decryptedText = response.data.text;
        } catch (err) {
          console.error('Error decrypting message: ', err);
          decryptedText = '[Error decrypting]';
        }

        loadedMessages.push({
          id: docSnap.id,
          message: decryptedText,
          senderId: senderId || 'unknown',
          timestamp: timestamp?.toDate() || new Date(),
        });
      }

      setMessages(loadedMessages);
    });

    return () => unsubscribe();
  }, [patientId, functions, uid]);

  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (!newMessage.trim()) return;

    setIsLoading(true);

    try {
      console.log('Encrypting the message...');
      const encryptFunction = httpsCallable(functions, 'encrypt');
      const encryptResponse = await encryptFunction({ text: newMessage.trim() });
      const { ciphertext, iv } = encryptResponse.data;

      console.log('Saving encrypted message to Firestore...');
      await addDoc(
        collection(
          db,
          'patients',
          uid,
          'patientData',
          patientId,
          'messages'
        ),
        {
          ciphertext,
          iv,
          senderId: activeSenderId,
          timestamp: serverTimestamp(),
        }
      );

      console.log('Sending SMS to the patient...', phoneNumber);
      const sendSmsFunction = httpsCallable(functions, 'sendPatientsms');
      await sendSmsFunction({
        phoneNumber, // Must be in E.164 format, e.g., "+15555555555"
        patientMessage: newMessage.trim(),
      });

      console.log('Message sent successfully!');
      setNewMessage(''); // Clear the input field
    } catch (error) {
      console.error('Error sending message or SMS:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage(e);
    }
  };

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  const renderMessage = (msg) => {
    const isSender = msg.senderId === activeSenderId;
    return (
      <div
        key={msg.id}
        className={`message ${isSender ? 'sent' : 'received'}`}
      >
        <div className="messageText">{msg.message}</div>
        <div className="timestamp">
          {msg.timestamp.toLocaleString()}
        </div>
      </div>
    );
  };

  return (
    <div>
       <div className="closeButtonContainer">
          <button onClick={onClose} className="filesCloseButton">
            X
          </button>
          <p className="closeBarNav">Messages</p>
        </div>
      <div className="MessageContainer">
        <div className="messagesList">
          {messages.length === 0 ? (
            <div className="noMessages">No messages yet. Start by sending one below.</div>
          ) : (
            messages.map(renderMessage)
          )}
          <div ref={messagesEndRef} />
        </div>

        {/* Input Area */}
        <form onSubmit={handleSendMessage} className="inputArea">
          <textarea
            className="textarea"
            value={newMessage}
            disabled={isLoading}
            onChange={(e) => setNewMessage(e.target.value)}
            placeholder="Send a message..."
            onKeyDown={handleKeyDown}
          />
          <button
            type="submit"
            disabled={isLoading}
            className="sendButton"
          >
            {isLoading ? 'Sending...' : 'Send'}
          </button>
        </form>
      </div>
    </div>
  );
}

export default Messages;
