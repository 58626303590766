import React, { Component, createRef, useState } from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { loadStripe } from '@stripe/stripe-js';
import { useNavigate } from 'react-router-dom';
import HexSpinner from '../General/Animations/Hexspinner'
import SignaturePad from 'react-signature-canvas';
import useUID from '../General/useUID'
import { setDoc, doc } from 'firebase/firestore';
import { db } from '../../firebase'; 

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

function PaymentFormWrapper({ plan, planDetails, price }) {
  const navigate = useNavigate();
  const [error, setError] = useState(null); // Add useState hook for error
  const [isProcessing, setIsProcessing] = useState(false);
  const [uid] = useUID();
  const sigPadRef = createRef();

  const handleForm = async (e, stripe, card, sigPadRef) => {
    e.preventDefault();
    setIsProcessing(true);
    
    // Check if the signature pad is initialized and not empty
    if (!stripe || !sigPadRef.current || sigPadRef.current.isEmpty()) {
      // Adjust the error message based on the specific missing requirement
      const errorMessage = !stripe ? "Stripe not initialized" : "Signature is required";
      setError(errorMessage);
      setIsProcessing(false);
      return;
    }
  
    // Signature pad has been used, proceed with capturing the signature
    const signatureImage = sigPadRef.current.getTrimmedCanvas().toDataURL('image/png');
  
   
    const { token, error } = await stripe.createToken(card);
    if (error) {
      setError(error.message);
      setIsProcessing(false);
    } else {
      let startSubscription;
      if (plan === 'Professional') {
        startSubscription = httpsCallable(getFunctions(), 'startSubscriptionProfessional');
      } else if (plan === 'Enterprise') {
        startSubscription = httpsCallable(getFunctions(), 'startSubscriptionEnterprise');
      }
  
      const res = await startSubscription({ source: token.id });
      if (res.data.success) {
        navigate('/patients');
      } else {
        setError(res.data.message);
        setIsProcessing(false);
      }
    } if (uid) {
      await setDoc(doc(db, "users", uid), { signature: signatureImage }, { merge: true });
    }
  }
  
  

  return (
    <PaymentFormComponent
      handleForm={handleForm}
      plan={plan}
      planDetails={planDetails}
      price={price}
      error={error}
      isProcessing={isProcessing}
    />
  );
  }

class PaymentFormComponent extends Component {
  constructor(props) {
    super(props);
    this.cardForm = createRef();
    this.sigPad = createRef();
    this.state = {
      stripe: null,
      card: null
    };
  }

  async componentDidMount() {
    const stripe = await stripePromise;
    const elements = stripe.elements();
    const card = elements.create('card');
    card.mount(this.cardForm.current);
    this.setState({ stripe, card });
  }

  clearSigPad = () => {
    if (this.sigPad && this.sigPad.current) {
        this.sigPad.current.clear();
    }
}

  render() {
    const { error } = this.props;
    const { handleForm, plan, planDetails, price } = this.props;
  
    return (
      <div className="popupCard">
        <form onSubmit={(e) => {
              e.preventDefault();
              if (!this.state.stripe || !this.sigPad.current) {
                setError("Stripe or signature pad not initialized");
                setIsProcessing(false);
                return;
              }
              handleForm(e, this.state.stripe, this.state.card, this.sigPad) // Pass sigPad as a ref directly
            }}>         
          <h1 className="heading">Enter Your Payment Details</h1>
          <h2 className="subheading">Plan: {plan}</h2>
          <h3 className="price">Price: {price}</h3>
         {planDetails && (
            <ul className="plan-details-list">
              {planDetails.map((detail, index) => (
                <li key={index} className="payment-form__detail-item">
                  {detail}
                </li>
              ))}
            </ul>
          )}
          <div ref={this.cardForm} className="input-field"></div>
          {error && <p className="error-message">{error}</p>}
          <p>By signing below, you attest to the <a href="/terms" target="_blank" rel="noopener noreferrer">terms and conditions</a> of using Popularis.</p>
          <SignaturePad ref={this.sigPad} canvasProps={{ className: 'sigCanvas' }} />
          <p style={{ cursor: 'pointer', marginBottom: "1rem" }} onClick={this.clearSigPad}>Clear Signature</p>
          {this.props.isProcessing ? (
            <div className="spinner-wrapper">
              <HexSpinner />
            </div>
          ) : (
            <button type="submit" className="signup-btn">
              Submit Payment
            </button>
          )}
  
          <div className="powered-by-stripe">
          <p>Powered by  </p>
            <img
              src="https://stripe.com/img/about/logos/logos/blue.png"
              alt="Stripe logo"
              className="stripe-logo"
              style={{marginLeft:".25rem"}}
            />
          </div>
        </form>
      </div>
    );
  }
}
export default PaymentFormWrapper;