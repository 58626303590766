import { useRef, useState, useEffect } from "react";
import styles from "./styles/Home.module.css";
import ReactMarkdown from "react-markdown";
import LoadingDots from "./UI components/loadingdots";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "./UI components/accordion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import botImage from "../../popularis_logos/Popularis_logo_single.png";
import UserIcon from "./UI components/usericon.jsx";
import { faArrowCircleDown } from "@fortawesome/free-solid-svg-icons";
import { getFunctions, httpsCallable } from "firebase/functions";
import TypingText from "./UI components/TypingText";

// Initialize Firebase Functions
const functions = getFunctions();
const callChat = httpsCallable(functions, "chat");

export default function Chat({ special }) {
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(true);

  const [messageState, setMessageState] = useState({
    messages: [
      {
        message:
          "Hello, I am an AI built for healthcare and trained on healthcare specific information. I was trained on information that represents the consensus in our industry.",
        type: "apiMessage",
      },
    ],
    history: [],
  });

  const { messages, history } = messageState;

  const messageListRef = useRef(null);
  const textAreaRef = useRef(null);

  //handle form submission
  async function handleSubmit(e) {
    e.preventDefault();

    setError(null);

    if (!query) {
      alert("Please input a question");
      return;
    }

    const question = query.trim();

    setMessageState((state) => ({
      ...state,
      messages: [
        ...state.messages,
        {
          type: "userMessage",
          message: question,
        },
      ],
    }));

    setLoading(true);
    setQuery("");

    try {
      const uid = "preloaded";

      const result = await callChat({
        question: question,
        history: history,
        uid: uid,
      });

      if (result.data.error) {
        setError(result.data.error);
      } else {
        setMessageState((state) => ({
          ...state,
          messages: [
            ...state.messages,
            {
              type: "apiMessage",
              message: result.data,
              sourceDocs: result.data.sourceDocuments,
            },
          ],
        }));
      }

      setLoading(false);
      scrollToBottom();
    } catch (error) {
      setLoading(false);
      setError("An error occurred while fetching the data. Please try again.");
      console.log("error", error);
    }
  }

  const handleEnter = (e) => {
    if (e.key === "Enter" && query) {
      handleSubmit(e);
    } else if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  const checkScrollPosition = () => {
    if (messageListRef.current) {
      const bottom =
        messageListRef.current.scrollHeight - messageListRef.current.scrollTop ===
        messageListRef.current.clientHeight;
      setIsScrolledToBottom(bottom);
    }
  };

  useEffect(() => {
    if (messageListRef.current) {
      messageListRef.current.addEventListener("scroll", checkScrollPosition);
    }
    return () => {
      if (messageListRef.current) {
        messageListRef.current.removeEventListener("scroll", checkScrollPosition);
      }
    };
  }, []);

  const scrollToBottom = () => {
    if (messageListRef.current) {
      messageListRef.current.scrollTo(0, messageListRef.current.scrollHeight);
    }
  };

  return (
    <main className={styles.main}>
      <div className={`${styles.cloud} ${special ? styles.cloudSpecial : ""}`}>
        <div ref={messageListRef} className={styles.messagelist}>
          {messages.map((message, index) => {
            let icon;
            let className;
            if (message.type === "apiMessage") {
              icon = <img src={botImage} alt="AI" className={styles.boticon} />;
              className = `${styles.apimessage}`;
            } else {
              icon = <UserIcon />;
              className = loading && index === messages.length - 1
                ? styles.usermessagewaiting
                : styles.usermessage;
            }
            return (
              <div key={`chatMessage-${index}`} className={className}>
                {icon}
                <div className={styles.markdownanswer}>
                  {message.type === "apiMessage" ? (
                    <TypingText text={message.message} />
                  ) : (
                    <ReactMarkdown linkTarget="_blank">
                      {message.message}
                    </ReactMarkdown>
                  )}
                </div>
                {message.sourceDocs && (
                  <div className="sources" key={`sourceDocsAccordion-${index}`}>
                    <Accordion type="single" collapsible className="accordion">
                      {message.sourceDocs.map((doc, index) => (
                        <AccordionItem key={`messageSourceDocs-${index}`} value={`item-${index}`}>
                          <AccordionTrigger>
                            <p>Source {index + 1}</p>
                          </AccordionTrigger>
                          <AccordionContent>
                            <ReactMarkdown linkTarget="_blank">
                              {doc.pageContent}
                            </ReactMarkdown>
                          </AccordionContent>
                        </AccordionItem>
                      ))}
                    </Accordion>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
      {!isScrolledToBottom && (
        <button className="scroll-to-bottom-btn" onClick={scrollToBottom}>
          <FontAwesomeIcon icon={faArrowCircleDown} />
        </button>
      )}
      <div className={styles.center}>
        <div className={styles.cloudform}>
          <form onSubmit={handleSubmit}>
            <textarea
              disabled={loading}
              onKeyDown={handleEnter}
              ref={textAreaRef}
              autoFocus={false}
              rows={1}
              maxLength={512}
              id="userInput"
              name="userInput"
              placeholder={loading ? "Waiting for response..." : "Send a message."}
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              className={styles.textareaai}
            />
            <button
              disabled={loading}
              className={styles.generatebutton}
              alt="submit popularisAI chat message"
            >
              {loading ? (
                <div className={styles.loadingwheel}>
                  <LoadingDots color="#000" />
                </div>
              ) : (
                <svg
                  viewBox="0 0 20 20"
                  className={styles.svgicon}
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z"></path>
                </svg>
              )}
            </button>
          </form>
        </div>
      </div>
      <div className={`disclaimer ${special ? "disclaimerSpecial" : ""}`}>
        <p style={{ textAlign: "center" }}>
          PopularisAI might produce inaccurate information. This is not a medical device.
        </p>
      </div>
      {error && (
        <div className="border border-red-400 rounded-md p-4">
          <p className="text-red-500">{error}</p>
        </div>
      )}
    </main>
  );
}