import React, { useEffect, useState, useRef } from 'react';
import {
  collection,
  query,
  where,
  getDocs,
} from 'firebase/firestore';
import { useParams, useNavigate } from 'react-router-dom';
import { db } from '../../../../firebase';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { FaCalendarAlt, FaWpforms } from 'react-icons/fa';
import Topbar from '../../../General/Topbar';
import HexagonSpinner from '../../../General/Animations/Hexspinner';
import useUID from '../../../General/useUID';
import IntakeToolbar from './utilities/IntakeToolbar';
import Sidebar from './Sidebar';
import './IntakeView.css';

function IntakeView() {
  const { clinicId } = useParams();
  const navigate = useNavigate();

  /**
   * Constants
   */
  const ITEMS_PER_LOAD = 50; // Number of patients to load on each scroll

  /**
   * State Management
   */
  const [allPatients, setAllPatients] = useState([]); // All fetched patients
  const [filteredPatients, setFilteredPatients] = useState([]); // Patients after filtering/search
  const [selectedPatients, setSelectedPatients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectAll, setSelectAll] = useState(false);
  const [showCreateEncounter, setShowCreateEncounter] = useState(false);

  const [visibleCount, setVisibleCount] = useState(ITEMS_PER_LOAD); // Initialize with ITEMS_PER_LOAD

  const containerRef = useRef(null); // Ref for the scrollable container
  const sentinelRef = useRef(null); // Ref for the sentinel element
  const [uid] = useUID();

  const functions = getFunctions();
  const batchDecrypt = httpsCallable(functions, 'batchDecryptPatients');

  /**
   * Fetch all appointment docs for this clinic, return a Set of patientIds that have appointments.
   */
  const fetchAppointmentsForClinic = async (clinicId) => {
    try {
      const appointmentsRef = collection(db, `clinics/${clinicId}/appointments`);
      const snapshot = await getDocs(appointmentsRef);

      const patientAppointmentSet = new Set();
      snapshot.forEach((doc) => {
        const data = doc.data();
        if (data.patientId) {
          patientAppointmentSet.add(data.patientId);
        }
      });
      return patientAppointmentSet;
    } catch (error) {
      console.error('Error fetching appointments:', error);
      return new Set(); // Return empty set if error
    }
  };

  /**
   * Decrypt an array of encrypted patient docs using your cloud function.
   */
  const decryptPatients = async (encryptedPatients) => {
    const validEncryptedPatients = encryptedPatients
      .filter(
        (p) => p.encryptedData && p.iv && p.created
      );
    if (!validEncryptedPatients.length) {
      return [];
    }

    try {
      const decryptedResponse = await batchDecrypt({
        patients: validEncryptedPatients.map((patient) => ({
          ciphertext: patient.encryptedData,
          iv: patient.iv,
        })),
      });

      return decryptedResponse.data.decryptedPatients.map((decrypted, index) => ({
        id: validEncryptedPatients[index].id,
        data: decrypted,
        flag: validEncryptedPatients[index].flag,
        clinicToken: validEncryptedPatients[index].clinicToken,
        created: validEncryptedPatients[index].created,
        intakeFormId: validEncryptedPatients[index].intakeFormId,
      }));
    } catch (error) {
      console.error('Error decrypting batch:', error);
      return [];
    }
  };

  /**
   * After decrypting patients, mark who has an appointment and who has an intake form.
   */
  const enrichPatients = (decryptedPatients, appointmentSet) => {
    return decryptedPatients.map((patient) => ({
      ...patient,
      hasAppointment: appointmentSet.has(patient.id),
      hasIntakeForm: !!patient.intakeFormId,
    }));
  };

  /**
   * Fetch ALL patients in one shot (no limit)
   * Called on initial load and when user performs a global search or selects all.
   */
  const fetchAllPatients = async () => {
    setLoading(true);
  
    try {
      const cacheKey = `patients_${clinicId}`;
      const cachedData = localStorage.getItem(cacheKey);
  
      if (cachedData) {
        const parsedData = JSON.parse(cachedData);
        
        // Use cached data if it's not outdated
        if (Date.now() - parsedData.timestamp < 5 * 60 * 1000) { // 5-minute cache expiration
          console.log("Using cached patients");
          setAllPatients(parsedData.data);
          setFilteredPatients(parsedData.data);
          setVisibleCount(ITEMS_PER_LOAD);
          setLoading(false);
          return;
        }
      }
  
      console.log("Fetching fresh patient data...");
      const allQuery = query(
        collection(db, `patients/${uid}/patientData`),
        where('clinicToken', '==', clinicId)
      );
  
      const [allSnapshot, appointmentSet] = await Promise.all([
        getDocs(allQuery),
        fetchAppointmentsForClinic(clinicId),
      ]);
  
      if (allSnapshot.empty) {
        setAllPatients([]);
        setFilteredPatients([]);
        setVisibleCount(ITEMS_PER_LOAD);
        setLoading(false);
        return;
      }
  
      const encryptedPatients = allSnapshot.docs.map((doc) => ({
        id: doc.id,
        encryptedData: doc.data()?.patient?.ciphertext,
        iv: doc.data()?.patient?.iv,
        flag: doc.data().flag,
        clinicToken: doc.data().clinicToken,
        created: doc.data().created,
        intakeFormId: doc.data().intakeFormId,
      }));
  
      const decryptedPatients = await decryptPatients(encryptedPatients);
      const enrichedPatients = enrichPatients(decryptedPatients, appointmentSet);
  
      const sortedPatients = enrichedPatients.sort((a, b) => {
        return new Date(b.created) - new Date(a.created);
      });
  
      // Save fetched data to cache
      localStorage.setItem(cacheKey, JSON.stringify({ timestamp: Date.now(), data: sortedPatients }));
  
      setAllPatients(sortedPatients);
      setFilteredPatients(sortedPatients);
      setVisibleCount(ITEMS_PER_LOAD);
    } catch (error) {
      console.error('Error fetching patients:', error);
    } finally {
      setLoading(false);
    }
  };
  

  /**
   * Load more patients by increasing visibleCount
   */
  const loadMorePatients = () => {
    if (loading) {
      return; // Prevent multiple triggers
    }

    if (visibleCount >= filteredPatients.length) {
      return; // No more patients to load
    }

    setLoading(true);
    // Simulate loading delay (optional)
    setTimeout(() => {
      setVisibleCount((prev) => {
        const newCount = Math.min(prev + ITEMS_PER_LOAD, filteredPatients.length);
        return newCount;
      });
      setLoading(false);
    }, 300);
  };

  /**
   * Refresh data from scratch: fetch all patients again
   */
  const refreshData = () => {
    setAllPatients([]);
    setFilteredPatients([]);
    setSelectedPatients([]);
    setSelectAll(false);
    setVisibleCount(ITEMS_PER_LOAD);
    fetchAllPatients();
  };

  /**
   * Format date utility
   */
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString('en-US', {
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });
  };

  /**
   * Handle "Select All" functionality
   */
  const handleSelectAll = (checked) => {
    setSelectAll(checked);
    if (checked) {
      // Select only the *filtered* patients
      setSelectedPatients(filteredPatients.map((p) => p.id));
    } else {
      setSelectedPatients([]);
    }
  };

  /**
   * Scroll Event Handler
   */
  const handleScroll = () => {
    if (!containerRef.current) {
      return;
    }

    const { scrollTop, clientHeight, scrollHeight } = containerRef.current;


    // Trigger when user is within 100px from the bottom
    if (scrollTop + clientHeight >= scrollHeight - 100) {
      if (!loading && visibleCount < filteredPatients.length) {
        loadMorePatients();
      } else if (loading) {
      } else {
      }
    }
  };

  /**
   * Setup Scroll Event Listener
   */
  useEffect(() => {
    const container = containerRef.current;
    if (!container) {
      return;
    }

    container.addEventListener('scroll', handleScroll);

    return () => {
      container.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  /**
   * Setup IntersectionObserver for infinite scroll
   */
  useEffect(() => {
    if (loading) {
      return; // Do not observe while loading
    }

    if (!sentinelRef.current) {
      return;
    }


    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          if (visibleCount < filteredPatients.length) {
            loadMorePatients();
          } else {
          }
        }
      },
      {
        root: containerRef.current, // Observe within the scrollable container
        rootMargin: '0px',
        threshold: 1.0, // Trigger when sentinel is fully visible
      }
    );

    observer.observe(sentinelRef.current);

    return () => {
      if (sentinelRef.current) {
        observer.unobserve(sentinelRef.current);
      }
    };
    // Dependencies include loading, visibleCount, and filteredPatients.length
  }, [loading, visibleCount, filteredPatients.length]);

  /**
   * Initialize data on component mount
   */
  useEffect(() => {
    if (!clinicId || !uid) {
      return;
    }

    fetchAllPatients();
    // eslint-disable-next-line
  }, [clinicId, uid]);

  useEffect(() => {
    if (selectAll) {
      // keep the selection in sync with the filtered list
      setSelectedPatients(filteredPatients.map((p) => p.id));
    }
  }, [filteredPatients, selectAll]);
  
  /**
   * Reset visibleCount when filteredPatients change
   */
  useEffect(() => {
    setVisibleCount(ITEMS_PER_LOAD);
  }, [filteredPatients]);


  /**
   * Determine patients to display based on visibleCount
   */
  const patientsToDisplay = filteredPatients.slice(0, visibleCount);

  return (
    <>
      <Topbar />
      <div className="intakeViewLayout">
        <Sidebar />
        <div className="intakeViewContent">
          <IntakeToolbar
            selectAll={selectAll}
            setSelectAll={handleSelectAll}
            onRefresh={refreshData}
            selectedPatients={selectedPatients}
            patients={allPatients}
            onFilter={setFilteredPatients}
            clinicToken={clinicId}
            loading={loading}
          />

          <div
            className="patientsList"
            ref={containerRef}
            style={{ height: '80vh', overflowY: 'auto', position: 'relative' }} // Ensure the container is scrollable
          >
            <table className="patientsTable">
              <tbody>
                {patientsToDisplay.map((patient, index) => (
                  <tr
                    key={patient.id}
                  >
                    <td className="patientCheckbox">
                      <input
                        type="checkbox"
                        className="intake-checkbox"
                        checked={selectedPatients.includes(patient.id)}
                        onChange={() => {
                          setSelectedPatients((prev) =>
                            prev.includes(patient.id)
                              ? prev.filter((pid) => pid !== patient.id)
                              : [...prev, patient.id]
                          );
                        }}
                      />
                    </td>
                    <td
                      className="patientRow"
                      onClick={() => navigate(`/patients/${patient.id}`)}
                      style={{ cursor: 'pointer' }}
                    >
                      {`${patient?.data?.patient?.firstName || 'N/A'} ${
                        patient?.data?.patient?.middleName || ''
                      } ${patient?.data?.patient?.lastName || 'N/A'}`.trim()}
                    </td>
                    <td className="patientIcons">
                      {patient.hasAppointment && <FaCalendarAlt title="Appointment" />}
                      {patient.hasIntakeForm && <FaWpforms title="Intake Form" />}
                    </td>
                    <td className="patientDate">{formatDate(patient?.created)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            {loading && (
              <div className="loadingContainer">
                <HexagonSpinner />
                <p>Loading patients...</p>
              </div>
            )}
            {!loading && patientsToDisplay.length === 0 && (
              <div className="noPatients">
                <p>No patients found.</p>
              </div>
            )}
            {/* Sentinel element */}
            <div id="sentinel" ref={sentinelRef} style={{ height: '1px' }}></div>
          </div>
        </div>
      </div>
    </>
  );
}

export default IntakeView;
