import { createUserWithEmailAndPassword, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import React, { useState, useEffect } from "react";
import { auth, db } from "../../firebase";
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import './AUTH.css';
import { doc, setDoc } from "firebase/firestore";
import { Link } from 'react-router-dom';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { Helmet } from 'react-helmet';
import styles from "./AUTH.css";
import { collection, query, where, getDocs } from "firebase/firestore";
import AuthHexSpinner from "./AuthHexSpinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faEyeSlash, faEye  } from "@fortawesome/free-regular-svg-icons";
import { faRocket, faStream, faHeartbeat, faFlask, faInfoCircle  } from "@fortawesome/free-solid-svg-icons";
import googleLogo from '../../components/Website/assets/googleLogo.png';
import logo from '../../popularis_logos/Popularis_logo_wide.png';
import '../Website/subcomponents/MovingGradientBackground.css';
import EncryptionSelection from './EncryptionSelection';
import EncryptionHistogram from "./EncryptionHistogram";

const debounce = (func, wait) => {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

const SignUp = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { token } = useParams() || {};
  const functions = getFunctions();

  const [fullName, setFullName] = useState(localStorage.getItem('fullName') || "");
  const [email, setEmail] = useState(
    localStorage.getItem('email') ||
    new URLSearchParams(location.search).get('email') ||
    ""
  );
  const [password, setPassword] = useState(localStorage.getItem('password') || "");
  const [confirmPassword, setConfirmPassword] = useState(localStorage.getItem('confirmPassword') || "");
  const [role, setRole] = useState('admin'); // Default role or role from invite
  const [error, setError] = useState("");
  const [agree, setAgree] = useState(localStorage.getItem('agree') === 'true');
  const [isLoading, setIsLoading] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [encryptionType, setEncryptionType] = useState("Zero-Trust AES"); // Default to AES
  
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  useEffect(() => {
    // Inject Google reCAPTCHA script
    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`;
    script.async = true;
    document.body.appendChild(script);

    return () => document.body.removeChild(script);
  }, []);

  useEffect(() => {
    validatePassword(password);
  }, [password]);

  useEffect(() => {
    // Sync form fields to localStorage
    localStorage.setItem('fullName', fullName);
    localStorage.setItem('email', email);
    localStorage.setItem('password', password);
    localStorage.setItem('confirmPassword', confirmPassword);
    localStorage.setItem('agree', agree);
  }, [fullName, email, password, confirmPassword, agree]);

  useEffect(() => {
    // If a user is visiting /signup/:token, fetch the corresponding user invite data
    const fetchInviteData = async () => {
      if (token) {
        const tokenQuery = query(collection(db, 'UserInviteTokens'), where('token', '==', token));
        const tokenQuerySnapshot = await getDocs(tokenQuery);
        if (!tokenQuerySnapshot.empty) {
          const inviteData = tokenQuerySnapshot.docs[0].data();
          setEmail(inviteData.email);
          setRole(inviteData.role);
        }
      }
    };
    fetchInviteData();
  }, [token]);

  const validatePassword = debounce((password) => {
    // Only show an error if user typed at least 5 chars
    if (password.length >= 5) {
      const passwordRegex = /^(?=.*\d)(?=.*[A-Z]).{8,}$/;
      if (!passwordRegex.test(password)) {
        setPasswordError(
          "Password must be at least 8 characters long, contain at least one uppercase letter, and at least one number"
        );
      } else {
        setPasswordError("");
      }
    } else {
      setPasswordError("");
    }
  }, 500);

  const gtagSendEvent = (url) => {
    const callback = function () {
      if (typeof url === 'string') {
        window.location = url;
      }
    };
    // Make sure gtag is available in your environment
    gtag('event', 'conversion_event_signup', {
      'event_callback': callback,
      'event_timeout': 2000,
    });
    return false;
  };

  const HandlesignUp = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    // Basic email & password checks
    if (!emailRegex.test(email)) {
      setError("Invalid email format.");
      setIsLoading(false);
      return;
    }
    if (passwordError) {
      setError(passwordError);
      setIsLoading(false);
      return;
    }
    const nameParts = parseFullName(fullName);
    if (!nameParts) {
      setError("Please enter at least first and last name.");
      setIsLoading(false);
      return;
    }
    const { firstName, lastName, middleNames } = nameParts;

    if (!encryptionType) {
      setError("Please select an encryption type.");
      setIsLoading(false);
      return;
    }

    // Attempt geolocation verification, but do NOT break sign-up if it fails
    let geolocationResult = null;
    try {
      const verifyGeolocation = httpsCallable(functions, 'verifyGeolocation');
      geolocationResult = await verifyGeolocation();

      // If verifyGeolocation returns something like { data: { authenticated: false } },
      // we just log a warning and continue anyway.
      if (!geolocationResult.data.authenticated) {
      }
    } catch (geoError) {
      // If the Cloud Function call fails or times out, just warn and continue
    }

    // Proceed with sign-up even if geolocation is not verified or failed
    try {
      const passwordRegex = /^(?=.*\d)(?=.*[A-Z]).{8,}$/;
      if (!passwordRegex.test(password)) {
        setError("Password must be at least 8 characters long, contain at least one uppercase letter, and at least one number.");
        setIsLoading(false);
        return;
      }

      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const userUID = userCredential.user.uid;

      // Log the sign-up event
      await logSignUp(userUID, geolocationResult, email);

      // If it's a subuser sign-up with an invite token
      if (token) {
        await handleSubUserSignUp(token, userCredential, firstName, lastName, email, geolocationResult, middleNames);
      } else {
        // Regular sign-up flow
        await handleRegularSignUp(userCredential, userUID, firstName, lastName, email, middleNames);
      }
    } catch (error) {
      console.error("Error during account creation:", error);
      switch (error.code) {
        case 'auth/email-already-in-use':
          setError("Email already in use. Please try another email.");
          break;
        case 'auth/weak-password':
          setError("Password is too weak.");
          break;
        case 'auth/invalid-email':
          setError("Invalid email format.");
          break;
        default:
          setError("Error creating account. Please try again.");
          break;
      }
      setIsLoading(false);
    }
  };

  const handleRegularSignUp = async (userCredential, userUID, firstName, lastName, email, middleNames) => {
    // Create user doc in Firestore
    await setDoc(doc(db, "users", userUID), {
      firstName,
      lastName,
      middleNames,
      email,
      AccountTier: "Freebie",
      role: role,
      encryptionType,
    });

    sessionStorage.setItem('parentUID', userUID);
    sessionStorage.setItem('effectiveUID', userUID);
    sessionStorage.setItem('userRole', role);

    // Optionally populate default Firestore data
    const accountStartDatabaseFn = httpsCallable(functions, 'accountStartDatabase');
    try {
      await accountStartDatabaseFn({ uid: userUID });
    } catch (error) {
      console.error('Error populating Firestore data: ', error);
    }

    navigate("/patients");

    // Send a verification email
    const sendVerificationEmailFn = httpsCallable(functions, 'sendVerificationEmail');
    try {
      const verificationData = { email, uid: userUID, firstName, lastName };
      await sendVerificationEmailFn(verificationData);
    } catch (error) {
      console.error('Error sending verification email: ', error);
    }

    // Create Stripe customer (if you're using Stripe)
    const createStripeCustomerFn = httpsCallable(functions, 'createStripeCustomer');
    try {
      await createStripeCustomerFn({ uid: userUID, email });
    } catch (error) {
      console.error('Error creating Stripe customer: ', error);
    }

    // Notify your internal system / Slack / email, etc. about the new user
    const newUserNotificationFn = httpsCallable(functions, 'newUserNotification');
    try {
      await newUserNotificationFn({ firstName, lastName, email });
    } catch (error) {
      console.error('Error sending notification:', error);
    }
  };

  const handleSubUserSignUp = async (token, userCredential, firstName, lastName, email, geolocationResult, middleNames) => {
    // Fetch the token from Firestore
    const tokenQuery = query(collection(db, 'UserInviteTokens'), where('token', '==', token));
    const tokenQuerySnapshot = await getDocs(tokenQuery);
    if (tokenQuerySnapshot.empty) {
      setError("Invalid invitation token.");
      setIsLoading(false);
      return;
    }

    const inviterUID = tokenQuerySnapshot.docs[0].data().uid;
    const role = tokenQuerySnapshot.docs[0].data().role;

    // Create subuser under the inviter's document
    await setDoc(doc(db, "users", inviterUID, "subusers", userCredential.user.uid), {
      firstName,
      lastName,
      middleNames,
      email,
      parentUID: inviterUID,
      role: role
    });

    sessionStorage.setItem('effectiveUID', userCredential.user.uid);
    sessionStorage.setItem('parentUID', inviterUID);
    sessionStorage.setItem('userRole', role);

    // Log it as subuser sign-up
    await logSignUp(userCredential.user.uid, geolocationResult, email);

    navigate("/patients");
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const parseFullName = (fullName) => {
    const names = fullName.trim().split(' ').filter(Boolean);
    if (names.length < 2) {
      return null; // Invalid, need at least first and last name
    }
    const firstName = names[0];
    const lastName = names[names.length - 1];
    const middleNames = names.slice(1, -1).join(' ');
    return { firstName, lastName, middleNames };
  };

  const logSignUp = async (userUID, verifyGeolocationResult, email) => {
    // Safely handle if verifyGeolocationResult is null or an unexpected shape
    let city = "Unknown city";
    let country = "Unknown country";
    let state = "Unknown state";
    let postalCode = "Unknown postal code";
    let latitude, longitude, isp, connectionType, ipAddress = "";

    if (verifyGeolocationResult?.data?.fullGeoIPData) {
      const geoData = verifyGeolocationResult.data.fullGeoIPData;
      city = geoData?.city?.names?.en || city;
      country = geoData?.country?.names?.en || country;
      state = geoData?.subdivisions?.[0]?.names?.en || state;
      postalCode = geoData?.postal?.code || postalCode;
      latitude = geoData?.location?.latitude;
      longitude = geoData?.location?.longitude;
      isp = geoData?.traits?.isp;
      connectionType = geoData?.traits?.connectionType;
      ipAddress = verifyGeolocationResult.data.ip || "";
    }

    const currentTime = new Date().toISOString();
    const logMessage = email
      ? `${email} (subuser) signed up from ${city}, ${state}, ${country} (IP: ${ipAddress}). Sign-Up Time: ${currentTime}`
      : `User signed up from ${city}, ${state}, ${country} (IP: ${ipAddress}). Sign-Up Time: ${currentTime}`;

    const addLogFunction = httpsCallable(functions, 'addLog');
    await addLogFunction({
      uid: userUID,
      message: logMessage,
      loginDetails: JSON.stringify(verifyGeolocationResult?.data ?? {})
    });
  };

  const handleGoogleSignUp = async () => {
    setIsLoading(true);
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      const userUID = user.uid;

      // Attempt geolocation check, but do not break sign-up if it fails
      let geolocationResult = null;
      try {
        const verifyGeolocation = httpsCallable(functions, 'verifyGeolocation');
        geolocationResult = await verifyGeolocation();
        if (!geolocationResult.data.authenticated) {
        }
      } catch (geoError) {
      }

      await logSignUp(userUID, geolocationResult);

      // Parse name from Google profile
      const nameParts = parseFullName(user.displayName || '');
      let firstName = '';
      let lastName = '';
      let middleNames = '';
      if (nameParts) {
        firstName = nameParts.firstName;
        lastName = nameParts.lastName;
        middleNames = nameParts.middleNames;
      }

      // If user was invited (token), handle subuser sign-up
      if (token) {
        await handleSubUserSignUp(token, { user }, firstName, lastName, user.email, geolocationResult, middleNames);
      } else {
        // Regular sign-up flow
        await handleRegularSignUp({ user }, userUID, firstName, lastName, user.email, middleNames);
      }

      gtagSendEvent("/patients");
    } catch (error) {
      console.error("Google Sign-Up Error: ", error);
      setError("Error during Google sign-up. Please try again.");
      setIsLoading(false);
    }
  };
  

  return (
    <>
      <Helmet>
        <title>Sign Up | Popularis Health</title>
        <meta name="description" content="Create your account on Popularis Health and start accessing our suite of medical tools. Join us today!" />
        <meta name="keywords" content="Sign Up, Create Account, Medical Tools, Medical Billing Software, Telehealth tools, Healthcare Billing, Clinic Management, Patient Management, AI in Healthcare, Popularis Health" />
        <meta property="og:title" content="Sign Up | Popularis Health" />
        <meta property="og:description" content="Create your account on Popularis Health and start accessing our suite of medical tools. Join us today!" />
        <meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/popularishealth.appspot.com/o/Popularis_logo_single.png?alt=media&token=e079bdf2-360b-42da-9ef2-1cdbdeb474cf" />
        <meta property="og:url" content="https://popularishealth.com/signup" />
      </Helmet>

      <div className="background auth-background-overflow">
        <a href="/" className="logoWrapper">
          <img src={logo} alt="Logo" className="logo" />
        </a>
        <div className="sign-up-background">
          <div className="signup-wrapper">
            {/* Left Section */}
            <div className="signup-left">


            <EncryptionSelection setEncryptionType={setEncryptionType} encryptionType={encryptionType} />
                <EncryptionHistogram encryptionType={encryptionType} />


            </div>

            {/* Sign-Up Form */}
            <div className="signup-container">
              <form onSubmit={HandlesignUp}>
                <h1 className="heading">Create your Popularis Account</h1>
                <div className="customsignup-grid">
                  {/* Full Name */}
                  <div className="input-field customsignup-grid-item">
                    <label htmlFor="fullName">Full Name:</label>
                    <input
                      type="text"
                      value={fullName}
                      onChange={(e) => setFullName(e.target.value)}
                      style={{ minWidth: '15rem' }}
                      className={styles["input-field"]}
                      required
                    />
                  </div>

                  {/* Email */}
                  <div className="input-field customsignup-grid-item">
                    <label htmlFor="email">Email:</label>
                    <input
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className={styles["input-field"]}
                      style={{ minWidth: '15rem' }}
                      required
                    />
                  </div>

                  {/* Password */}
                  <div className="input-field customsignup-grid-item">
                    <label htmlFor="password">Password:</label>
                    <input
                      id="Password"
                      type={showPassword ? 'text' : 'password'}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                      style={{ paddingRight: '40px' }}
                    />
                    <div className='eye-slash-container'>
                      <FontAwesomeIcon
                        className='eye-slash'
                        icon={showPassword ? faEye : faEyeSlash}
                        onClick={togglePasswordVisibility}
                      />
                    </div>
                  </div>
                </div>

                {passwordError && <p className="error-message">{passwordError}</p>}
               

                 {/* ✅ Encryption Selection BEFORE submission */}
              
                {error && <p className="error-message">{error}</p>}
                
                {isLoading ? (
                  <>
                    <AuthHexSpinner />
                    <p className="authMessage">Creating Account...</p>
                  </>
                ) : (
                  <>
                   {/* Show message if Post-Quantum is selected */}

                   <button type="submit" style={{ marginTop: "1rem", textAlign: "center" }} className="signup-btn">
                      Sign Up
                    </button>
                    <div className="divider-wrapper">
                      <hr className="divider-line" />
                      <span className="divider-text">or</span>
                      <hr className="divider-line" />
                    </div>
                    <button
                      type="button"
                      className="signInGoogle-button"
                      onClick={handleGoogleSignUp}
                    >
                      <img src={googleLogo} alt="Google Logo" className="google-logo" />
                      Continue with Google
                    </button>
                  </>
                )}

                <p style={{ marginTop: "3rem", textAlign: "center" }}>
                  Already have an account? <Link to="/signin" className="signin-btn">Sign In</Link>
                </p>
              </form>
            </div>
          </div>
        </div>
        <div className="gradient-skewed-bottom"></div>
      </div>
    </>
  );
};

export default SignUp;
