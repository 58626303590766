import React, { useState, useEffect } from 'react';
import styles from './quicksearch.module.css';
import { auth } from '../../firebase';
import { getFunctions, httpsCallable } from 'firebase/functions';
import PayerSelection from '../Payers/PayerSelection';
import QuicksearchData from './EligibilityResultsViewData';
import HexSpinner from '../General/Animations/Hexspinner';
import { getFirestore, collection, query, orderBy, onSnapshot, doc, getDoc } from 'firebase/firestore';
const db = getFirestore();
import ProviderSelection from '../Providers/ProviderSelection';
import ServiceTypeDropdown from './ServiceTypeDropdown';
import useEmailVerified from '../General/useEmailVerified';
import NoSpaceInput from '../General/NoSpaceInput';
import useUID from "../General/useUID";

function QuickSearch({ onSuccess, firstName: initialFirstName, middleName: initialMiddleName, lastName: initialLastName, dob: initialDob, gender: initialGender, memberid: initialmemberid, patientId, initialTradingPartnerName, initialProcedureCode }) {
  const [firstName, setFirstName] = useState(initialFirstName || '');
  const [middleName, setMiddleName] = useState(initialMiddleName || '');
  const [lastName, setLastName] = useState(initialLastName || '');
  const [dob, setDob] = useState(initialDob || '');
  const [gender, setGender] = useState(initialGender || '');
  const [patientData, setPatientData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPayers, setSelectedPayers] = useState([]);
  const [payers, setPayers] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [payerError, setPayerError] = useState("");
  const [memberId, setmemberId] = useState(initialmemberid || '');
  const [hasRemainingRequests, setHasRemainingRequests] = useState(true);
  const [providerOrganizationName, setProviderOrganizationName] = useState('');
  const [providerNPI, setProviderNPI] = useState('');
  const [providerfirstName, setProviderFirstName] = useState('');
  const [providerlastName, setProviderLastName] = useState('');
  const [medicareNumber, setMedicareNumber] = useState('');
  const [medicaidNumber, setMedicaidNumber] = useState('');
  const [isFormValid, setIsFormValid] = useState(false);
  const [procedureCode, setProcedureCode] = useState(initialProcedureCode || '');
  const [selectedServiceType, setSelectedServiceType] = useState('');
  const isEmailVerified = useEmailVerified();
  const [uid, subUserUID] = useUID();

  // Utility function to format today's date in YYYY-MM-DD format
  const formatDate = (date) => {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  };
  const [dos, setDos] = useState(formatDate(new Date()));

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!isEmailVerified) {
      alert('Please verify your email before submitting claims.');
      return;
    }
  
    const isBCBS = selectedPayers.some(payer => payer.name && payer.name.includes("Blue Cross Blue Shield"));
  
    if (isBCBS && !memberId) {
      setErrorMessage("For Blue Cross Blue Shield payers, Member Id is mandatory.");
      return;  // This should stop the function from proceeding further.
    }
  
    if (!firstName || !lastName || !dob || !gender || !dos || selectedPayers.length === 0) {
      setErrorMessage("Please fill out all required fields.");
      return;
    }
  
    if (!hasRemainingRequests) {
      setErrorMessage("You have reached the limit of 25 API requests for the free trial.");
      return;
    }
  
    setIsLoading(true);
    try {
      const formattedDob = dob ? new Date(dob).toLocaleString('en-US', { timeZone: 'UTC', month: '2-digit', day: '2-digit', year: 'numeric' }).split(',')[0] : '';
      const formattedDos = dos ? new Date(dos).toLocaleString('en-US', { timeZone: 'UTC', month: '2-digit', day: '2-digit', year: 'numeric' }).split(',')[0] : '';
  
      const functions = getFunctions();
      const eligibilitySingleAPI = httpsCallable(functions, 'eligibilitySingleAPI');
  
      const payersArray = selectedPayers.map(payer => ({
        tradingPartnerId: payer.tradingPartnerId,
        name: payer.name
      }));
  
      const result = await eligibilitySingleAPI({
        firstName,
        lastName,
        ...(middleName && { middleName }),
        dob: formattedDob,
        dos: formattedDos,
        gender,
        ...(memberId && { memberId }),
        payers: payersArray,
        uid,
        organizationName: providerOrganizationName,
        providerfirstName: providerfirstName,
        providerlastName: providerlastName,
        npi: providerNPI,
        ...(medicareNumber && { medicareNumber }),
        ...(medicaidNumber && { medicaidNumber }),
        ...(patientId && { patientId }),
        ...(procedureCode && { procedureCode }),
        ...(selectedServiceType && { selectedServiceType }),
      }).catch((error) => {
        console.error("Error calling function: ", error);
      });
  
      handleAPIResult(result);
    } catch (error) {
      console.error("Error in handleRunEligibility: ", error);
      setErrorMessage(`An error occurred while running eligibility check: ${error.message}`);
      setIsModalOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAPIResult = (result) => {
    if (result && result.data && result.data.success && result.data.responses[0].success) {
      const eligibilityData = result.data.responses[0];
      setPatientData(eligibilityData);
    } else {
      processEligibilityErrors(result);
    }
  };
  
  
  const processEligibilityErrors = (result) => {

    let errorMessages = [];
  
    if (result.data.errors) {
      errorMessages = result.data.errors.map(err => {
        return `${err.description} (Code: ${err.code}). Follow up action: ${err.followupAction}. Possible resolution: ${err.possibleResolutions}`;
      });
    } else if (result.data.responses) {
      result.data.responses.forEach(response => {
        if (response.errors && response.errors.length > 0) {
          response.errors.forEach(error => {
            errorMessages.push(`${error.description}. ${error.possibleResolutions}`);
          });
        }
      });
    }
  
    if (errorMessages.length > 0) {
      setErrorMessage(`Eligibility check failed:\n${errorMessages.join('\n')}`);
    } else {
      setErrorMessage("Eligibility check failed (unknown error).");
    }
  
    setIsLoading(false);
  };
  

  const handlePayerSelect = (selectedPayers) => {
    setSelectedPayers(selectedPayers);

    const isBCBS = selectedPayers.some(payer => payer.name && payer.name.includes("Blue Cross Blue Shield"));

    if (isBCBS && !memberId) {
      setErrorMessage("For Blue Cross Blue Shield payers, Member Id is mandatory.");
    } else {
      setErrorMessage("");
    }
  };



  useEffect(() => {
    if (!uid) return;

    const fetchAccountTierAndCheckRemainingRequests = async () => {
      const userDocRef = doc(db, "users", uid);
      const userDocSnap = await getDoc(userDocRef);

      const AccountTier = userDocSnap.data().AccountTier || '';

      const requestsRef = collection(db, "users", uid, "API");
      const q = query(requestsRef, orderBy("timestamp", "desc"));

      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        let total = 0;
        querySnapshot.forEach((doc) => {
          const requestData = doc.data();

          if (requestData.APIRequests) {
            const apiRequestsCount = parseInt(requestData.APIRequests, 10);
            total += apiRequestsCount;
          }
        });

        if (AccountTier === 'Freebie' && total >= 25) {
          setHasRemainingRequests(false);
        } else {
          setHasRemainingRequests(true);
        }
      });

      return () => unsubscribe();
    };

    if (uid) {
      fetchAccountTierAndCheckRemainingRequests();
    }
  }, [uid]);

  useEffect(() => {
    if (firstName && lastName && dob && gender && dos && selectedPayers.length > 0) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [firstName, lastName, dob, gender, dos, selectedPayers]);

  // Function to clear the error message
  const clearErrorMessage = () => {
    setErrorMessage('');
  };



  // Handler function for when service type changes
  const handleServiceTypeChange = (newServiceType) => {
    setSelectedServiceType(newServiceType);
  };


  return (
    <div className={styles.eligibilitySearchContainer}>
      <div className={styles.quickSearchForm}>
        <div className="centerHeader">
          <p className="subheader-container">Use this tool to confirm or discover a patient's eligibility. You can select multiple payers to query all.</p>
        </div>

        <div className='input-group-row'>
          <div className="input-field">
            <label htmlFor="firstname">First Name:</label>
            <NoSpaceInput type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} required />
          </div>
          <div className="input-field">
            <label htmlFor="firstname">Middle Name:</label>
            <NoSpaceInput type="text" value={middleName} onChange={(e) => setMiddleName(e.target.value)} />
          </div>
          <div className="input-field">
            <label htmlFor="firstname">Last Name:</label>
            <NoSpaceInput type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} required />
          </div>
        </div>

        <div className='input-group-row'>
          <div className="input-field">
            <label htmlFor="memberID">Gender:</label>
            <select id="gender" className={styles.genderSelect} value={gender} onChange={(e) => setGender(e.target.value)} required >
              <option value="">Select gender</option>
              <option value="M">Male</option>
              <option value="F">Female</option>
            </select>
          </div>
          <div className="input-field">
            <label htmlFor="memberID">Member Id (optional):</label>
            <NoSpaceInput
              className={styles.memberid}
              type="text"
              value={memberId}
              onChange={(e) => {
                const sanitizedValue = e.target.value.replace(/[^a-zA-Z0-9]/g, ''); // Remove anything that's not a letter or a number
                setmemberId(sanitizedValue);
              }}
            />
          </div>

        </div>
        <div className='input-group-row'>
          <div className="input-field">
            <label htmlFor="dob">Date of Birth:</label>
            <input type="date" id="dob" placeholder="Date of Birth" value={dob} onChange={(e) => setDob(e.target.value)} required />
          </div>
          <div className="input-field">
            <label htmlFor="dos">Date of Service:</label>
            <input
              type="date"
              id="dos"
              placeholder="Date of Service"
              value={dos}
              onChange={(e) => setDos(e.target.value)}
              required
            />
          </div>
        </div>

        <div className='input-group-row'>
          <div className="input-field">
            <label htmlFor="firstname">Procedure Code (optional)</label>
            <input type="text" value={procedureCode} onChange={(e) => setProcedureCode(e.target.value)} required />
          </div>
          <div className="input-field">
            <label htmlFor="serviceType">Service Type (optional)</label>
            <ServiceTypeDropdown onServiceTypeChange={handleServiceTypeChange} />
          </div>
        </div>

        <div className='content-center mb-3 mt-3'>
          <PayerSelection payers={payers} TradingPartnerName={initialTradingPartnerName} onSelect={handlePayerSelect} mode={'eligibility'} />
        </div>
        
          <ProviderSelection
            onProviderSelect={(data) => {
              if (data) {
                setProviderOrganizationName(data.organizationName);
                setProviderNPI(data.npi);
                setProviderFirstName(data.firstName);
                setProviderLastName(data.lastName);
                setMedicareNumber(data.medicareNumber);
                setMedicaidNumber(data.medicaidNumber);
              } else {
                setProviderOrganizationName('');
                setProviderNPI('');
                setProviderFirstName('');
                setProviderLastName('');
                setMedicareNumber('');
                setMedicaidNumber('');
              }
            }}
          />


        {
          errorMessage && (
            <div className={styles.payerErrorMessage}>
              {errorMessage}
            </div>
          )
        }

        <div className="content-center mb-3 mt-3">
          {isLoading ? (
            <HexSpinner />
          ) : (
            <button type="submit" onClick={handleSubmit} disabled={!isFormValid}>
              Search
            </button>

          )}
        </div>
      </div>
      {
        payerError && (
          <div className={styles.payerErrorMessage}>{payerError}</div>
        )
      }
      <div className={styles.patientDataPopupContainer} style={{ display: patientData ? 'block' : 'none' }}>
        {patientData && (
          <div className={styles.patientDataPopupContainer}>
            <QuicksearchData patientData={patientData} onClose={() => setPatientData(null)} />
          </div>
        )}


      </div>
    </div>
  );
}

export default QuickSearch;

