import React, { useState, useEffect } from "react";
import { db } from "../../../../firebase";
import { collection, getDocs, where, query, doc, getDoc, addDoc, setDoc, updateDoc } from "firebase/firestore";
import { useParams, useNavigate } from "react-router-dom";
import "../../AddPatient/AddPatient.modules.css";
import HexagonSpinner from "../../../General/Animations/Hexspinner";
import { getFunctions, httpsCallable } from "firebase/functions";
import ValidateSubform from "../../../PatientFiles/FormEditor/FormSelection/ValidateSubform";
import PersonalInformation from "./PersonalInformation";
import InsuranceInformation from "./InsuranceInformation";
import IntakeSuccess from "./IntakeSuccess";
import Appointments from "./Appointments/Appointments";
import FormDisplay from "../../../PatientFiles/FormEditor/FormSelection/FormDisplay";
import { Stepper, Step, StepLabel, Button, styled } from "@mui/material";
import ScrollToTop from "../../../../ScrollToTop";
import LanguageSelector from "../../../General/LanguageSelector";
import { useTranslation } from "react-i18next";

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

const functions = getFunctions();
const verifyRecaptcha = httpsCallable(functions, 'verifyRecaptcha');

function AddPatientIntakeForm() {
  let { clinic } = useParams() || {};
  const navigate = useNavigate(); // Hook for navigation
  const { t } = useTranslation(); // Enable translation

  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [token, setToken] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [enableAppointments, setEnableAppointments] = useState(false);
  const [clinicId, setClinicId] = useState(null);
  const [clinicData, setClinicData] = useState({});
  const [clinicExists, setClinicExists] = useState(false);
  const [error, setError] = useState([]);
  const [requiredFields, setRequiredFields] = useState({});
  const [formInputValues, setFormInputValues] = useState([]);
  const [appointmentData, setAppointmentData] = useState(null); // Changed to null initially
  const [insuranceData, setInsuranceData] = useState({
    memberId: "",
    selectedPayer: null,
    secondaryMemberId: "",
    selectedSecondaryPayer: null,
    insuranceCardImage: null,
    insuranceCardBackImage: null,
    idFrontImage: null,
    idBackImage: null,
  });
  
  // Update insurance data from the child component
  const handleInsuranceDataChange = (updatedData) => {
    setInsuranceData((prevData) => ({
      ...prevData,
      ...updatedData,
    }));
  };
  
  
  
  const [userData, setUserData] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    gender: "",
    dob: "",
    phone: "",
    email: "",
    address: {
      address1: "",
      address2: "",
      city: "",
      state: "",
      zip: "",
    },
  });
  const [companyLogoUrl, setCompanyLogoUrl] = useState(null);
  const [uid, setUid] = useState(null);
  const [secondaryMemberId, setSecondaryMemberId] = useState("");
  const [selectedSecondaryPayer, setSelectedSecondaryPayer] = useState(null);
  
   // Dynamic step labels
   const steps = [
    { id: "personalInformation", label: t("personalInformation") },
    { id: "insuranceInformation", label: t("insuranceInformation") },
    enableAppointments ? { id: "appointments", label: t("appointments") } : null,
    clinicData.formData?.length > 0 ? { id: "patientIntake", label: t("patientIntake") } : null,
    { id: "complete", label: t("complete") },
  ].filter(Boolean);
  

  useEffect(() => {
    setError([]);
    setIsLoading(true);
    const fetchOwnerIdAndToken = async () => {
      try {
        // Search for the clinic document where the clinicNameURL matches the 'clinic' from useParams
        const clinicQuery = query(
          collection(db, "clinics"),
          where("clinicNameURL", "==", clinic)
        );
        const querySnapshot = await getDocs(clinicQuery);

        if (!querySnapshot.empty) {
          const clinicDocData = querySnapshot.docs[0].data();
          const uid = clinicDocData.ownerId;
          const clinicToken = querySnapshot.docs[0].id;

          setUid(uid)
          setClinicId(clinicToken);
          setClinicData(clinicDocData);

          setRequiredFields({
            firstName: true,
            lastName: true,
            gender: true,
            dob: true,
            address: clinicDocData.requireAddress ?? false,
            addInsuranceCardCamera:
              clinicDocData.addInsuranceCardCamera ?? false,
            payer: clinicDocData.requireInsuranceInfo ?? false,
            memberId: clinicDocData.requireInsuranceInfo ?? false,
            phone: clinicDocData.requirePhone ?? false,
          });

          setEnableAppointments(clinicDocData.appointmentsEnabled ?? false);

          setToken(clinicToken);
          setClinicExists(true);

          // Fetch company logo URL if it exists  
          const companyImageDocRef = doc(db, "customerCompanyImage", uid);
          const companyImageDoc = await getDoc(companyImageDocRef);

          if (companyImageDoc.exists() && companyImageDoc.data().imageUrl) {
            setCompanyLogoUrl(companyImageDoc.data().imageUrl);
          }
        } else {
          navigate('/404'); // Redirect to 404 if clinic does not exist
        }
      } catch (error) {
        console.error("Error getting document:", error);
        navigate('/404'); // Redirect to 404 on error
      }
      setIsLoading(false);
    };

    fetchOwnerIdAndToken();
  }, [clinic, navigate]);

  useEffect(() => {
    window.scrollTo(0, 0);
    setError([]); // Clear errors when the step changes
  }, [activeStep]);
  

  const MobileStepperRoot = styled(Stepper)(({ theme }) => ({
    [theme.breakpoints.down("sm")]: {
      padding: 0,
      "& .MuiStepLabel-root": {
        padding: 0,
        "& .MuiStepLabel-label": {
          fontSize: "0.75rem", // Reduce font size for step labels
          display: "none"
        },
      },
      "& .MuiStepIcon-root": {
        fontSize: "1rem", // Reduce font size for step icons
      },
    },
  }));

  const handleUpdateUserData = (updatedUserData) => {
    setUserData(updatedUserData);
  };

  const validatePersonalInformation = () => {
    const {
      firstName,
      lastName,
      gender,
      dob,
      phone,
      email,
      address: { address1, city, state, zip } = {},
    } = userData || {};
  
    const minDate = new Date();
    minDate.setFullYear(minDate.getFullYear() - 200);
  
    const errors = [];
  
    if (requiredFields.firstName && !firstName) errors.push(t("firstNameRequired"));
    if (requiredFields.lastName && !lastName) errors.push(t("lastNameRequired"));
    if (requiredFields.gender && !gender) errors.push(t("genderRequired"));
    if (requiredFields.dob && (!dob || new Date(dob) >= new Date() || new Date(dob) < minDate)) 
      errors.push(t("invalidDateOfBirth"));
    if (requiredFields.phone && (!phone || !/^\(\d{3}\)\s\d{3}-\d{4}$/.test(phone))) 
      errors.push(t("invalidPhoneNumber"));
    if (email && !/^\S+@\S+\.\S+$/.test(email)) errors.push(t("invalidEmailAddress"));
    if (requiredFields.address && !address1) errors.push(t("addressLine1Required"));
    if (requiredFields.address && !city) errors.push(t("cityRequired"));
    if (requiredFields.address && (!state || !/^[A-Z]{2}$/.test(state))) errors.push(t("invalidState"));
    if (requiredFields.address && (!zip || !/^\d{5}$/.test(zip))) errors.push(t("invalidZipCode"));
  
    setError(errors);
    return errors.length === 0;
  };
  

  const handleAppointmentSelected = async (appointmentData) => {
    setAppointmentData(appointmentData);
    if (activeStep === 2) {
      await handleNext(true); // Pass a flag to indicate that the appointment was selected
    }
  };

  const saveAppointmentData = async (patientId) => {
    try {
      await addDoc(collection(db, `clinics/${clinicId}/appointments`), {
        ...appointmentData,
        patientId: patientId,
      });
    } catch (error) {
      console.error("Error saving the appointment: ", error);
      throw error; // Rethrow error to handle it in the saveIntake function
    }
  };

  const saveIntake = async () => {
    const encryptFunction = httpsCallable(functions, "encrypt");
  
    // Extract Primary and Secondary Payer Details
    const primaryPayerInfo = {
      memberId: insuranceData?.memberId || "",
      name: insuranceData?.selectedPayer?.name || "",
      EligibilityPayerID: insuranceData?.selectedPayer?.EligibilityPayerID || "",
      ClaimStatusPayerID: insuranceData?.selectedPayer?.ClaimStatusPayerID || "",
      CPID: insuranceData?.selectedPayer?.CPID || "",
      PayerID: insuranceData?.selectedPayer?.PayerID || "",
    };
  
    const secondaryPayerInfo = insuranceData?.selectedSecondaryPayer
      ? {
          name: insuranceData.selectedSecondaryPayer?.name || "",
          memberId: insuranceData.secondaryMemberId || "",
          EligibilityPayerID:
            insuranceData.selectedSecondaryPayer?.EligibilityPayerID || "",
          ClaimStatusPayerID:
            insuranceData.selectedSecondaryPayer?.ClaimStatusPayerID || "",
          CPID: insuranceData.selectedSecondaryPayer?.CPID || "",
          PayerID: insuranceData.selectedSecondaryPayer?.PayerID || "",
        }
      : null;
  
    // Combine Personal + Insurance Data
    const combinedData = {
      patient: {
        ...userData,
      },
      payers: {
        ...primaryPayerInfo,
        secondaryPayer: secondaryPayerInfo,
      },
    };
  
    try {
      // 1) Encrypt combinedData for the patient
      const encryptedData = await encryptFunction(combinedData);
  
      // 2) Generate a patientId for the patients/{uid}/patientData collection
      const patientId = doc(collection(db, `patients/${uid}/patientData`)).id;
      const patientRef = doc(db, `patients/${uid}/patientData/${patientId}`);
      const createdTimestamp = new Date().toISOString();
  
      // 3) If there's sub-form data (e.g. clinicData.formData + formInputValues), encrypt and save it
      //    in the "intakeForms" subcollection under this clinic. 
      //    (Only do this if your front end is collecting formInputValues.)
      let intakeFormId = null; // We'll store this in the patient doc if it exists
      if (clinicData.formData?.length > 0 && formInputValues?.length > 0) {
        // Generate an ID for the new intake form doc
        intakeFormId = doc(collection(db, `clinics/${clinicId}/intakeForms`)).id;
        const intakeRef = doc(db, `clinics/${clinicId}/intakeForms/${intakeFormId}`);
  
        // Encrypt the form data from formInputValues
        const encryptedSubFormData = await encryptFunction({ formInputs: formInputValues });
  
        // Save it to the "clinics/clinicId/intakeForms" collection
        await setDoc(intakeRef, {
          ciphertext: encryptedSubFormData.data.ciphertext,
          iv: encryptedSubFormData.data.iv,
          patientId,
          created: createdTimestamp,
        });
      }
  
      // 4) Save the patient document itself
      await setDoc(patientRef, {
        patient: {
          ciphertext: encryptedData.data.ciphertext,
          iv: encryptedData.data.iv,
        },
        clinicToken: token,
        deleted: false,
        flag: "intake",
        created: createdTimestamp,
        ...(intakeFormId ? { intakeFormId } : {}), // store intakeFormId in patient doc if it exists
      });
  
      // 5) Upload insurance and ID card images (if any)
      const saveFile = async (fileUrl, fileName) => {
        if (fileUrl) {
          const fileData = {
            url: fileUrl,
            name: fileName,
            timestamp: createdTimestamp,
          };
          await setDoc(
            doc(db, `patients/${uid}/patientData/${patientId}/files`, fileName),
            fileData
          );
        }
      };
  
      await saveFile(insuranceData.insuranceCardImage, "Insurance Card (Front)");
      await saveFile(insuranceData.insuranceCardBackImage, "Insurance Card (Back)");
      await saveFile(insuranceData.idFrontImage, "ID Card (Front)");
      await saveFile(insuranceData.idBackImage, "ID Card (Back)");
  
      // 6) If an appointment was scheduled, save that too
      if (appointmentData) {
        await saveAppointmentData(patientId);
      }
  
      // On success
      setSuccess(true);
      setError([]);
      return true;
    } catch (error) {
      console.error("Error saving intake data:", error);
      setError(["Error saving intake data."]);
      setSuccess(false);
      return false;
    } finally {
      setIsLoading(false);
    }
  };
  
  
  
  
  const handleBack = () => {
    console.log("Validating before going back...");
  
    // Validate the current step before allowing the user to navigate back
    if (activeStep === 1) {
      const isValid = validateInsuranceInformation();
      if (!isValid) {
        console.error("Insurance information validation failed.");
        return; // Block navigation if validation fails
      }
    } else if (activeStep === 0) {
      const isValid = validatePersonalInformation();
      if (!isValid) {
        console.error("Personal information validation failed.");
        return; // Block navigation if validation fails
      }
    }
  
    // If validation passes, navigate back
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setError([]); // Clear errors when navigating back successfully
  };
  
  
  const handleNext = async () => {
    console.log("Validating before moving forward...");
  
    if (activeStep === 0) {
      const isValid = validatePersonalInformation();
      if (!isValid) {
        console.error("Personal information validation failed.");
        return; // Block navigation if validation fails
      }
    } else if (activeStep === 1) {
      const isValid = validateInsuranceInformation();
      if (!isValid) {
        console.error("Insurance information validation failed.");
        return; // Block navigation if validation fails
      }
    } else if (activeStep === steps.length - 2) {
      if (clinicData.formData?.length > 0) {
        let subFormValidation = ValidateSubform(clinicData.formData, formInputValues);
        if (!subFormValidation.isValid) {
          console.error("Subform validation failed:", subFormValidation.message);
          setError([subFormValidation.message]);
          return; // Block navigation if subform validation fails
        }
      }
  
      setIsLoading(true);
      try {
        const success = await saveIntake();
        if (success) {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
          setError([]); // Clear errors on success
        }
      } catch (error) {
        console.error("Error during saving process:", error);
        setError(["Error during saving process."]);
      }
      setIsLoading(false);
      return;
    }
  
    // If validation passes, navigate forward
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setError([]); // Clear errors when navigating successfully
  };
  
  

  const validateInsuranceInformation = () => {
    const errors = [];
  
    // Validate Member ID
    if (!insuranceData.memberId) {
      errors.push("Primary Member ID is required.");
      console.error("Validation failed: Primary Member ID is missing.");
    }
  
    // Validate Selected Payer
    if (!insuranceData.selectedPayer) {
      errors.push("Primary Payer is required.");
      console.error("Validation failed: Primary Payer is missing.");
    } else {
      // Validate specific properties of selectedPayer
      if (!insuranceData.selectedPayer.name) {
        errors.push("Selected payer must have a valid name.");
        console.error("Validation failed: Selected payer has no name.");
      }
    }
  
    setError(errors);
    console.log("Validation Errors:", errors); // Log all errors for debugging
    return errors.length === 0;
  };


  useEffect(() => {
    const fetchClinicData = async () => {
      try {
        const clinicQuery = query(
          collection(db, "clinics"),
          where("clinicNameURL", "==", clinic)
        );
        const querySnapshot = await getDocs(clinicQuery);

        if (!querySnapshot.empty) {
          const clinicDocData = querySnapshot.docs[0].data();
          setClinicData(clinicDocData);
          setRequiredFields({
            firstName: true,
            lastName: true,
            gender: true,
            dob: true,
            address: clinicDocData.requireAddress ?? false,
            memberId: clinicDocData.requireInsuranceInfo ?? false,
            phone: clinicDocData.requirePhone ?? false,
          });
        } else {
          navigate("/404");
        }
      } catch (error) {
        console.error("Error fetching clinic data:", error);
        navigate("/404");
      }
    };

    fetchClinicData();
  }, [clinic, navigate]);



  const getStepContent = (stepIndex) => {
    const stepId = steps[stepIndex]?.id;
  
    switch (stepId) {
      case "personalInformation":
        return (
          <div className="pt-5">
            <LanguageSelector />
            {companyLogoUrl ? (
              <img
                src={companyLogoUrl}
                alt="Company Logo"
                className="company-logo"
                style={{ maxWidth: "200px", marginBottom: "20px" }}
              />
            ) : clinicData.clinicName ? (
              <div className="company-name-placeholder">{clinicData.clinicName}</div>
            ) : null}
            <PersonalInformation
              requiredFields={requiredFields}
              UserData={userData || {}}
              onUpdateUserData={handleUpdateUserData}
              errors={error}
            />
          </div>
        );
        case "insuranceInformation":
          return (
              <InsuranceInformation
                  uid={uid}
                  insuranceData={insuranceData}
                  onInsuranceInfoChange={handleInsuranceDataChange}
              />
          );
      
      case "appointments":
        return enableAppointments ? (
          <div className="pt-5">
            <Appointments
              clinicId={clinicId}
              onAppointmentSelected={handleAppointmentSelected}
            />
          </div>
        ) : null;
      case "patientIntake":
        return success ? (
          <IntakeSuccess
            appointmentData={appointmentData}
            location={clinicData.location}
          />
        ) : (
          <div className="pt-5">
            <FormDisplay
              formData={clinicData.formData}
              onFormInputChange={setFormInputValues}
              formInputValuesInit={formInputValues}
            />
          </div>
        );
      case "complete":
        return (
          <IntakeSuccess
            appointmentData={appointmentData}
            location={clinicData.location}
          />
        );
      default:
        return t("unknownStep");
    }
  };
  
  



  return (
    <div className="IPA-background">
      <ScrollToTop />
      <div className="grid-container-lg">
        <div className="main-form">
          {/* Stepper UI */}
          <MobileStepperRoot activeStep={activeStep} alternativeLabel>
            {steps.map((step) => (
              <Step key={step.id}>
                <StepLabel>{step.label}</StepLabel>
              </Step>
            ))}
          </MobileStepperRoot>


          {/* Current Step Content */}
          {getStepContent(activeStep)}

          <div>
            {Array.isArray(error) && error.length > 0 && (
              <div className="error-message mt-4">
                {error.map((errorMessage, index) => (
                  <p key={index}>{errorMessage}</p>
                ))}
              </div>
            )}
          </div>

          {activeStep < steps.length - 1 && (
            <div className="display-flex mt-4 pt-4 px-3">
              {activeStep > 0 && (
                <Button onClick={handleBack}>
                {t("back")}
              </Button>
              )}
              {isLoading ? (
                <div className="w-100 m-auto">
                  <HexagonSpinner />
                </div>
              ) : (
                <Button
                  variant="contained"
                  onClick={handleNext}
                  disabled={isLoading}
                >
                  {activeStep === steps.length - 2 ? t("submit") : t("next")}
                </Button>
              )}
            </div>
          )}

        </div>
      </div>
    </div>
  );
}

export default AddPatientIntakeForm;
