import React, { useState } from "react";
import styles from "../PatientView.module.css";
import { doc, collection, addDoc, serverTimestamp } from "firebase/firestore";
import { db } from "../../../firebase";
import useUID from "../../General/useUID";
import { getFunctions, httpsCallable } from "firebase/functions";
import HexagonSpinner from "../../General/Animations/Hexspinner";

const functions = getFunctions();
const encryptFunction = httpsCallable(functions, "encrypt");

function LabAndTest({ patientId, onClose }) {
  const [uid, subUserUID, isLoading, error] = useUID();
  const [labTests, setLabTests] = useState({
    testName: "",
    testDate: "",
    testResults: "",
    notes: "",
  });
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = (e) => {
    setLabTests({
      ...labTests,
      [e.target.name]: e.target.value,
    });
    setErrorMessage("");
  };

  const isFormEmpty = () => {
    return !labTests.testName || !labTests.testDate;
  };

  const isDateValid = (date) => {
    const selectedDate = new Date(date);
    const today = new Date();
    return selectedDate <= today;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isFormEmpty()) {
      setErrorMessage("Please fill in at least the test name and date.");
      return;
    }

    if (!isDateValid(labTests.testDate)) {
      setErrorMessage("The test date cannot be in the future.");
      return;
    }

    setLoading(true);

    try {
      const encryptedResponse = await encryptFunction(labTests);
      const encryptedData = encryptedResponse.data;

      const medicalHistoryRef = collection(
        doc(db, "patients", uid, "patientData", patientId),
        "medicalHistory"
      );

      await addDoc(medicalHistoryRef, {
        type: "LabAndTest",
        timestamp: serverTimestamp(),
        ciphertext: encryptedData.ciphertext,
        iv: encryptedData.iv,
      });

      const logData = {
        timestamp: Date.now(),
        patientId: patientId,
        activity: "Medical history added",
        activityType: "medicalHistory",
        uid: uid,
      };

      await addDoc(collection(db, "users", uid, "activityLogs"), logData);

      setLabTests({
        testName: "",
        testDate: "",
        testResults: "",
        notes: "",
      });
      onClose();
    } catch (error) {
      console.error("Error during encryption or adding document: ", error);
      setErrorMessage("An error occurred while submitting the form. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.labAndTestForm}>
      <form onSubmit={handleSubmit}>
        <div className="input-group-row">
          <div className="input-field">
            <label htmlFor="testName">Test Name:</label>
            <input
              type="text"
              id="testName"
              name="testName"
              value={labTests.testName}
              onChange={handleChange}
              placeholder="Name of the lab or test"
            />
          </div>
        </div>

        <div className="input-group-row">
          <div className="input-field">
            <label htmlFor="testDate">Test Date:</label>
            <input
              type="date"
              id="testDate"
              name="testDate"
              value={labTests.testDate}
              onChange={handleChange}
            />
          </div>
          <div className="input-field">
            <label htmlFor="testResults">Test Results:</label>
            <input
              type="text"
              id="testResults"
              name="testResults"
              value={labTests.testResults}
              onChange={handleChange}
              placeholder="Results or findings"
            />
          </div>
        </div>

        <div className="input-group-row">
          <div className="input-field">
            <label htmlFor="notes">Notes:</label>
            <textarea
              id="notes"
              name="notes"
              value={labTests.notes}
              onChange={handleChange}
              placeholder="Any additional notes or comments"
            />
          </div>
        </div>

        {errorMessage && (
          <div className={styles.errorMessage}>{errorMessage}</div>
        )}

        {loading ? (
          <HexagonSpinner />
        ) : (
          <button
            type="submit"
            className={styles.submitButton}
            disabled={loading}
          >
            Submit Lab & Test
          </button>
        )}
      </form>
    </div>
  );
}

export default LabAndTest;
