import React, { useState, useEffect } from "react";
import styles from "./PatientFiles.modules.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolder, faTrash, faHospital } from "@fortawesome/free-solid-svg-icons";
import { db } from "../../firebase";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import useUID from "../General/useUID";

function FolderControls({ onFolderChange, toggleClinicPopup }) {
  const [clinics, setClinics] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState("All Patients");
  const [uid, subUserUID] = useUID();

  useEffect(() => {
    if (!uid) return;

    const clinicsCol = collection(db, "clinics");
    const q = query(clinicsCol, where("ownerId", "==", uid));

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const clinicList = snapshot.docs
        .map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }))
        .filter((clinic) => clinic.deleted !== true); // Exclude deleted clinics

      setClinics(clinicList);
    });

    // Select "All Patients" automatically
    handleFolderSelect("All Patients");

    // Clean up the listener when the component unmounts
    return () => unsubscribe();
  }, [uid]);

  const handleFolderSelect = (folder) => {
    setSelectedFolder(folder);
    onFolderChange(folder);
  };

  return (
    <div className={styles.folderSelectContainer}>
      <ul className="folderList">

        {/* Button that toggles the Clinics popup */}
        <div>
          <button
            id="AddClinicButton"
            onClick={toggleClinicPopup}
            className="addClinicButton"
          >
            <FontAwesomeIcon icon={faHospital} style={{ marginRight: "10px" }} />
            <div className="patientsActionButtonText">Clinics</div>
          </button>
        </div>

        <li
          className={
            selectedFolder === "All Patients"
              ? "folder selectedFolder"
              : "folder"
          }
          onClick={() => handleFolderSelect("All Patients")}
          style={{ cursor: "pointer" }}
        >
          <FontAwesomeIcon icon={faFolder} className="folderIcon" />
          <span className={styles.folderName}>All Patients</span>
        </li>

        {clinics.map((clinic) => (
          <li
            key={clinic.id}
            className={
              selectedFolder === clinic ? "folder selectedFolder" : "folder"
            }
            onClick={() => handleFolderSelect(clinic)}
            style={{ cursor: "pointer" }}
          >
            <FontAwesomeIcon icon={faFolder} className="folderIcon" />
            <span className={styles.folderName}>{clinic.clinicName}</span>
          </li>
        ))}

        <li
          className={
            selectedFolder === "Deleted Patients"
              ? "folder selectedFolder"
              : "folder"
          }
          onClick={() => handleFolderSelect("Deleted Patients")}
          style={{ cursor: "pointer" }}
        >
          <FontAwesomeIcon icon={faTrash} className="folderIcon" />
          <span className={styles.folderName}>Deleted</span>
        </li>
      </ul>
    </div>
  );
}

export default FolderControls;
