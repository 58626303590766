import React, { useState, useEffect } from "react";
import styles from "./PatientView.module.css";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileInvoiceDollar,
  faNotesMedical,
  faFileMedical,
  faStaffSnake,
  faVideo,
  faEnvelope,
  faSms,
  faPhone
} from "@fortawesome/free-solid-svg-icons";
import Modal from "react-modal";
import {
  doc,
  getDoc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../firebase";
import PatientFilesEligibility from "./Billing/PatientFilesEligibility";
import PatientFilesClaimStatus from "./Billing/PatientFilesClaimStatus";
import PatientFilesClaimsSend from "./Billing/PatientFilesClaim";
import Zoom from "./Contact/Zoom/ZoomModal";
import Notes from "./Notes/Notes";
import Files from "./Files";
import EditPatientPage from "./EditPatientPage";
import PatientDataDownload from "./Download/PatientDataDownload";
import Encounters from "./Encounters/Encounters";
import EncountersView from "./Encounters/EncountersView";
import useUID from "../General/useUID";
import ClaimsView from "./Billing/ClaimsView";
import EligibilityCards from "./Billing/EligibilityView";
import NotesView from "./Notes/NotesView";
import MedicalHistory from "./MedicalHistory/MedicalHistory";
import PatientFlag from "./PatientFlags";
import InstantEligibility from "./Billing/InstantEligibility";
import PatientFileTimer from "./PatientFileTimer";
import { getFunctions, httpsCallable } from "firebase/functions";
import { SHA256 } from "crypto-js";
import IntakeView from "./Clinics/Intake/PatientFilesIntakeView";
import ClinicNameFetcher from "./ClinicNameFetcher";
import InsuranceStatus from "./InsuranceStatus";
import AddPatientPopup from "./AddPatient/AddPatientPopup";
import ClinicsPopup from "./Clinics/Clinics";
import Messages from "./Contact/messages/patientMessages";
import BatchClaims from "./Billing/PatientsBatchClaims";

function PatientView({ patientSelected, onDataUpdated, resetView, showAddPatien = false, selectedFolder, isAddPatientOpen, isBatchUploadOpen, toggleAddPatientPopup, toggleBatchUploadPopup, toggleClinicPopup, showBatchClaimsModal, isAddClinicOpen, selectedPatientsData  }) {
  const [isEditing, setisEditing] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [editedPatientData, setEditedPatientData] = useState(null);
  const [showPatientFilesEligibility, setShowPatientFilesEligibility] = useState(false);
  const [showPatientFilesClaimStatus, setShowPatientFilesClaimStatus] = useState(false);
  const [showPatientFilesClaims, setShowPatientFilesClaims] = useState(false);
  const [showZoom, setShowZoom] = useState(false);
  const [showNotes, setShowNotes] = useState(false);
  const [showFiles, setShowFiles] = useState(false);
  const [showEncounters, setShowEncounters] = useState(false);
  const [showAddPatient, setShowAddPatient] = useState(showAddPatien);
  const [showTools, setShowTools] = useState(false);
  const [uid, subUserUID] = useUID();
  const [userRole, setUserRole] = useState(null);
  const [showContactOptions, setShowContactOptions] = useState(false);
  const [showMessages, setShowMessages] = useState(false);

  const functions = getFunctions();
  useEffect(() => {
    // Reset all sections when the resetView flag changes
    setShowPatientFilesEligibility(false);
    setShowPatientFilesClaimStatus(false);
    setShowPatientFilesClaims(false);
    setShowZoom(false);
    setShowNotes(false);
    setShowFiles(false);
    setShowEncounters(false);
    setShowAddPatient(false);
    setShowTools(false);
  }, [resetView]);

  useEffect(() => {
    const fetchUserRole = async () => {
      if (!uid) return;

      try {
        const userRef = subUserUID
          ? doc(db, "users", uid, "subusers", subUserUID)
          : doc(db, "users", uid);

        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setUserRole(userData.role || 'nurse'); // default to 'nurse' if role is undefined
        }
      } catch (error) {
        console.error("Error fetching user role:", error);
      }
    };

    fetchUserRole();
  }, [uid, subUserUID]);

  if (!patientSelected) {
    return <div></div>;
  }
  const patientData = patientSelected.data;
  console.log(patientSelected)
  const { patient } = patientData;
  const firstName = patient ? patient.firstName : patientData.subfirstName;
  const middleName = patient ? patient.middleName : patientData.submiddleName;
  const lastName = patient ? patient.lastName : patientData.sublastName;
  const memberid =
    patientData.payers?.memberId || patientData.eligibility?.submemberId;
  const address1 =
    patient?.address?.address1 || patientData.eligibility?.subAddress1;
  const address2 =
    patient?.address?.address2 || patientData.eligibility?.subAddress2;
  const city = patient?.address?.city || patientData.eligibility?.subCity;
  const state = patient?.address?.state || patientData.eligibility?.subState;
  const zip = patient?.address?.zip;
  const email = patient?.email;
  const phone = patient?.phone;
  const tradingPartnerName = patientData.payers?.name;
  const dob = (patient.dob);
  const gender = patient.gender
  const flag = patientData?.flag;
  const clinicToken = patientSelected?.clinicToken;
  const intakeId = patientSelected?.intakeFormId;
  const isDeleted = patientData?.deleted === true;

  const handleSendClaim = () => {
    setShowPatientFilesClaims(!showPatientFilesClaims);
  };

  const handleZoom = () => {
    setShowZoom(!showZoom);
  };

  const handleNotes = () => {
    setShowNotes(!showNotes);
  };
  const handleFiles = () => {
    setShowFiles(!showFiles);
  };


  const handlePhone = () => {
    // Check if `phone` exists and is non-empty
    if (!phone || phone.trim() === "") {
      alert("No phone number on file for this patient.");
      return;
    }
    
    // If a phone number exists, proceed
    setShowMessages(true);
    setShowContactOptions(false);
  };
  


  const handleEmail = () => {
    // Check if `email` exists and is non-empty
    if (!email || email.trim() === "") {
      alert("No email on file for this patient.");
      return;
    }
    // If an email exists, open the mail client
    window.open(`mailto:${email}`, "_blank");
    setShowContactOptions(false);
  };
  
  

  function hashDocIdToNumber(docId) {
    const hash = SHA256(docId).toString(); // This gives you the hash as a hex string
    const largeNumber = parseInt(hash.slice(0, 15), 16); // Convert the first 15 characters to an integer
    return largeNumber % 1000000000; // Use modulo to limit it to 9 digits
  }
  const patientMRN = hashDocIdToNumber(patientSelected.id);

  const handleEncounters = () =>
    (!address1 && !address2) ? (alert("No address found. Cannot use encounters for patients with no address."), setShowEncounters(false))
      : setShowEncounters(!showEncounters);

  const formatGender = (gender) =>
    /^(m|male)$/i.test(gender) ? "Male" : /^(f|female)$/i.test(gender) ? "Female" : "";

  //autistics capitalization function
  function capitalizeName(name) {
    if (!name) {
      return "";
    }
    return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
  }

  const handleShowEditPage = () => setisEditing(!isEditing);

  const handleCloseEditPage = () => setisEditing(false);

  const handleDelete = () => setShowConfirmModal(true);

  const confirmDelete = async () => {
    // Mark the patient as "deleted" in Firestore
    const patientRef = doc(
      db,
      "patients",
      uid,
      "patientData",
      patientSelected.id
    );

    // Update the "deleted" field of the document
    const test = await updateDoc(patientRef, {
      deleted: true,
    });

    // Close the confirmation modal
    setShowConfirmModal(false);
    setisEditing(false);
  };

  const cancelDelete = () => {
    setShowConfirmModal(false);
  };

  const handleSavePatientData = async () => {
    const patientRef = doc(db, "patients", uid, "patientData", patientSelected.id);
    try {
      const docSnap = await getDoc(patientRef);
      if (docSnap.exists()) {
        const encryptedData = docSnap.data();

        // Decrypt data
        const decryptFunction = httpsCallable(functions, "decrypt");
        const decryptedResult = await decryptFunction(encryptedData);
        const decryptedData = decryptedResult.data;

        return decryptedData; // Return decrypted data for further use, such as populating a form
      } else {
        console.error("No such document!");
        return null;
      }
    } catch (error) {
      return null;
    }
  };

  const handleRecoverPatient = async () => {
    const patientRef = doc(db, "patients", uid, "patientData", patientSelected.id);
    try {
      // Retrieve the encrypted patient data
      const docSnap = await getDoc(patientRef);
      if (docSnap.exists()) {
        const encryptedData = docSnap.data();

        const decryptFunction = httpsCallable(functions, "decrypt");
        const decryptedResult = await decryptFunction(encryptedData);
        const decryptedData = decryptedResult.data;

        // Proceed with the recovery process
        await updateDoc(patientRef, {
          ...decryptedData,
          deleted: false,
        });

      } else {
      }
    } catch (error) {
      console.error("Error recovering patient:");
    }
  };

  function constructFullName(firstName, middleName, lastName) {
    return [firstName, middleName, lastName]
      .filter(Boolean) // Remove undefined or null values
      .map(capitalizeName) // Apply capitalization function
      .join(" "); // Join with a space
  }

  // Usage in JSX
  const fullName = constructFullName(
    patient?.firstName || patientData.subfirstName,
    patient?.middleName || patientData.submiddleName,
    patient?.lastName || patientData.sublastName
  );

  function calculateAge(dobString) {
    if (!dobString) return null;
    const dobDate = new Date(dobString);
    const today = new Date();
    let age = today.getFullYear() - dobDate.getFullYear();
    const m = today.getMonth() - dobDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < dobDate.getDate())) {
      age--;
    }
    return age;
  }
  

  return (
    <div className={styles.contentContainer}>
      {showPatientFilesEligibility && (
        <div>
          <PatientFilesEligibility
            patientId={patientSelected.id}
            firstName={firstName}
            middleName={middleName}
            lastName={lastName}
            dob={dob}
            gender={gender}
            memberid={memberid}
            tradingPartnerName={tradingPartnerName}
            onClose={() => setShowPatientFilesEligibility(false)}
          />
        </div>
      )}
      {showPatientFilesClaimStatus && (
        <div>
          <PatientFilesClaimStatus
            patientId={patientSelected.id}
            firstName={firstName}
            middleName={middleName}
            lastName={lastName}
            dob={dob}
            gender={gender}
            memberid={memberid}
            tradingPartnerName={tradingPartnerName}
            onClose={() => setShowPatientFilesClaimStatus(false)}
          />
        </div>
      )}
      {showPatientFilesClaims && (
        <div>
          <PatientFilesClaimsSend
            patientId={patientSelected.id}
            firstName={firstName}
            middleName={middleName}
            lastName={lastName}
            dob={dob}
            gender={gender}
            memberid={memberid}
            address1={address1}
            address2={address2}
            city={city}
            state={state}
            zip={zip}
            tradingPartnerName={tradingPartnerName}
            onClose={() => setShowPatientFilesClaims(false)}
          />
        </div>
      )}
      {showMessages && (
        <div className="overlay">
          <div className="modalBox">
            <Messages
              patientId={patientSelected.id}
              phoneNumber={phone}
              onClose={() => setShowMessages(false)}
            />
          </div>
        </div>
      )}

      {showZoom && (
        <div className="overlay">
          <div className="modalBox">
            <Zoom
              email={email}
              phone={phone}
              patientId={patientSelected.id}
              onClose={() => setShowZoom(false)}
            />
          </div>
        </div>
      )}
      {showNotes && (
        <div className="overlay">
          <div className="modalBox">
            <Notes
              patientId={patientSelected.id}
              patientData={patientData}
              onClose={() => setShowNotes(false)}
            />
          </div>
        </div>
      )}
      {showFiles && (
        <div className="overlay">
          <div className="modalBox">
            <Files
              patientId={patientSelected.id}
              onClose={() => setShowFiles(false)}
            />
          </div>
        </div>
      )}
      {showEncounters && (
        <Encounters
          patientId={patientSelected.id}
          patientData={patientData}
          onClose={() => setShowEncounters(false)}
        />
      )}

      <AddPatientPopup
        selectedFolder={selectedFolder}
        onDataUpdated={onDataUpdated}
        isBatchUploadOpen={isBatchUploadOpen}
        isAddPatientOpen={isAddPatientOpen}
        toggleAddPatientPopup={toggleAddPatientPopup}
        toggleBatchUploadPopup={toggleBatchUploadPopup}
      />

      <ClinicsPopup toggleClinicPopup={toggleClinicPopup} isAddClinicOpen={isAddClinicOpen}/>

      {showBatchClaimsModal && (
        <BatchClaims
          selectedPatientsData={selectedPatientsData}
          toggleBatchClaimPopup={toggleClaimsPopup}
          onClose={() => toggleClaimsPopup()}
        />
      )}


      <Modal
        isOpen={showConfirmModal}
        onRequestClose={cancelDelete}
        className={styles.confirmModal}
        overlayClassName={styles.confirmModalOverlay}
      >
        <h2>
          Delete "{firstName} {lastName}"?
        </h2>
        <p>Are you sure you want to delete the selected patient?</p>
        <div className={styles.confirmButtons}>
          <Button variant="secondary" onClick={cancelDelete}>
            Cancel
          </Button>
          <Button variant="danger" onClick={confirmDelete}>
            Delete
          </Button>
        </div>
      </Modal>
      <div className={styles.topBar}>
        {isDeleted ? (
          <span className={styles.editText} onClick={handleRecoverPatient}>
            Recover
          </span>
        ) : (
          <span className={styles.editText} onClick={handleShowEditPage}>
            Edit
          </span>
        )}
        <PatientFlag
          patientSelected={patientSelected}
          onDataUpdated={onDataUpdated}
        />
        <PatientDataDownload patientSelected={patientSelected} />
        <PatientFileTimer patientSelected={patientSelected} />
      </div>

      {isEditing && (
        <EditPatientPage
          patientId={patientSelected.id}
          firstName={firstName}
          middleName={middleName}
          lastName={lastName}
          dob={dob}
          gender={gender}
          memberid={memberid}
          address1={address1}
          address2={address2}
          city={city}
          state={state}
          zip={zip}
          tradingPartnerName={tradingPartnerName}
          patientData={editedPatientData || patientData}
          handleSave={handleSavePatientData}
          handleShowEditPage={handleShowEditPage}
          handleDelete={handleDelete}
          patientSelected={patientSelected}
          onClose={handleCloseEditPage}
          onDataUpdated={onDataUpdated}
        />
      )}

      <div className="patientDataContainer">
        <div className="titleBox">
          <h2 className="patientDataTitle">
            <span className="initialsCircleContainer">
              <span className="initialsCircle">
                {capitalizeName(firstName).charAt(0)}
                {capitalizeName(lastName).charAt(0)}
              </span>
              <span className={styles.name}>{fullName}</span>
            </span>
            <br />
            {dob && (
              <div className="dobandgender">
               <span style={{ fontSize: "24px", fontWeight: "lighter" }}>
                  {dob} {calculateAge(dob) ? `(${calculateAge(dob)})` : ''}
                </span>

                {gender && (
                  <span
                    style={{
                      marginLeft: "50px",
                      fontSize: "24px",
                      fontWeight: "lighter",
                    }}
                  >
                    {formatGender(gender)}
                  </span>
                )}
              </div>
            )}
          </h2>
        </div>
        <div className="actions">
          <div className="firstRow">
            <div className="action-button-container">

              <button onClick={handleEncounters}>
                <div className="icon-container">
                  <FontAwesomeIcon
                    icon={faStaffSnake}
                    size="lg"
                    style={{ height: "30px" }}
                  />
                </div>
                <div className="text-container">Encounter</div>
              </button>

              <>
                <InstantEligibility
                  patientId={patientSelected.id}
                  patientDataView={patientData}
                  uid={uid}
                />

                <button onClick={handleSendClaim}>
                  <div className="icon-container">
                    <FontAwesomeIcon
                      icon={faFileInvoiceDollar}
                      size="lg"
                      style={{ height: "30px" }}
                    />
                  </div>
                  <div className="text-container">Send Claim</div>
                </button>
              </>

            </div>

            <div className="secondRow">
              <div className="action-button-container">
              </div>
            </div>

            <div className="secondRow">
              <div className="action-button-container">
              <div className="action-button-container">
                <button onClick={() => setShowContactOptions(prev => !prev)}>
                  <div className="icon-container">
                    <FontAwesomeIcon
                      // Use whichever FontAwesome icon you prefer for "Contact"
                      icon={faPhone} 
                      size="lg"
                      style={{ height: "30px" }}
                    />
                  </div>
                  <div className="text-container">Contact</div>
                </button>
              </div>

                <button onClick={handleNotes}>
                  <div className="icon-container">
                    <FontAwesomeIcon
                      icon={faNotesMedical}
                      size="lg"
                      style={{ height: "30px" }}
                    />
                  </div>
                  <div className="text-container">Notes</div>
                </button>
                <button onClick={handleFiles}>
                  <div className="icon-container">
                    <FontAwesomeIcon
                      icon={faFileMedical}
                      size="lg"
                      style={{ height: "30px" }}
                    />
                  </div>
                  <div className="text-container">Files</div>
                </button>
              </div>
            </div>

            {showContactOptions && (
              <div className="thirdRow">
                <div className="action-button-container">
                  <button onClick={handlePhone}>
                    <div className="icon-container">
                      <FontAwesomeIcon icon={faSms} size="lg" style={{ height: "20px" }} />
                    </div>
                    <div className="text-container">Message</div>
                  </button>

                  <button onClick={handleZoom}>
                    <div className="icon-container">
                      <FontAwesomeIcon icon={faVideo} size="lg" style={{ height: "20px" }} />
                    </div>
                    <div className="text-container">Zoom</div>
                  </button>

                  <button onClick={handleEmail}>
                    <div className="icon-container">
                      <FontAwesomeIcon icon={faEnvelope} size="lg" style={{ height: "20px" }} />
                    </div>
                    <div className="text-container">Email</div>
                  </button>
                </div>
              </div>
            )}

          </div>
        </div>
        <div className={styles.flexContainer}>
          <div className={styles.patientInfocontainer}>

            <InsuranceStatus
              patientSelected={patientSelected}
              onDataUpdated={onDataUpdated}
              uid={uid}
            />

            <div className={styles.collapsibleContainer}>
              <div>
                {patientData.patient?.firstName && (
                  <p>
                    <span style={{ opacity: 0.75 }}>Name: </span>
                    <span className="opacity-100">
                      {capitalizeName(`${patientData.patient?.firstName} ${patientData.patient?.middleName ? patientData.patient?.middleName + ' ' : ''}${patientData.patient?.lastName}`)}
                    </span>
                  </p>
                )}

                {patientData.patient?.dob && (
                  <p>
                    <span style={{ opacity: 0.75 }}>Date of Birth: </span>
                    <span className="opacity-100">
                      {patientData.patient.dob} {calculateAge(patientData.patient.dob) ? `(${calculateAge(patientData.patient.dob)} years old)` : ''}
                    </span>
                  </p>
                )}

                {patientData.patient?.gender && (
                  <p>
                    <span style={{ opacity: 0.75 }}>Gender: </span>
                    <span className="opacity-100">
                      {formatGender(patientData.patient.gender)}
                    </span>
                  </p>
                )}
                {patientData.patient?.address?.address1 && (
                  <p>
                    <span style={{ opacity: 0.75 }}>Address: </span>
                    <span>
                      {patientData.patient.address.address1}
                      {patientData.patient.address.address2
                        ? `, ${patientData.patient.address.address2}`
                        : ""}
                      , {patientData.patient.address.city},{" "}
                      {patientData.patient.address.state}{" "}
                      {patientData.patient.address.zip}
                    </span>
                  </p>
                )}
                {patientData.patient?.email && (
                  <p>
                    <span style={{ opacity: 0.75 }}>Email: </span>
                    <span className="opacity-100">
                      {patientData.patient?.email}
                    </span>
                  </p>
                )}
                {patientData.patient?.phone && (
                  <p>
                    <span style={{ opacity: 0.75 }}>Phone: </span>
                    <span className="opacity-100">
                      {patientData.patient?.phone}
                    </span>
                  </p>
                )}

                {patientMRN && (
                  <p>
                    <span style={{ opacity: 0.75 }}>Medical Record Number: </span>
                    <span className="opacity-100">
                      {patientMRN}
                    </span>
                  </p>
                )}
                <ClinicNameFetcher patientId={patientSelected.id} clinicToken={clinicToken} />

                {/* Add Edit button at the bottom */}
                <div className={styles.editButtonContainer}>
                  <span
                    variant="primary"
                    className={styles.editButton}
                    onClick={handleShowEditPage}
                  >
                    Edit &gt;
                  </span>
                </div>
                <NotesView patientId={patientSelected.id} />
                <EncountersView patientId={patientSelected.id} />
                <MedicalHistory patientId={patientSelected.id} />
                <EligibilityCards patientId={patientSelected.id} />
                <ClaimsView patientId={patientSelected.id} />
                {/* <IntakeView patientId={patientSelected.id} clinicToken={clinicToken} intakeId={intakeId} /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PatientView;
