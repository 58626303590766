import React from 'react';
import AddPatient from './AddPatient';
import './AddPatient.modules.css';
import BatchUploadPopup from './BatchUploadPopup';

function AddPatientPopup({ selectedFolder, onDataUpdated, isBatchUploadOpen, isAddPatientOpen, toggleAddPatientPopup, toggleBatchUploadPopup }) {

  return (
    <>
      {isAddPatientOpen && (
        <div className="popupContainer">
          <div className="popupContentAddPatient">
            <div className="closeButtonContainer">
              <button onClick={toggleAddPatientPopup} className="filesCloseButton">
                X
              </button>
              <p className="closeBarNav">Add Patient</p>
            </div>

            <AddPatient
              selectedFolder={selectedFolder}
              onClose={toggleAddPatientPopup}
              onDataUpdated={onDataUpdated}
            />
          </div>
        </div>
      )}

      {isBatchUploadOpen && (
        <div className="popupContainer">
          <div className="popupContentAddPatient">
            <div className="closeButtonContainer">
              <button onClick={toggleBatchUploadPopup} className="filesCloseButton">
                X
              </button>
              <p className="closeBarNav">Batch Patient Upload</p>
            </div>
            <BatchUploadPopup
              selectedFolder={selectedFolder}
              onDataUpdated={onDataUpdated}
              onClose={toggleBatchUploadPopup}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default AddPatientPopup;

