import React, { useState } from "react";
import { getFunctions, httpsCallable } from "firebase/functions";
import ProviderSelection from "../../../../Providers/ProviderSelection";
import BillingCodesSelector from "../../../../Claims/Subcomponents/BillingCodesSelector";
import { FaTimes, FaPlus } from "react-icons/fa";
import useUID from "../../../../General/useUID";
const functions = getFunctions();
const batchCreateEncounters = httpsCallable(functions, "batchCreateEncounters");

const encounterTypes = [
  "Outpatient Visit",
  "Inpatient Admission",
  "Emergency Room Visit",
  "Telemedicine Visit",
  "Home Health Visit",
  "Ambulatory Care",
  "Day Surgery",
  "Observation",
  "Ancillary Services",
  "Follow-up Visit",
  "Preventative Visit",
  "Rehabilitation Visit",
  "Referral Visit",
  "Urgent Care Visit",
  "Post-operative Visit",
  "Nursing Home Visit",
  "Routine Check-up",
  "Maternity & Antenatal Visits",
];

const CreateEncounterModal = ({ selectedPatients, clinicToken, onClose }) => {
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [billingCode, setBillingCode] = useState([]);
  const [encounterType, setEncounterType] = useState(""); 
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [uid, subUserUID] = useUID();

  const handleCreateEncounters = async () => {
    setErrorMessage("");
  
    if (!selectedPatients.length) {
      setErrorMessage("Please select at least one patient.");
      return;
    }
  
    if (!selectedProvider) {
      setErrorMessage("Please select a provider.");
      return;
    }
  
    if (!billingCode || billingCode.length === 0) {
      setErrorMessage("Please select at least one billing code.");
      return;
    }
  
    if (!encounterType) {
      setErrorMessage("Please select an encounter type.");
      return;
    }
  
    setIsSubmitting(true);
  
    try {
      const encounterData = {
        uid: uid,
        provider: {  // Send full provider object
          providerId: selectedProvider.providerId,
          firstName: selectedProvider.firstName,
          lastName: selectedProvider.lastName,
          organizationName: selectedProvider.organizationName,
          npi: selectedProvider.npi,
          phone: selectedProvider.phone,
          posCode: selectedProvider.posCode,
          state: selectedProvider.state,
          zip: selectedProvider.zip,
          address1: selectedProvider.address1,
          address2: selectedProvider.address2,
          city: selectedProvider.city,
          ein: selectedProvider.ein,
        },
        billingCode,
        dateOfService: new Date().toISOString(),
        clinicId: clinicToken,
        selectedPatients, // Only send patient IDs
        encounterType,
        controlNumber: Math.floor(100000000 + Math.random() * 900000000).toString(),
      };
  
      console.log("🚀 Sending Encounter Data with Only Patient IDs:", JSON.stringify(encounterData, null, 2));
  
      const response = await batchCreateEncounters({ data: { encounterData } });
  
      if (response.data.success) {
        alert("Encounters created successfully!");
        onClose();
      } else {
        setErrorMessage("Error creating encounters: " + response.data.message);
      }
    } catch (error) {
      console.error("❌ Error creating encounters:", error);
      setErrorMessage("An error occurred while creating encounters.");
    } finally {
      setIsSubmitting(false);
    }
  };
  

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="modal-close" onClick={onClose}>
          <FaTimes />
        </button>
        <h2>Create Encounters for {selectedPatients.length} Patients</h2>

        <div className="form-group">
          <label>Select Provider:</label>
          <ProviderSelection onProviderSelect={setSelectedProvider} />
        </div>

        <div className="form-group">
          <label>Select Billing Code:</label>
          <BillingCodesSelector onBillingCodeSelect={setBillingCode} mode="encounters" />
        </div>

        <div className="form-group">
          <label>Select Encounter Type:</label>
          <select value={encounterType} onChange={(e) => setEncounterType(e.target.value)}>
            <option value="">Select an encounter type</option>
            {encounterTypes.map((type) => (
              <option key={type} value={type}>{type}</option>
            ))}
          </select>
        </div>

        {errorMessage && <p className="error-message">{errorMessage}</p>}

        <button 
          className="submit-button" 
          onClick={handleCreateEncounters} 
          disabled={isSubmitting}
        >
          <FaPlus /> {isSubmitting ? "Creating..." : "Create Encounters"}
        </button>
      </div>
    </div>
  );
};

export default CreateEncounterModal;
