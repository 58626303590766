import React, { useState, useEffect } from "react";
import {
  collection,
  doc,
  getDoc,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import useUID from "../../../General/useUID";
import { db } from "../../../../firebase";
import HexagonSpinner from "../../../General/Animations/Hexspinner";

const functions = getFunctions();
const decryptFunction = httpsCallable(functions, "decrypt");

function IntakeView({ patientId, clinicToken, intakeId }) {
  const [formData, setFormData] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [uid] = useUID();

  useEffect(() => {
    setFormData([]);
    if (!uid || !clinicToken) return;

    const fetchIntakeForms = async () => {
      setIsLoadingData(true);
      let docSnaps = [];

      try {
        if (intakeId) {
          const intakeDocRef = doc(
            db,
            "clinics",
            clinicToken,
            "intakeForms",
            intakeId
          );
          const intakeDocSnap = await getDoc(intakeDocRef);
          if (intakeDocSnap.exists()) {
            docSnaps = [intakeDocSnap];
          }
        }

        if (docSnaps.length === 0) {
          const intakeFormsRef = collection(
            db,
            "clinics",
            clinicToken,
            "intakeForms"
          );
          const intakeFormsQuery = query(
            intakeFormsRef,
            where("patientId", "==", patientId)
          );
          const querySnapshot = await getDocs(intakeFormsQuery);
          docSnaps = querySnapshot.docs;
        }

        if (docSnaps.length > 0) {
          const decryptedForms = await Promise.all(
            docSnaps.map(async (docSnap) => {
              const { ciphertext, iv } = docSnap.data() || {};
              if (!ciphertext || !iv) return null;
              try {
                const { data } = await decryptFunction({ ciphertext, iv });
                return data && data.formInputs ? data.formInputs : data?.data || [];
              } catch (decryptError) {
                return null;
              }
            })
          );
          const validForms = decryptedForms.filter((item) => item && item.length > 0);
          setFormData(validForms);
        } else {
          setFormData([]);
        }
      } catch (error) {
        setFormData([]);
      } finally {
        setIsLoadingData(false);
      }
    };

    fetchIntakeForms();
  }, [patientId, clinicToken, intakeId, uid]);

  return (
    <div className="encountersContainer">
      {formData.length > 0 && (
        <>
          <h3>Intake Form Results</h3>
          <div
            style={{
              height: "1px",
              backgroundColor: "black",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          ></div>
        </>
      )}

      {isLoadingData ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <HexagonSpinner />
        </div>
      ) : (
        formData.map((oneForm, formIndex) => (
          <div key={formIndex}>
            {oneForm.map((input) => (
              <div key={input.id}>
                {input.fields.map((item, itemIndex) => (
                  <React.Fragment key={itemIndex}>
                    {item.isVisible && (
                      <div>
                        <p>
                          <span style={{ opacity: 0.75 }}>{item.label}:</span>{" "}
                          <span>{item.value}</span>
                        </p>
                      </div>
                    )}
                  </React.Fragment>
                ))}
              </div>
            ))}
          </div>
        ))
      )}
    </div>
  );
}

export default IntakeView;
