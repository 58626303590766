import React, { useState } from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBoltLightning } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-modal';
import QuicksearchData from '../../Eligibility/EligibilityResultsViewData';
import ProviderSelection from '../../Providers/ProviderSelection';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../../firebase';
import HexagonSpinner from '../../General/Animations/Hexspinner';
import '../PatientView.module.css';

function InstantEligibility({ patientDataView, patientId, uid }) {
  const [loading, setLoading] = useState(false);
  const [loadingModalOpen, setLoadingModalOpen] = useState(false);
  const [defaultProviderStatus, setDefaultProviderStatus] = useState('Fetching default provider...');
  const [defaultProvider, setDefaultProvider] = useState(null);
  const [selectingProvider, setSelectingProvider] = useState(false);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [patientData, setPatientData] = useState(null);
  const [dos, setDos] = useState(() => {
    const today = new Date();
    return today.toISOString().split('T')[0];
  });
  const functions = getFunctions();

  /**
   * Validates and normalizes gender input.
   * Accepts "Male", "male", "M", "m" as male and "Female", "female", "F", "f" as female.
   * Returns "M" for male and "F" for female.
   * @param {string} gender - The gender input.
   * @returns {string} - "M" or "F"
   * @throws {Error} - If the gender value is missing or unrecognized.
   */
  const validateGender = (gender) => {
    if (!gender) {
      throw new Error("Gender is required.");
    }
    const normalized = gender.trim().toLowerCase();
    if (normalized === "male" || normalized === "m") {
      return "M";
    }
    if (normalized === "female" || normalized === "f") {
      return "F";
    }
    throw new Error(
      `Invalid or unrecognized gender value: "${gender}". Please ensure the patient's gender is specified as either "Male"/"M" or "Female"/"F".`
    );
  };

  // Fetch the default provider
  const fetchDefaultProvider = async () => {
    setLoadingModalOpen(true);
    try {
      const providersRef = collection(db, `users/${uid}/providers`);
      const defaultProviderQuery = query(providersRef, where("isDefault", "==", true));
      const querySnapshot = await getDocs(defaultProviderQuery);

      if (querySnapshot.empty) {
        setDefaultProviderStatus("No default provider found. Please select one.");
        setSelectingProvider(true);
        return null;
      }

      const defaultProviderDoc = querySnapshot.docs[0];
      const encryptedData = defaultProviderDoc.data();

      if (!encryptedData.ciphertext || !encryptedData.iv) {
        throw new Error("Provider has no encrypted data fields.");
      }

      const decrypt = httpsCallable(functions, "decrypt");
      const decryptedResponse = await decrypt({
        ciphertext: encryptedData.ciphertext,
        iv: encryptedData.iv,
      });

      if (!decryptedResponse.data) {
        throw new Error("Decryption returned no data.");
      }

      const decryptedProvider = {
        id: defaultProviderDoc.id,
        ...decryptedResponse.data,
        isDefault: encryptedData.isDefault || false,
      };

      setDefaultProviderStatus(
        `Default provider: ${decryptedProvider.organizationName || "Unknown Organization"}`
      );

      return decryptedProvider;
    } catch (error) {
      console.error("Error fetching default provider:", error);
      setDefaultProviderStatus("Error fetching default provider.");
      throw error;
    } finally {
      setLoadingModalOpen(false);
    }
  };

  const handleRunEligibility = async () => {
    setLoading(true);
    setError(null);

    try {
      const { patient, payers } = patientDataView;
      if (!patient || !payers) {
        throw new Error('Patient or payer data is missing.');
      }

      const { firstName, middleName, lastName, dob, gender } = patient;
      if (!patientId) {
        throw new Error('Patient ID is missing.');
      }

      const {
        memberId,
        name: payerName,
        EligibilityPayerID,
        RealtimePayerID,
        TradingPartnerID,
        CPID,
        secondaryPayer,
      } = payers;

      console.log('Primary Payer:', payers);
      if (secondaryPayer) {
        console.log('Secondary Payer:', secondaryPayer);
      } else {
        console.warn('No secondary payer found.');
      }

      const tradingPartnerId =
        (EligibilityPayerID && EligibilityPayerID !== 'Not Available')
          ? EligibilityPayerID
          : (RealtimePayerID && RealtimePayerID !== 'Not Available')
            ? RealtimePayerID
            : (TradingPartnerID && TradingPartnerID !== '')
              ? TradingPartnerID
              : null;

      if (!tradingPartnerId) {
        console.error(
          `Payer "${payerName}" could not be matched for electronic eligibility. Please check the payer details.`
        );
        if (!CPID) {
          throw new Error(
            `Payer "${payerName}" does not support electronic eligibility verification. Please call them to verify eligibility.`
          );
        } else {
          throw new Error(
            `Payer "${payerName}" could not be matched for electronic eligibility. Please check the payer details.`
          );
        }
      }

      console.log('Selected tradingPartnerId:', tradingPartnerId);

      const formattedDob = dob;
      const [year, month, day] = dos.split('-');
      const formattedDos = `${month}/${day}/${year}`;

      // Validate and normalize gender (accepts both formats)
      const normalizedGender = validateGender(gender);

      const provider = defaultProvider || (await fetchDefaultProvider());
      if (!provider) {
        throw new Error('No default provider selected.');
      }

      const eligibilitySingleAPI = httpsCallable(functions, 'eligibilitySingleAPI');
      const apiPayload = {
        uid,
        patientId,
        firstName,
        middleName,
        lastName,
        dob: formattedDob,
        dos: formattedDos,
        gender: normalizedGender, // "M" or "F"
        memberId,
        payers: [{ tradingPartnerId, name: payerName }],
        organizationName: provider.organizationName,
        npi: provider.npi,
      };

      console.log('Payload being sent to API:', apiPayload);

      const apiResult = await eligibilitySingleAPI(apiPayload);
      handleAPIResult(apiResult);

    } catch (error) {
      console.error('Error in handleRunEligibility:', error);
      setError(error.message);
      setIsModalOpen(true);
    } finally {
      setLoading(false);
    }
  };

  // Handle provider selection
  const handleProviderSelect = (provider) => {
    if (provider) {
      setDefaultProvider(provider);
      setSelectingProvider(false);
      setLoadingModalOpen(false);
    }
  };

  const handleAPIResult = (result) => {
    if (result?.data?.success && result?.data?.responses?.[0]?.success) {
      setPatientData(result.data.responses[0]);
    } else {
      processEligibilityErrors(result);
    }
  };

  const processEligibilityErrors = (result) => {
    const errorMessages =
      result?.data?.responses?.[0]?.errors?.map(
        (err) => `${err.description} - ${err.possibleResolutions}`
      ) || ['Eligibility check failed (unknown error).'];

    setError(errorMessages.join('\n'));
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setError(null);
  };

  return (
    <>
      <button
        onClick={handleRunEligibility}
        className="patientViewEligibilityButton mb-2"
        data-tooltip={
          loading
            ? 'Loading, please wait...'
            : (
                !patientDataView?.payers?.EligibilityPayerID &&
                !patientDataView?.payers?.RealtimePayerID
              )
              ? 'You need either EligibilityPayerID or RealtimePayerID to run eligibility.'
              : 'Run Eligibility on this claim'
        }
        disabled={
          loading ||
          (
            !patientDataView?.payers?.EligibilityPayerID &&
            !patientDataView?.payers?.RealtimePayerID
          )
        }
      >
        <div className="icon-container">
          <FontAwesomeIcon icon={faBoltLightning} size="lg" style={{ height: '30px' }} />
        </div>
        {loading ? 'Checking...' : 'Instant Eligibility'}
      </button>

      <Modal
        isOpen={loadingModalOpen || selectingProvider}
        onRequestClose={() => setSelectingProvider(false)}
        className="confirmModal"
        overlayClassName="modalOverlay"
      >
        <div className="loadingContainer">
          <HexagonSpinner />
          <h3>Loading...</h3>
          <p>{defaultProviderStatus}</p>
          <div>
            <h4>Select a Default Provider by clicking the heart icon. Then rerun instant eligibility.</h4>
            <ProviderSelection onProviderSelect={handleProviderSelect} uid={uid} type="unified" />
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        className="confirmModal"
        overlayClassName="modal-overlay"
      >
        <button className="filesCloseButton" onClick={closeModal}>X</button>
        <h2>Error</h2>
        <div>{error || 'An unexpected error occurred'}</div>
      </Modal>

      {patientData && (
        <div className="patientDataPopupContainer">
          <QuicksearchData patientData={patientData} onClose={() => setPatientData(null)} />
        </div>
      )}
    </>
  );
}

export default InstantEligibility;
