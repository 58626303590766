import React, { useState, useEffect } from 'react';
import { db, auth } from '../../../firebase';
import { collection, addDoc, onSnapshot, updateDoc, doc, deleteDoc, query, where, getDocs } from 'firebase/firestore';
import '../MainStyles.css'
import useUID from '../useUID'

function Callbacks() {
    const [name, setName] = useState('');
    const [authKey, setAuthKey] = useState('');
    const [url, setUrl] = useState('');
    const [eventType, setEventType] = useState('Encounters');
    const [messageMappings, setMessageMappings] = useState('');
    const [payerMappings, setPayerMappings] = useState('');
    const [apiSpecs, setApiSpecs] = useState([]);
    const [editing, setEditing] = useState(null);
    const [error, setError] = useState('');
    const [uid, subUserUID] = useUID();

    const apiSpecsRef = collection(db, 'APISpecs');

    useEffect(() => {
        const unsubscribe = onSnapshot(apiSpecsRef, (querySnapshot) => {
            const specs = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setApiSpecs(specs);
        });

        return () => unsubscribe();
    }, []);

    const addApiSpec = async () => {

        await addDoc(apiSpecsRef, {
            Name: name,
            Auth: authKey,
            Event: eventType,
            URL: url,
            UserID: uid,
            messageMappings: messageMappings ? JSON.parse(messageMappings) : {},
            payerMappings: payerMappings ? JSON.parse(payerMappings) : {}
        });
        setName('');
        setAuthKey('');
        setUrl('');
        setEventType('Encounters');
        setMessageMappings('');
        setPayerMappings('');
        setError('');
    };

    const updateApiSpec = async () => {
        const specDoc = doc(db, 'APISpecs', editing.id);
        await updateDoc(specDoc, {
            Name: editing.Name,
            Auth: editing.Auth,
            Event: editing.Event,
            URL: editing.URL,
            messageMappings: editing.messageMappings,
            payerMappings: editing.payerMappings
        });
        setEditing(null);
    };

    const deleteApiSpec = async (id) => {
        const specDoc = doc(db, 'APISpecs', id);
        await deleteDoc(specDoc);
    };

    return (
        <div className='APIsidebarContent'>
            <h3>Callbacks</h3>
            {error && <p style={{ color: 'red' }}>{error}</p>}
            <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Name"
            />
            <input
                type="text"
                value={authKey}
                onChange={(e) => setAuthKey(e.target.value)}
                placeholder="Auth Key"
            />
            <select value={eventType} onChange={(e) => setEventType(e.target.value)}>
                <option value="Encounters">Encounters</option>
                {/* Additional event types can be added here */}
            </select>
            <input
                type="text"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
                placeholder="URL"
            />
            <textarea
                value={messageMappings}
                onChange={(e) => setMessageMappings(e.target.value)}
                placeholder="Message Mappings JSON"
                className='textAreaAPI'
            />
            <textarea
                value={payerMappings}
                onChange={(e) => setPayerMappings(e.target.value)}
                placeholder="Payer Mappings JSON"
                className='textAreaAPI'

            />
            <button onClick={addApiSpec}>Add Callback</button>
            <table>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Auth Key</th>
                        <th>Event</th>
                        <th>URL</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {apiSpecs.map((spec) => (
                        <tr key={spec.id}>
                            {editing && editing.id === spec.id ? (
                                <>
                                    <td>
                                        <input
                                            type="text"
                                            value={editing.Name}
                                            onChange={(e) => setEditing({ ...editing, Name: e.target.value })}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            value={editing.Auth}
                                            onChange={(e) => setEditing({ ...editing, Auth: e.target.value })}
                                        />
                                    </td>
                                    <td>{editing.Event}</td>
                                    <td>
                                        <input
                                            type="text"
                                            value={editing.URL}
                                            onChange={(e) => setEditing({ ...editing, URL: e.target.value })}
                                        />
                                    </td>
                                    <td>
                                        <textarea
                                            value={JSON.stringify(editing.messageMappings, null, 2)}
                                            onChange={(e) => setEditing({ ...editing, messageMappings: JSON.parse(e.target.value) })}
                                        />
                                    </td>
                                    <td>
                                        <textarea
                                            value={JSON.stringify(editing.payerMappings, null, 2)}
                                            onChange={(e) => setEditing({ ...editing, payerMappings: JSON.parse(e.target.value) })}
                                        />
                                    </td>
                                    <td>
                                        <button onClick={updateApiSpec}>Save</button>
                                    </td>
                                </>
                            ) : (
                                <>
                                    <td>{spec.Name}</td>
                                    <td>Edit to view.</td>
                                    <td>{spec.Event}</td>
                                    <td>{spec.URL}</td>
                                    <td>
                                        <button onClick={() => setEditing(spec)}>Edit</button>
                                        <button onClick={() => deleteApiSpec(spec.id)}>Delete</button>
                                    </td>
                                </>
                            )}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default Callbacks;
