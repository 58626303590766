import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { collection, query, where, getDocs, doc, getDoc } from "firebase/firestore";
import { db } from "../../../../firebase";
import { getFunctions, httpsCallable } from "firebase/functions";
import Topbar from "../../../General/Topbar";
import HexagonSpinner from "../../../General/Animations/Hexspinner";
import useUID from "../../../General/useUID";

function IntakeViewPatient() {
  const { clinicId } = useParams();
  const { state: locationState } = useLocation();
  const navigate = useNavigate();
  const [patientData, setPatientData] = useState(null);
  const [formData, setFormData] = useState([]);
  const [appointments, setAppointments] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [uid] = useUID();

  const { patientId, intakeFormId } = locationState || {};

  const functions = getFunctions();
  const decryptFunction = httpsCallable(functions, "decrypt");

  useEffect(() => {
    const fetchPatientData = async () => {
      try {
        if (!uid || !patientId) {
          return;
        }

        const patientDoc = await getDoc(doc(db, `patients/${uid}/patientData/${patientId}`));
        if (patientDoc.exists()) {
          const { patient } = patientDoc.data();
          const { ciphertext, iv } = patient;

          if (ciphertext && iv) {
            const decryptedResponse = await decryptFunction({ ciphertext, iv });
            setPatientData(decryptedResponse.data || {});
          }
        } else {
          console.error("Patient not found");
        }
      } catch (error) {
        console.error("Error fetching patient data:", error);
      }
    };

    const fetchIntakeForms = async () => {
      try {
        const intakeFormsRef = collection(db, "clinics", clinicId, "intakeForms");
        let intakeFormsSnapshot;

        if (intakeFormId) {
          const intakeFormQuery = query(intakeFormsRef, where("__name__", "==", intakeFormId));
          intakeFormsSnapshot = await getDocs(intakeFormQuery);
        }

        if (!intakeFormsSnapshot || intakeFormsSnapshot.empty) {
          const patientQuery = query(intakeFormsRef, where("patientId", "==", patientId));
          intakeFormsSnapshot = await getDocs(patientQuery);
        }

        if (!intakeFormsSnapshot.empty) {
          const intakeFormsData = await Promise.all(
            intakeFormsSnapshot.docs.map(async (docSnap) => {
              const { ciphertext, iv } = docSnap.data();
              if (ciphertext && iv) {
                const decryptedResponse = await decryptFunction({ ciphertext, iv });
                return decryptedResponse.data.data || [];
              }
              return [];
            })
          );
          setFormData(intakeFormsData.filter((data) => data.length > 0));
        } else {
          setFormData([]);
        }
      } catch (error) {
        console.error("Error fetching intake forms:", error);
        setFormData([]);
      }
    };

    const fetchAppointments = async () => {
      try {
        const appointmentsRef = collection(db, "clinics", clinicId, "appointments");
        const appointmentsQuery = query(
          appointmentsRef,
          where("patientId", "==", patientId)
        );
        const appointmentsSnapshot = await getDocs(appointmentsQuery);

        if (!appointmentsSnapshot.empty) {
          const appointmentsData = appointmentsSnapshot.docs.map((docSnap) => {
            const data = docSnap.data();
            return {
              id: docSnap.id,
              ...data,
            };
          });
          setAppointments(appointmentsData);
        } else {
          setAppointments([]);
        }
      } catch (error) {
        console.error("Error fetching appointments:", error);
      }
    };

    setIsLoadingData(true);
    Promise.all([fetchPatientData(), fetchIntakeForms(), fetchAppointments()]).then(() => {
      setIsLoadingData(false);
    });
  }, [uid, clinicId, patientId, intakeFormId]);

  if (isLoadingData) {
    return (
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
        <HexagonSpinner />
      </div>
    );
  }

  const { firstName, middleName, lastName, dob, gender, address, payers } = patientData?.patient || {};
  const { address1, address2, city, state, zip } = address || {};
  const { name: payerName, memberId } = payers || {};

  return (
    <>
      <Topbar />
      <div className="patientDetailsContainer">
        <div className="closeButtonContainer">
          <button onClick={() => navigate(-1)} className="filesCloseButton">
            X
          </button>
          <p className="closeBarNav">Patient Details</p>
        </div>
  
        <div className="patientDetails">
          <h2>{`${firstName || "N/A"} ${middleName || ""} ${lastName || "N/A"}`.trim()}</h2>
          <p>
            <strong>Date of Birth:</strong> {dob || "N/A"}
          </p>
          <p>
            <strong>Gender:</strong> {gender || "N/A"}
          </p>
  
          <h3>Address</h3>
          <p>{address1 || "N/A"}</p>
          <p>{address2 || ""}</p>
          <p>{`${city || "N/A"}, ${state || "N/A"} ${zip || "N/A"}`}</p>
  
          <h3>Payer Information</h3>
          <p>
            <strong>Payer Name:</strong> {payerName || "N/A"}
          </p>
          <p>
            <strong>Member ID:</strong> {memberId || "N/A"}
          </p>
  
          {appointments.length > 0 && (
            <>
              <h3>Appointments</h3>
              {appointments.map((appointment, index) => (
                <div key={index}>
                  <p>
                    <strong>Type:</strong> {appointment.appointmentType || "N/A"}
                  </p>
                  <p>
                    <strong>Date:</strong> {appointment.date || "N/A"}
                  </p>
                  <p>
                    <strong>Time:</strong> {appointment.time || "N/A"}
                  </p>
                  <p>
                    <strong>Status:</strong> {appointment.status || "N/A"}
                  </p>
                </div>
              ))}
            </>
          )}
  
          {formData.length > 0 && (
            <>
              <h3>Intake Form Results</h3>
              <div className="intakeDivider" />
              {formData.map((form, formIndex) => (
                <div key={formIndex}>
                  {form.map((input) => (
                    <div key={input.id}>
                      {input.fields.map((item, itemIndex) => (
                        <React.Fragment key={itemIndex}>
                          {item.isVisible && (
                            <div>
                              {["text", "phone", "email", "date", "time", "choice", "textarea"].includes(
                                item.type
                              ) && (
                                <p>
                                  <span className="intakeLabel">{item.label}:</span>{" "}
                                  <span>{item.value}</span>
                                </p>
                              )}
                              {item.type === "signature" && (
                                <div>
                                  <p className="intakeLabel">{item.label}:</p>
                                  <img src={item.value} alt="signature" />
                                </div>
                              )}
                            </div>
                          )}
                        </React.Fragment>
                      ))}
                    </div>
                  ))}
                </div>
              ))}
            </>
          )}
        </div>
      </div>
    </>
  );
  
}

export default IntakeViewPatient;
