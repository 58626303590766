import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileMedical, faUserCheck, faFileAlt } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

function PricingTable({ showPaymentForm }) {
  const plans = [
    {
      name: 'Freebie',
      description: "Popularis is Latin for 'for the people' — get unrestricted access.",
      buttonText: 'Get Started',
      price: 'Free',
      features: [
        '25 free claims or eligibility requests',
        'Up to 500 patients, securely stored for the legally required 7 years',
        'Add up to 2 providers',
        'All patient management tools included',
        'Custom-branded intake forms',
        'Unlimited Additional Users'
      ]
    },
    {
      name: 'Professional',
      description: 'Streamline your operations with eligibility and start getting paid through claims.',
      buttonText: 'Get Started',
      price: '$199/month',
      features: [
        '$0.40 per claim or eligibility request',
        'Unlimited patients',
        'Unlimited users',
        'Batch eligibility checks',
        'Batch billing claims',
        '$100 per additional provider',
        'Business hours customer support'
      ]
    },
    {
      name: 'Enterprise',
      description: 'Ideal for growing practices or those with multiple locations.',
      buttonText: 'Get Started',
      price: '$699/month',
      features: [
        '$0.35 per claim or eligibility request',
        'Unlimited providers and users',
        '$75 per additional provider',
        'Access to standard and custom APIs',
        'One free API integration (custom quotes for additional)',
        'Dedicated US-based support'
      ]
    },
  ];
  
  

  return (
    <div className="pricing-table">
      <div className="plan-container">
        {plans.map((plan, index) => (
          <div className="plan-card" key={index}>
            <h2>{plan.name}</h2>
            <div className="plan-description">
              <p>{plan.description}</p>
            </div>            <ul>
              {plan.features.map((feature, i) => (
                <li key={i}>{feature}</li>
              ))}
            </ul>
            <div className='price-container'>
              <span className="price">{plan.price.split('/')[0]}</span>
              {plan.price.split('/')[1] && (
                <span className="month">/{plan.price.split('/')[1]}</span>
              )}
            </div>
            <Link to="/signup">
              <button>{plan.buttonText}</button>
            </Link>
          </div>
        ))}
      </div> 
    </div>
  );
}

export default PricingTable;
