import React, { useEffect } from "react";
import InputSection from "./InputSection";
import InputTypeText from "./SubInputTypes/InputTypeText";
import InputTypeTextArea from "./SubInputTypes/InputTypeTextArea";
import InputTypeHeader from "./SubInputTypes/InputTypeHeader";
import InputTypeParagraph from "./SubInputTypes/InputTypeParagraph";
import InputTypeSelectState from "./SubInputTypes/InputTypeSelectState";
import InputTypeChoice from "./SubInputTypes/InputTypeChoice";
import InputTypeSymptomsList from "./SubInputTypes/InputTypeSymptomsList";
import InputTypeSignature from "./SubInputTypes/InputTypeSignature";
import InputTypeLine from "./SubInputTypes/InputTypeLine";
import InputTypeSpacer from "./SubInputTypes/InputTypeSpacer";
import GeneralSwitch from "./GeneralSwitch";

const FormElementTypes = ({ handleChange, item, formElementSubTypes }) => {
  // Init the defualt vales/properties
  useEffect(() => {
    Object.entries(formElementSubTypes).forEach(([key, fieldElement]) => {
      Object.entries(fieldElement.properties).forEach(
        ([property, defaultValue]) => {
          handleChange(
            { target: { value: defaultValue } },
            fieldElement.index,
            property
          );
        }
      );
    });
  }, []);

  return (
    <div className="MainContainer">
      <div className={`Preview ${item.expandableAreaOpen ? "border-buttom" : "pb-5"}`}>
        <label className="preview-text">Preview</label>
        {formElementSubTypes.map((fieldDef) => (
          <React.Fragment key={fieldDef.index}>
            {item.fields?.[fieldDef.index]?.isVisible && (
              <div className="input-group-row">
                <div className="input-field w-100 mb-0" key={fieldDef.index}>
                  {(() => {
                    const components = {
                      textarea: InputTypeTextArea,
                      state: InputTypeSelectState,
                      space: InputTypeTextArea,
                      header: InputTypeHeader,
                      paragraph: InputTypeParagraph,
                      choice: InputTypeChoice,
                      symptomslist: InputTypeSymptomsList,
                      signature: InputTypeSignature,
                      line: InputTypeLine,
                      Spacer: InputTypeSpacer,
                    };
                    const Component = components[fieldDef.properties.type] || InputTypeText;
                    return (
                      <Component
                        fieldItem={item.fields?.[fieldDef.index]}
                        isVisible={item.fields?.[fieldDef.index]?.isVisible}
                      />
                    );
                  })()}
                </div>
              </div>
            )}
          </React.Fragment>
        ))}
      </div>
      <div
        className={`ExpandableArea ${item.expandableAreaOpen ? "open" : ""}`}
      >
        <div className="FormElementsContainer">
          {formElementSubTypes.map((fieldDef) => (
            <InputSection
              key={fieldDef.index}
              item={item}
              fieldIndex={fieldDef.index}
              fieldDef={fieldDef}
              handleChange={handleChange}
            />
          ))}
          {formElementSubTypes.map((fieldDef) => (
            <>
              {fieldDef.properties.showIsVisibleSwitch && (
                <GeneralSwitch
                  label={`Show ${fieldDef.properties.title}:`}
                  itemFields={item.fields}
                  fieldIndex={fieldDef.index}
                  handleChange={handleChange}
                  property="isVisible"
                />
              )}
            </>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FormElementTypes;