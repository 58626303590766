import React from "react";
import { Draggable } from "react-beautiful-dnd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icons from "@fortawesome/free-solid-svg-icons";

const PaletteCard = ({ typeData }) => {
  const icon = Icons[typeData.icon];

  return (
    <Draggable draggableId={typeData.type} index={0}>
      {(provided) => (
        <div
          className="PaletteFormElementCard"
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          <div className="icons">
            {icon && <FontAwesomeIcon icon={icon} />}
          </div>
          <div>{typeData.title}</div>
          <div className="iconContainer">
            {/* Example of a static icon; you can also make this dynamic if needed */}
            <FontAwesomeIcon icon={Icons.faGripVertical} />
          </div>
        </div>
      )}
    </Draggable>
  );
};

export default PaletteCard;
