import { useState, useEffect, useRef } from "react";
import { storage, auth, db } from "../../../firebase";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
  list,
  listAll,
} from "firebase/storage";
import {
  getFunctions,
  httpsCallable,
} from "firebase/functions";
import PayerSelection from "../../Payers/PayerSelection";
import * as XLSX from "xlsx";
import React from "react";
import CollapsibleSection from "../Subcomponents/CollapsibleSection";
import {
  doc,
  collection,
  query,
  orderBy,
  onSnapshot,
  getDoc,
  getDocs,
} from "firebase/firestore";
import styles from "../Claims.css";
import ServiceLineTable from "../Subcomponents/ServiceLineTable";
import HexSpinner from "../../General/Animations/Hexspinner";
import Confetti from "../../General/Animations/Confetti";
import ProviderSelection from "../../Providers/ProviderSelection";
import PayerMatchingModal from "../Configuration/PayerMatching";
import { ProviderProvider } from "../../../context/ProviderContext";
import useUID from "../../General/useUID";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudArrowUp } from "@fortawesome/free-solid-svg-icons";
import HelpArticleLink from "../../Articles/HelpArticleLink";
import useEmailVerified from "../../General/useEmailVerified";
import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import Modal from "react-modal";
import SearchBox from "../Subcomponents/ICD10";

function BatchClaims({ onClose }) {
  const [progresspercent, setProgresspercent] = useState(0);
  const [files, setFiles] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const [selectedPayers, setSelectedPayers] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [payerError, setPayerError] = useState("");
  const [imgUrl, setImgUrl] = useState(null);
  const [activeSection, setActiveSection] = useState(1);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [confirmationMessage, setConfirmationMessage] = useState(null);
  const [showSubmitButton, setShowSubmitButton] = useState(true);
  const [fileErrors, setFileErrors] = useState([]);
  const [errorTable, setErrorTable] = useState([]);
  const fileInputRef = useRef(null);
  const [AccountTier, setAccountTier] = useState("");
  const [hasRemainingRequests, setHasRemainingRequests] = useState(true);
  const [payers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [totalCharges, setTotalCharges] = useState(0);
  const [selectedBillingCodes, setSelectedBillingCodes] = useState([]);
  const [selectedServiceProviders, setSelectedServiceProviders] = useState([]);
  const [selectedRenderingProviders, setSelectedRenderingProviders] = useState(
    []
  );
  const [selectedBillingProviders, setSelectedBillingProviders] = useState([]);
  const [selectedReferringProviders, setSelectedReferringProviders] = useState([]);
  const [showReferringProviderButton, setShowReferringProviderButton] = useState(true);
  const [DatesOfService, setDatesOfService] = useState("");
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [successMessages, setSuccessMessages] = useState([]);
  const [successCount, setSuccessCount] = useState(0);
  const [fileName, setFileName] = useState("");
  const [failedPatients, setFailedPatients] = useState([]);
  const [isPayerModalOpen, setIsPayerModalOpen] = useState(false);
  const [isDiagnosisModalOpen, setIsDiagnosisModalOpen] = useState(false);
  const [showPayerSelection, setShowPayerSelection] = useState(true);
  const [hasDiagnosisCodes, setHasDiagnosisCodes] = useState(null);
  const [errors2, setErrors2] = useState({});
  const [rowCount, setRowCount] = useState(0);
  const [hasPayerInExcel, setHasPayerInExcel] = useState(false);
  const [totalApiRequests, setTotalApiRequests] = useState(0);
  const [uid, subUserUID] = useUID();
  const isEmailVerified = useEmailVerified();
  const [currentStep, setCurrentStep] = useState(0);
  const [differentPayers, setDifferentPayers] = useState(null);
  const [isTestMode, setIsTestMode] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [hasDifferentPayersAnswered, setHasDifferentPayersAnswered] =
    useState(null);
  const [
    hasDifferentDiagnosisCodesAnswered,
    setHasDifferentDiagnosisCodesAnswered,
  ] = useState(null);

  const myRef = useRef(null);

  useEffect(() => {
    if (!uid) return;

    const fetchAccountTierAndCheckRemainingRequests = async () => {
      const userDocRef = doc(db, "users", uid);
      const userDocSnap = await getDoc(userDocRef);

      const AccountTier = userDocSnap.data().AccountTier || "";
      setAccountTier(AccountTier);

      const requestsRef = collection(db, "users", uid, "API");
      const q = query(requestsRef, orderBy("timestamp", "desc"));

      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        let total = 0;
        querySnapshot.forEach((doc) => {
          const requestData = doc.data();

          if (requestData.APIRequests) {
            const apiRequestsCount = parseInt(requestData.APIRequests, 10);
            total += apiRequestsCount;
          }
        });

        setTotalApiRequests(total); // Set the state variable for totalApiRequests

        if (total >= 25 && AccountTier === "Freebie") {
          setHasRemainingRequests(false);
        } else {
          setHasRemainingRequests(true);
        }
      });

      return () => unsubscribe();
    };

    if (uid) {
      fetchAccountTierAndCheckRemainingRequests();
    }
  }, [uid]);



  const handleSubmit = async (e) => {
    e.preventDefault();
  
    setErrorMessage(null);
    setPayerError("");
    setFailedPatients([]);
    setErrorTable([]);
    setSuccessMessage(null);
    setSuccessMessages([]);
    setSuccessCount(0);
    setShowSuccessMessage(false);
  
    if (!isEmailVerified) {
      alert("Please verify your email before submitting claims.");
      return;
    }
  
    const { data, rowCount } = await readExcelData(uploadedFile);
    setRowCount(rowCount);
  
    // Check if the number of claims exceeds 150
    if (rowCount > 150) {
      const totalSeconds = rowCount * 1.8;
      const minutes = Math.floor(totalSeconds / 60);
      const seconds = Math.round(totalSeconds % 60);
  
      const message = `It is estimated to take ${minutes} minutes and ${seconds} seconds to process your claims. You will get an email when complete. Thank you.`;
      setErrorMessage(message);
    }
  
    setIsLoading(true);
    setShowSubmitButton(false);
    
    const patientBatch = await processPatientData(data);
    
    const claimData = {
      patientBatch,
      uid,
      selectedServiceProviders,
      selectedRenderingProviders,
      selectedBillingProviders,
      selectedReferringProviders,
      usageIndicator: isTestMode ? 'T' : 'P',  // 'P' for production, 'T' for test
    };
  
    const totalRequests = rowCount;
    const isFreeTrial = AccountTier === "Freebie";
    const reachedFreeTrialLimit =
      totalApiRequests + totalRequests > 25 && isFreeTrial;
  
    if (reachedFreeTrialLimit) {
      setErrorMessage(
        <span>
          You can only make up to 25 API requests in the free trial. You have
          made {totalApiRequests} requests, and you are trying to make{" "}
          {totalRequests} more. Please{" "}
          <a
            href="https://popularishealth.com/payment"
            target="_blank"
            rel="noopener noreferrer"
          >
            upgrade your account
          </a>{" "}
          to continue.
        </span>
      );
      setIsLoading(false);
      setShowSubmitButton(true);
      return;
    }
  
    if (!uploadedFile) {
      setErrorMessage("Please upload a file before submitting.");
      setIsLoading(false);
      setShowSubmitButton(true);
      return;
    }
  
    const functions = getFunctions();
    const batchclaims = httpsCallable(functions, "batchClaimsJson");
    
    batchclaims({ claimData })
      .then((result) => {
        if (result.data.errors && result.data.errorMessage) {
          setErrorMessage(result.data.errorMessage);
          setFailedPatients(result.data.errors);
          setShowSubmitButton(true);
  
          failedPatients.forEach((failedPatient) => {
            console.log("Error:", failedPatient.error);
          });
        }
  
        if (result.data.successCount && result.data.successCount > 0) {
          setSuccessMessages(result.data.successMessages);
          setSuccessCount(result.data.successCount);
        }
  
        setShowSuccessMessage(true);
        setIsLoading(false);
        setShowSubmitButton(true);
      })
      .catch((error) => {
        setIsLoading(false);
        setShowSubmitButton(true);
      });
  };
  
  const processPatientData = async (data) => {
    const payerMap = await fetchMatchedPayers();
  
    return data.map((patient) => {
      const dobFormatted = formatDateToMMDDYYYY(patient.dateOfBirth);
      const dosFormatted = formatDateToMMDDYYYY(patient.dateOfService);
      const dosToFormatted = formatDateToMMDDYYYY(patient.dateOfServiceTo);
  
      let billingCodes = [];
      let totalCharges = 0;
  
      // Check if the patient data includes service lines
      const hasServiceLines = patient.billingCode1 || patient.billingCode2 || patient.billingCode3;
  
      if (hasServiceLines) {
        for (let i = 1; i <= 3; i++) {
          if (patient[`billingCode${i}`]) {
            billingCodes.push({
              code: patient[`billingCode${i}`],
              billAmount: parseFloat(patient[`chargeAmount${i}`]) || 0,
              diagnosisCode: patient[`diagnosisCode${i}`] ? [patient[`diagnosisCode${i}`]] : []
            });
            totalCharges += parseFloat(patient[`chargeAmount${i}`]) || 0;
          }
        }
      } else {
        // Use selectedBillingCodes if service lines are not included in the patient data
        billingCodes = selectedBillingCodes.map(code => ({
          code: code.code,
          billAmount: code.billAmount,
          diagnosisCode: code.diagnosisCode
        }));
        totalCharges = selectedBillingCodes.reduce((sum, code) => sum + code.billAmount, 0);
      }
  
      const payerName = patient.payer?.toUpperCase() || '';
      let payer = payerMap[payerName] || {};
  
      // If no payer in the spreadsheet and payer selection is used, use the selected payer
      if (showPayerSelection && selectedPayers) {
        payer = {
          payerName: selectedPayers.name,
          CPID: selectedPayers.tradingPartnerId
        };
      }
  
      const tradingPartnerName = payer.payerName || '';
      const CPID = payer.CPID || '';
  
      return {
        firstName: patient.firstName || '',
        middleName: patient.middleName || '',
        lastName: patient.lastName || '',
        dob: dobFormatted,
        gender: patient.gender || '',
        address1: patient.address1 || '',
        address2: patient.address2 || '',
        city: patient.city || '',
        state: patient.state || '',
        zip: patient.zip || '',
        payerName: tradingPartnerName,
        payerId: CPID,
        memberId: patient.memberId,
        dos: dosFormatted,
        dosTo: dosToFormatted,
        selectedBillingCodes: billingCodes,
        totalCharges: totalCharges,
      };
    });
  };
    
  


  const readExcelData = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);
  
      fileReader.onload = (e) => {
        const bufferArray = e.target.result;
        const wb = XLSX.read(bufferArray, { type: "buffer" });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws);
        const rowCount = data.length;
        resolve({ data, rowCount });
      };
  
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  

  const fetchMatchedPayers = async () => {
    const payerMatchesCollection = collection(db, `users/${uid}/payerMatches`);
    const payerMatchesSnapshot = await getDocs(payerMatchesCollection);
    
    // Create a map of payer names to their payer objects for case-insensitive comparison
    const payerMap = {};
    payerMatchesSnapshot.docs.forEach((doc) => {
      const data = doc.data();
      payerMap[data.name.toUpperCase()] = data.payer; // Store the entire payer object
    });
    
    return payerMap;
  };
  


  const expectedHeaders = [
    "firstName", "middleName", "lastName", "dateOfBirth", "gender", "address1",
    "address2", "city", "state", "zip", "payer", "memberId", "dateOfService",
    "dateOfServiceTo", "priorAuthorizationNumber", "resubmitClaimControlNumber"
  ];

  // Regex patterns for dynamic headers
  const billingCodePattern = /^billingCode\d+$/;
  const diagnosisCodePattern = /^diagnosisCode\d+$/;
  const chargeAmountPattern = /^chargeAmount\d+$/;

  const validateFile = async (file) => {
    setIsLoading(true);
    setErrorMessage(null); // Reset error message before validation
    const errorMessages = [];

    if (
      file.type !==
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      errorMessages.push("Please upload a valid .xlsx file.");
    }

    const matchedPayers = await fetchMatchedPayers();

    const { data } = await readExcelData(file);

    if (data.length > 1500) {
      errorMessages.push(
        "The uploaded file exceeds the maximum row limit of 1500."
      );
    }

    let hasDiagnosisCodes = false;
    const errors = [];

    const parsedData = data.map((row, rowIndex) => {
      const parsedRow = {};
      const rowErrors = [];

      Object.keys(row).forEach((key) => {
        if (expectedHeaders.includes(key) || billingCodePattern.test(key) || diagnosisCodePattern.test(key) || chargeAmountPattern.test(key)) {
          if (key === "middleName") {
            if (row[key] && typeof row[key] === 'string' && row[key].trim() === '') {
              errors.push({
                row: rowIndex + 1,
                message: "Middle name cannot be blank or filled with only spaces."
              });
            }
            parsedRow[key] = row[key].trim(); // Still store the trimmed version
          } else if (key === "firstName") {
            if (typeof row[key] !== "string" || !/^[A-Za-z- ]+$/.test(row[key])) {
              errors.push({
                row: rowIndex + 1,
                message: "First name should only contain letters, hyphens, and spaces."
              });
            }
            parsedRow[key] = row[key];
          } else if (key === "lastName") {
            if (
              typeof row[key] !== "string" ||
              !/^[A-Za-z- ]+$/.test(row[key])
            ) {
              errors.push({
                row: rowIndex + 1,
                message:
                  "Last name should only contain letters, hyphens, and spaces.",
              });
            }
            parsedRow[key] = row[key];
          } else if (key === "dateOfBirth") {
            const date = new Date(row[key]);
            if (isNaN(date)) {
              errors.push({
                row: rowIndex + 1,
                message:
                  "Invalid date format for dateOfBirth. Expected format: MM/DD/YYYY",
              });
            }
            parsedRow[key] = date;
          } else if (key === "dateOfService" || key === "dateOfServiceTo") {
            const date = new Date(row[key]);
            if (isNaN(date)) {
              errors.push({
                row: rowIndex + 1,
                message:
                  `Invalid date format for ${key}. Expected format: MM/DD/YYYY`,
              });
            }
            parsedRow[key] = date;
          } else if (key === "gender") {
            if (row[key] !== "M" && row[key] !== "F") {
              errors.push({
                row: rowIndex + 1,
                message: "Invalid gender. Expected 'M' or 'F'.",
              });
            }
            parsedRow[key] = row[key];
          } else if (key === "memberId") {
            parsedRow[key] = String(row[key]).replace(/[^a-zA-Z0-9]/g, "");
          } else if (key === "address1" || key === "address2" || key === "city" || key === "state" || key === "zip") {
            if (key === "zip") {
              // Ensure ZIP is treated as a string and pad if necessary
              let zip = String(row[key]).padStart(5, '0');
              if (!/^\d{5}$/.test(zip)) {
                const environment = process.env.NODE_ENV === "development" ? " in development mode" : "";
                errors.push({
                  row: rowIndex + 1,
                  message: `Zip code should be 5 digits long${environment}.`,
                });
              } else {
                // Update the ZIP code in the row with the padded version
                row[key] = zip;
              }
            }
            parsedRow[key] = row[key];
          } else if (key === "payer") {
            // Convert the payer value from the file to uppercase
            const payerValue = row["payer"].toUpperCase();
            // Check if the uppercase payer matches the payers in the database
            if (payerValue && !matchedPayers.hasOwnProperty(payerValue)) {
              rowErrors.push({
                row: rowIndex + 1,
                message: `Invalid payer '${row["payer"]}'. Please use a matched payer. Press the Payer Matching button to add payers.`,
              });
            }
            parsedRow[key] = row[key];
          } else if (diagnosisCodePattern.test(key)) {
            // Check if diagnosisCode is present and set the flag
            if (row[key]) {
              hasDiagnosisCodes = true;
              parsedRow[key] = row[key];
            }
          } else if (billingCodePattern.test(key) || chargeAmountPattern.test(key)) {
            if (!row[key] || isNaN(row[key]) || row[key] <= 0) {
              errors.push({
                row: rowIndex + 1,
                message: `Invalid ${key}. It must be a positive number.`,
              });
            }
            parsedRow[key] = row[key];
          } else {
            // Process other fields as before
            parsedRow[key] = row[key];
          }
        }
      });

      const requiredFields = expectedHeaders.filter(
        (header) =>
          ![
            "payer",
            "address2",
            "middleName",
            "dateOfServiceTo",
            "diagnosisCode1",
            "diagnosisCode2",
            "diagnosisCode3",
            "billingCode1",
            "billingCode2",
            "billingCode3",
            "chargeAmount1",
            "chargeAmount2",
            "chargeAmount3",
            "priorAuthorizationNumber",
            "resubmitClaimControlNumber"
          ].includes(header)
      );

      const hasEmptyValues = requiredFields.some((header) => !row[header]);
      const hasFilledValues = requiredFields.some((header) => !!row[header]);

      if (hasEmptyValues && hasFilledValues) {
        rowErrors.push({
          row: rowIndex + 1,
          message:
            "Inconsistency in data: If one cell in a row is filled, all cells in that row must also be filled (except optional fields).",
        });
      }

      if (rowErrors.length > 0) {
        errors.push(...rowErrors);
        return false;
      }

      return parsedRow;
    });

    if (errors.length > 0) {
      setErrorTable(errors);
      setIsLoading(false);
      return false;
    }

    // Check if there are rows with payer data and rows without payer data
    const hasInvalidPayerMatch = parsedData.some(
      (row) =>
        row && row.payer && !matchedPayers.hasOwnProperty(row.payer.toUpperCase())
    );

    if (hasInvalidPayerMatch) {
      setErrorMessage(
        "One or more rows have an invalid PAYER entry that does not match the available payers."
      );
      setIsLoading(false);
      return false;
    }

    // Check if there are rows with payer data and rows without payer data
    const hasAnyRowWithPayerData = parsedData.some(
      (row) => row && row.payer && row.payer.trim() !== ""
    );
    const hasAnyRowWithoutPayerData = parsedData.some(
      (row) => row && (!row.payer || row.payer.trim() === "")
    );
    setHasPayerInExcel(hasAnyRowWithPayerData);

    // If there's inconsistency in payer data (some filled and some empty), display an error
    if (hasAnyRowWithPayerData && hasAnyRowWithoutPayerData) {
      setErrorMessage(
        "Inconsistency in payer data: All rows should either have payer data or none should have it."
      );
      setIsLoading(false);
      return false;
    }

    // Logic related to payer selection based on the presence of payer data
    if (!hasAnyRowWithPayerData) {
      setShowPayerSelection(true);
    } else {
      setShowPayerSelection(false); // Assuming you don't want to show payer selection if there are payer cells filled
    }

    if (errorMessages.length > 0) {
      setErrorMessage(errorMessages.join(" ")); // Joining all error messages
      setIsLoading(false);
      return false;
    }

    setHasDiagnosisCodes(hasDiagnosisCodes);

    setIsLoading(false);
    return true; // If no error triggered, validation is successful
  };


  async function processFile(file) {
    const valid = await validateFile(file);
    if (!valid) {
      setIsLoading(false); // Ensure loading is stopped if validation fails
      return;
    }
  
    setUploadedFile(file);
  
    readExcelData(file).then(({ data, rowCount }) => {
      setRowCount(rowCount);
      setActiveSection(2); // Move to the next section after validation
    });
  }

  //logic if selected via browser
  async function handleFileInputChange(e) {
    const file = e.target.files[0];
    if (file) {
      await processFile(file); // Use the common file processing function
    }
    
    if (!file) return;
    setFiles([file]);

    const valid = await validateFile(file);
    if (!valid) {
      setIsLoading(false); // Ensure loading is stopped if validation fails
      return;
    }

    setUploadedFile(file);

    readExcelData(file).then(({ data, rowCount }) => {
      setRowCount(rowCount);
      setActiveSection(2); // Move to the next section after validation
    });
  }

  const handleFileInputClick = (e) => {
    if (fileErrors.length > 0) {
      setErrorMessage("Please fix the file errors before uploading.");
      e.preventDefault(); // Prevent the file input from opening
    } else {
      fileInputRef.current.click(); // Open the file input dialog
    }
  };

  function handleDragOver(event) {
    event.preventDefault();
    event.currentTarget.classList.add("dragover");
  }
  
  function handleDragLeave(event) {
    event.preventDefault();
    event.currentTarget.classList.remove("dragover");
  }
  
  async function handleDrop(event) {
    event.preventDefault();
    event.currentTarget.classList.remove("dragover");
  
    const fileList = event.dataTransfer.files;
    if (fileList.length > 0) {
      const file = fileList[0];
      await processFile(file); // Ensure to use a common file processing function
    }
  }  
  

  const handleBillingProviderSelection = (data) => {
    setSelectedBillingProviders(data);
  };

  const handleUnifiedProviderSelection = (data) => {
    setSelectedServiceProviders(data);
    setSelectedRenderingProviders(data);
    // setselectedReferringProviders(providerData);
  };

  const handleReferringProviderSelection = (data) => {
    setSelectedReferringProviders(data);
  };

  const handleAddReferringProvider = () => {
    setShowReferringProviderButton(false);
  };

  const handleBillingCodesSelect = (selectedCodes) => {
    setSelectedBillingCodes(selectedCodes);
  };

  const handleTotalChargesChange = (charges) => {
    setTotalCharges(charges);
  };

  const handlePayerSelect = (payer) => {
    setSelectedPayers(payer);
  };

  const handlePayerUnselect = () => {
    setSelectedPayers(null);
  };

  function handleDownload() {
    let templatePath = "xlsxtemplates/claimstemplate.xlsx"; // Default template

    // Get a reference to the storage service
    const storageRef = ref(storage, templatePath);

    // Get the download URL and initiate download
    getDownloadURL(storageRef)
      .then((downloadURL) => {
        let link = document.createElement("a");
        link.href = downloadURL;
        link.download = templatePath.split("/").pop(); // Extracts filename and sets it
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link); // Clean up
      })
      .catch((error) => {
        console.error("Error downloading template:", error);
      });
  }

  useEffect(() => {
    if (activeSection === 3) {
      myRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [activeSection]);

  const validateSection2 = () => {
    let errors = {};

    setErrors2(errors);

    // Check if there are no errors
    if (Object.keys(errors).length === 0) {
      setActiveSection(2); // Assuming section 3 is Providers
      return true;
    } else {
      // Log or display errors
      console.error("Validation errors:", errors);
      return false;
    }
  };

  const canProceedToNextSection = () => {
    const isProviderSelected = (provider) => {
      const isSelected = provider && Object.keys(provider).length > 0;
      return isSelected;
    };

    const areAllProvidersSelected =
      isProviderSelected(selectedBillingProviders) &&
      isProviderSelected(selectedServiceProviders) &&
      isProviderSelected(selectedRenderingProviders)
    // isProviderSelected(selectedReferringProviders);

    return areAllProvidersSelected;
  };

  function formatDateToMMDDYYYY(dateInput) {
    if (!dateInput) return '';
  
    let date;
  
    // Check if the input is a number (Excel serial date)
    if (!isNaN(dateInput)) {
      date = new Date((dateInput - (25567 + 2)) * 86400 * 1000); // Convert Excel date to JS date
    } else {
      // Try to parse the date as various string formats
      date = new Date(dateInput);
      
      // Check if parsing was successful
      if (isNaN(date.getTime())) {
        // Try parsing as MM/DD/YYYY
        const parts = dateInput.split('/');
        if (parts.length === 3) {
          const [month, day, year] = parts.map(part => parseInt(part, 10));
          date = new Date(year, month - 1, day);
        }
      }
  
      // Check if parsing was successful
      if (isNaN(date.getTime())) {
        // Try parsing as YYYY-MM-DD
        const parts = dateInput.split('-');
        if (parts.length === 3) {
          const [year, month, day] = parts.map(part => parseInt(part, 10));
          date = new Date(year, month - 1, day);
        }
      }
    }
  
    // Final check if the date is valid
    if (!isNaN(date.getTime())) {
      const day = ('0' + date.getDate()).slice(-2); // Ensure two digits
      const month = ('0' + (date.getMonth() + 1)).slice(-2); // Ensure two digits and correct month index
      const year = date.getFullYear();
      return `${month}/${day}/${year}`;
    } else {
      return ''; // Return empty string if date is invalid
    }
  }
  

  const handleDiagnosisCodeSelect = (selectedCodes) => {
    console.log(selectedCodes);
  };

  return (
    <div
      className="Claims-inner-container"
      style={{ display: "flex", alignItems: "flex-start" }}
    >
      <button className="filesCloseButton" onClick={onClose}>
        X
      </button>
      <div
        className="Claims-inner-content"
        style={{ display: "flex", alignItems: "flex-start" }}
      >
        {isPayerModalOpen ? (
          <PayerMatchingModal mode={'claims'} onClose={() => setIsPayerModalOpen(false)} />
        ) : null}

        <Modal
          isOpen={isDiagnosisModalOpen}
          onRequestClose={() => setIsDiagnosisModalOpen(false)}
          className="confirmModal"
        >
          <button className='filesCloseButton' onClick={() => setIsDiagnosisModalOpen(false)} >
            X
          </button>
          <SearchBox onCodeSelect={handleDiagnosisCodeSelect} />

        </Modal>

        <div className="checkout">
          <div className="centerHeader">
            <h3>Batch Claims XLSX Submission</h3>
            <p>Submit up to 1,500 claims using an XLSX template provided.</p>
          </div>
          <CollapsibleSection
            title="New Batch"
            number={1}
            activeSection={activeSection}
            setActiveSection={setActiveSection}
          >
            <div className="stepsContainer">
              {!isLoading && (
                <div>
                  <input
                    type="file"
                    accept=".xlsx"
                    ref={fileInputRef}
                    onChange={handleFileInputChange}
                    style={{ display: "none" }}
                  />
                  <button id="templateButton" onClick={handleDownload}>
                    Download XLSX Template
                  </button>
                  <button
                    title="Match payers in your spreadsheet with those in Popularis."
                    id="templateButton"
                    onClick={() => setIsPayerModalOpen(true)}
                  >
                    Payer Matching for Template
                  </button>
                  <button
                    title="Make sure you are using the right diagniostic codes if using the spreadsheet for service lines."
                    id="templateButton"
                    onClick={() => setIsDiagnosisModalOpen(true)}
                  >
                    Diagnostic Code Lookup
                  </button>
                  <div
                    className="dropbox"
                    onClick={handleFileInputClick}
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                    title="Upload files"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "1rem",
                    }}
                  >
                    <div className="dropBoxContent">
                      <FontAwesomeIcon
                        icon={faCloudArrowUp}
                        style={{ height: "3rem", marginBottom: ".5rem" }}
                      />
                    </div>
                    <p>Click to browse or drop files in here.</p>
                  </div>
                </div>
              )}
              {isLoading && (
                <>
                  <HexSpinner />
                  <p>Validating the data</p>
                </>
              )}
            </div>

            {imgUrl && (
              <div className="outerbar">
                <div
                  className="innerbar"
                  style={{ width: `${progresspercent}%` }}
                >
                  {progresspercent}%
                </div>
              </div>
            )}

            {errorTable.length > 0 && (
              <div className="error-table-container">
                <h3>Errors found in the file:</h3>
                <table>
                  <thead>
                    <tr>
                      <th>Row</th>
                      <th>Error Message</th>
                    </tr>
                  </thead>
                  <tbody>
                    {errorTable.map((error, index) => (
                      <tr key={index}>
                        <td>{error.row + 1}</td>
                        <td>{error.message}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}

            <div id="message" style={{ display: "none" }}>
              Please fill out all fields. Do not modify headers. Please read
              the instructions.
            </div>
          </CollapsibleSection>

          <CollapsibleSection
            title="Payer"
            number={2}
            activeSection={activeSection}
            setActiveSection={setActiveSection}
          >
            {showPayerSelection && (
              <div className={styles.payerSelection}>
                {!selectedPayers && (
                  <PayerSelection
                    payers={payers}
                    onSelect={handlePayerSelect}
                    mode={"claims"}
                    required
                  />
                )}
                {selectedPayers && (
                  <div>
                    <h3>Selected Payer:</h3>
                    <p>{selectedPayers.name}</p>
                    <button onClick={handlePayerUnselect}>Change</button>
                  </div>
                )}
              </div>
            )}

            {!hasDiagnosisCodes && (
              <ServiceLineTable
                onBillingCodesSelect={handleBillingCodesSelect}
                onTotalChargesChange={handleTotalChargesChange}
                required
              />
            )}

            <div className="errors2Section">
              {errors2.payer && (
                <div className="error-text">{errors2.payer}</div>
              )}
              {errors2.billingCode && (
                <div className="error-text">{errors2.billingCode}</div>
              )}
            </div>

            <button
              type="button"
              onClick={() => {
                if (validateSection2()) {
                  setActiveSection((prevSection) => prevSection + 1);
                }
              }}
            >
              Next
            </button>
          </CollapsibleSection>
          <CollapsibleSection
            ref={myRef}
            title="Provider"
            number={3}
            activeSection={activeSection}
            setActiveSection={setActiveSection}
            style={{ paddingLeft: 0 }}
          >
            <ProviderProvider uid={uid}>
              <h3>a. Select a Billing Provider</h3>
              <h4>
                The important one. Add medicare number, medicaid number, and
                CLIA here.
              </h4>
              <ProviderSelection
                type="billing"
                onProviderSelect={handleBillingProviderSelection}
              />
              <div
                style={{
                  height: "1px",
                  backgroundColor: "black",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              ></div>
              <h3>b. Select a Service Provider</h3>
              <h4>Servicing and rendering provider.</h4>
              <ProviderSelection
                type="unified"
                onProviderSelect={handleUnifiedProviderSelection}
              />
              {!showReferringProviderButton && (<>
                <h3>c. Select a Refering Provider</h3>
                <h4>Servicing and rendering provider.</h4>
                <ProviderSelection
                  type="refering"
                  onProviderSelect={handleReferringProviderSelection}
                />
              </>
              )}

              {showReferringProviderButton && (
                <button type="button" onClick={handleAddReferringProvider}>
                  Add Referring Provider
                </button>
              )}
              <div className="help-article-wide">
                <HelpArticleLink
                  article={{
                    title: "Adding Providers",
                    link: "https://popularishealth.com/article/Adding-Providers",
                  }}
                />
              </div>
              <div className={styles.nextButtonContainer}>
                <button
                  type="button"
                  disabled={!canProceedToNextSection()}
                  onClick={() => {
                    setActiveSection(activeSection + 1);
                  }}
                >
                  Next
                </button>
                {!canProceedToNextSection() && (
                  <span className="error-text">
                    Please select all providers
                  </span>
                )}
              </div>
            </ProviderProvider>
          </CollapsibleSection>

          <CollapsibleSection
            title="Review and Submit"
            number={4}
            activeSection={activeSection}
            setActiveSection={setActiveSection}
          >
            <div className="reviewAndSubmit">
              <p style={{ borderBottom: "1px solid black" }}>
                Number of claims: {rowCount}
              </p>{" "}
              {/* Calculate and display the number of requests */}
              <div className={styles.reviewItem}>
                  <span className={styles.lightText}>Billing Provider:</span>
                  <strong>
                      {selectedBillingProviders
                          ? `${selectedBillingProviders.firstName} ${selectedBillingProviders.lastName} | NPI: ${selectedBillingProviders.npi}`
                          : "No provider selected"}
                  </strong>
              </div>
              <div className={styles.reviewItem}>
                  <span className={styles.lightText}>Service Provider:</span>
                  <strong>
                      {selectedServiceProviders
                          ? `${selectedServiceProviders.firstName} ${selectedServiceProviders.lastName} | NPI: ${selectedServiceProviders.npi}`
                          : "No provider selected"}
                  </strong>
              </div>
              <div className={styles.reviewItem}>
                <span className={styles.lightText}>Diagnosis Codes:</span>
                <strong>
                  {selectedBillingCodes
                    .map((code) => code.diagnosisCode.join(", "))
                    .join("; ")}
                </strong>
              </div>
              {!hasDiagnosisCodes && (
                <div className={styles.reviewSection}>
                  <h4>Service Lines</h4>
                  <table className={styles.serviceLinesTable}>
                    <thead>
                      <tr>
                        <th>Code</th>
                        <th>Name</th>
                        <th>Diagnosis Code</th>
                        <th>Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedBillingCodes.map((code, index) => (
                        <tr key={index}>
                          <td>{code.code}</td>
                          <td>{code.name}</td>
                          <td>{code.diagnosisCode.join(", ")}</td>
                          <td>${code.billAmount.toFixed(2)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
              <div className="button-container">
                <div className={styles.searchButtonContainer}>
                  {isLoading ? (
                    <HexSpinner />
                  ) : (
                    <div>
                      {showSuccessMessage && successCount > 0 && (
                        <div>
                          <h3>
                            The batch of claims have been successfully
                            submitted.
                          </h3>
                          <p>Successful submissions: {successCount}. </p>
                          <Confetti />
                        </div>
                      )}

                      {errorMessage && (
                        <div>
                          <h3>Failed Patients</h3>
                          <table>
                            <thead>
                              <tr>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>Error</th>
                              </tr>
                            </thead>
                            <tbody>
                              {failedPatients.map((patient) => (
                                <tr
                                  key={`${patient.requestData.subscriber.firstName}-${patient.requestData.subscriber.lastName}`}
                                >
                                  <td>
                                    {patient.requestData.subscriber.firstName}
                                  </td>
                                  <td>
                                    {patient.requestData.subscriber.lastName}
                                  </td>
                                  <td>{patient.error}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                 
                        </div>
                      )}

                      {!isLoading && showSubmitButton && (
                        <>
                          <div>
                            <button type="button" onClick={handleSubmit}>
                              Submit Claims
                            </button>
                          </div>
                          <div className="switchTestModeContainer">
                            <label className="switch">
                              <input type="checkbox" checked={isTestMode} onChange={() => setIsTestMode(!isTestMode)} />
                              <span className="slider round"></span>
                            </label>
                            <p>{isTestMode ? 'Test Mode is ON' : 'Test Mode is OFF'}</p>
                          </div>
                        </>
                      )}
                    </div>
                  )}
                </div>
              </div>
              {/* {!isLoading && <button onClick={onClose}>Done</button>} */}
            </div>
          </CollapsibleSection>
          {errorMessage && (
            <div className="error-message">{errorMessage}</div>
          )}
          {payerError && <div className="error-message">{payerError}</div>}
          <div className="help-article-wide">
            <HelpArticleLink
              article={{
                title: "Batch Claims",
                link: "https://popularishealth.com/article/Sending-Batch-Claims",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
export default BatchClaims;
