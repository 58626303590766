import React, { useState } from "react";
import { FaDownload } from "react-icons/fa";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver"; // Ensure this is installed: npm i file-saver

const IntakeDownload = ({ selectedPatients, patients, clinicId }) => {
  const [isDownloading, setIsDownloading] = useState(false);

  const handleDownload = async () => {
    try {
      setIsDownloading(true);
      console.log("Download initiated...");

      // Filter only the selected patients in memory
      const selectedData = patients.filter((patient) =>
        selectedPatients.includes(patient.id)
      );

      if (selectedData.length === 0) {
        setIsDownloading(false);
        return;
      }

      // Build CSV data from patient and payers info
      const csvData = selectedData.map((patient) => {
        const {
          firstName,
          middleName,
          lastName,
          dob,
          gender,
          address,
          email,
          phone,
        } = patient?.data?.patient || {};
        const { address1, address2, city, state, zip } = address || {};
        const fullAddress = `${address1 || ""} ${address2 || ""}`.trim();

        // Extract payer information
        const {
          name: payerName,
          memberId,
          CPID,
          RealtimePayerID,
        } = patient?.data?.payers || {};

        // Extract and format created timestamp
        const createdTimestamp = patient?.created
          ? new Date(patient.created) // Correct path
          : null;
        const createdDate = createdTimestamp
          ? createdTimestamp.toLocaleDateString()
          : "";
        const createdTime = createdTimestamp
          ? createdTimestamp.toLocaleTimeString()
          : "";

        return {
          FirstName: firstName || "",
          MiddleName: middleName || "",
          LastName: lastName || "",
          DateOfBirth: dob || "",
          Gender: gender || "",
          Email: email || "",
          Phone: phone || "",
          Address: fullAddress,
          City: city || "",
          State: state || "",
          Zip: zip || "",
          PayerName: payerName || "",
          MemberId: memberId || "",
          CreatedDate: createdDate,
          CreatedTime: createdTime,
        };
      });

      console.log("Generated CSV Data:", csvData);

      // Convert JSON to worksheet, create a workbook, and trigger download
      const worksheet = XLSX.utils.json_to_sheet(csvData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Patient Data");

      const csvBlob = new Blob(
        [XLSX.write(workbook, { bookType: "csv", type: "array" })],
        { type: "text/csv;charset=utf-8;" }
      );
      saveAs(csvBlob, `patient_data_${clinicId}.csv`);
      console.log("CSV Download Complete.");
    } catch (error) {
      console.error("Error in handleDownload:", error);
    } finally {
      setIsDownloading(false);
    }
  };

  return (
    <>
      {isDownloading ? (
        <div className="small-spinner">
          <div className="small-spinner-circle"></div>
        </div>
      ) : (
        <button onClick={handleDownload} title="Download">
          <FaDownload />
        </button>
      )}
    </>
  );
};

export default IntakeDownload;
