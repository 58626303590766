import React, { useState, useEffect, useRef } from "react";
import { db, storage } from "../../firebase";
import {
  collection,
  query,
  onSnapshot,
  addDoc,
  orderBy,
  doc,
  deleteDoc,
} from "firebase/firestore";
import { ref as storageRef, uploadBytes, getDownloadURL } from "firebase/storage";
import { v4 as uuidv4 } from "uuid";
import styles from "./PatientView.module.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrashAlt,
  faFilePdf,
  faCamera,
  faCloudArrowUp,
} from "@fortawesome/free-solid-svg-icons";

import useUID from "../General/useUID";

function Files({ patientId, onClose }) {
  const [files, setFiles] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [uid] = useUID();            // We only use the main UID here
  const [isMobile, setIsMobile] = useState(false);
  const fileInputRef = useRef(null);

  useEffect(() => {
    if (!uid || !patientId) return;
  
    // Firestore reference to this patient's file docs
    const filesRef = collection(db, "patients", uid, "patientData", patientId, "files");
    const q = query(filesRef, orderBy("timestamp", "desc"));
  
    // Listen for changes to that collection
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const filesData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
  
      // Filter out files with 'undefined' in the URL.
      const validFiles = filesData.filter((file) => {
        if (!file.url) return false;
        return !file.url.includes("/undefined/");
      });
  
      setFiles(validFiles);
    });
  
    // Detect if user is on mobile
    if (/Mobi|Android|iPhone|iPad|iPod/i.test(navigator.userAgent)) {
      setIsMobile(true);
    }
  
    return () => unsubscribe();
  }, [uid, patientId]);
  

  const getFileType = (fileName) => {
    const lower = fileName.toLowerCase();
    if (lower.endsWith(".jpg") || lower.endsWith(".jpeg") || lower.endsWith(".png")) {
      return "image";
    } else if (lower.endsWith(".pdf")) {
      return "pdf";
    }
    return "other";
  };

  const handleFileUpload = async (file) => {
    if (!file || !uid || !patientId) return;

    setUploading(true);

    // If the file is from the camera, rename it
    const isCameraCapture = fileInputRef.current?.capture;
    if (isCameraCapture) {
      const uniqueSuffix = uuidv4();
      const currentDate = new Date().toISOString().split("T")[0]; // YYYY-MM-DD
      const fileExtension = file.name.split(".").pop();
      const newFileName = `phoneshot_${currentDate}_${uniqueSuffix}.${fileExtension}`;

      // Create a new File object with the new name
      file = new File([file], newFileName, { type: file.type });
    }

    // Upload to Storage at /PatientDocuments/uid/patientId/fileName
    const storageReference = storageRef(
      storage, 
      `PatientDocuments/${uid}/${patientId}/${file.name}`
    );
    await uploadBytes(storageReference, file);
    const downloadUrl = await getDownloadURL(storageReference);

    // Add a doc in Firestore with file metadata
    const filesRef = collection(db, "patients", uid, "patientData", patientId, "files");
    const docRef = await addDoc(filesRef, {
      name: file.name,
      url: downloadUrl,
      timestamp: new Date(),
    });

    // Optionally log activity
    await addDoc(collection(db, "users", uid, "activityLogs"), {
      timestamp: Date.now(),
      patientId: patientId,
      fileId: docRef.id,
      activity: "File added",
      activityType: "fileAdded",
      uid: uid,
    });

    setUploading(false);
  };

  const handleFileInputClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileInputChange = (e) => {
    if (e.target.files?.[0]) {
      handleFileUpload(e.target.files[0]);
      e.target.value = ""; // Reset so the same file can be uploaded again if needed
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.currentTarget.classList.add(styles.dropboxDragover);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.currentTarget.classList.remove(styles.dropboxDragover);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files?.[0]) {
      handleFileUpload(e.dataTransfer.files[0]);
    }
  };

  const handleDeleteFile = async (fileId) => {
    if (!uid || !patientId) return;
    const fileRef = doc(db, "patients", uid, "patientData", patientId, "files", fileId);
    await deleteDoc(fileRef);
  };

  return (
    <>
      {/* Header / Close Button */}
      <div className="closeButtonContainer">
        <button onClick={onClose} className="filesCloseButton">
          X
        </button>
        <p className="closeBarNav">Files</p>
      </div>

      <div className="notesContainer">
        <div className={styles.filesLayout}>
          
          {/* Upload Box */}
          <div className={styles.uploadBox}>
            <form className={styles.uploadForm}>
              {/* Hidden file input */}
              <input
                type="file"
                accept="image/*,application/pdf"
                ref={fileInputRef}
                onChange={handleFileInputChange}
                style={{ display: "none" }}
              />

              {/* Different UI for mobile vs. desktop */}
              {isMobile ? (
                <>
                  <button
                    type="button"
                    className={styles.uploadFileButton}
                    onClick={handleFileInputClick}
                  >
                    <FontAwesomeIcon icon={faCloudArrowUp} />
                    &nbsp;Add Files
                  </button>
                  <button
                    type="button"
                    className={styles.cameraButton}
                    onClick={() => {
                      fileInputRef.current.setAttribute("accept", "image/*");
                      fileInputRef.current.setAttribute("capture", "user");
                      fileInputRef.current.click();
                      // Restore defaults after a short delay
                      setTimeout(() => {
                        fileInputRef.current.removeAttribute("capture");
                        fileInputRef.current.setAttribute(
                          "accept",
                          "image/*,application/pdf"
                        );
                      }, 1000);
                    }}
                  >
                    <FontAwesomeIcon icon={faCamera} />
                    &nbsp;Take a Picture
                  </button>
                </>
              ) : (
                <div
                  className={styles.dropbox}
                  onClick={handleFileInputClick}
                  onDragOver={handleDragOver}
                  onDragLeave={handleDragLeave}
                  onDrop={handleDrop}
                  title="Upload files"
                >
                  <div className={styles.dropBoxContent}>
                    <FontAwesomeIcon
                      icon={faCloudArrowUp}
                      style={{ height: "3rem", marginBottom: ".5rem" }}
                    />
                  </div>
                  <p>Click or drag and drop files here.</p>
                </div>
              )}
            </form>
          </div>

          {/* File List */}
          <div className={styles.filesList}>
            <h3>Saved Files</h3>
            {files.length > 0 ? (
              <ul>
                {files.map((file) => (
                  <li key={file.id} className={styles.fileItem}>
                    <div className={styles.fileContent}>
                      {getFileType(file.name) === "image" && (
                        <img
                          src={file.url}
                          alt={file.name}
                          className={styles.fileImagePreview}
                        />
                      )}
                      {getFileType(file.name) === "pdf" && (
                        <FontAwesomeIcon icon={faFilePdf} className={styles.filePdfPreview} />
                      )}
                      <a
                        href={file.url}
                        download={file.name}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {file.name}
                      </a>
                    </div>
                    <button
                      onClick={() => handleDeleteFile(file.id)}
                      className={styles.deleteFileButton}
                    >
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </button>
                  </li>
                ))}
              </ul>
            ) : (
              <p>No files available.</p>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Files;
