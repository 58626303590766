import React, { useEffect, useState } from "react";
import { getFunctions, httpsCallable } from "firebase/functions";
import { auth, db } from "../../../firebase";
import {
  collection,
  addDoc,
  getDoc,
  updateDoc,
  doc,
  serverTimestamp,
  query,
  where,
  getDocs
} from "firebase/firestore";
import PdfGenerator from "./PdfGenerator";
import Signature from "./Signature";
import useUID from "../../General/useUID";
import Confetti from "../../General/Animations/Confetti";
import HelpArticleLink from "../../Articles/HelpArticleLink";
import { openEmailClient } from "./emailUtil";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import HexSpinner from "../../General/Animations/Hexspinner";

const functions = getFunctions();

function DoctorsNote({ patientId, note = null, body, manualInputMode }) {
  const [response, reRponse] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [disabledSave, setDisabledSave] = useState(false);
  const [patientData, setPatientData] = useState(null);
  const [user, setUser] = useState({});
  const [formattedResponse, setFormattedResponse] = useState(null);
  const [isPatientDataLoaded, setIsPatientDataLoaded] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [currentPanel, setCurrentPanel] = useState(manualInputMode ? 2 : 1);
  const [signatureData, setSignatureData] = useState(null);
  const [noteBody, setNoteBody] = useState(body || "");
  const [Error, setError] = useState("");
  const [Success, setSuccess] = useState("");
  const [savedNoteId, setSavedNoteId] = useState(null);
  const [uid, subUserUID] = useUID();
  const [defaultProvider, setDefaultProvider] = useState(null);
  const [isLoadingModalOpen, setLoadingModalOpen] = useState(false);

  useEffect(() => {
    if (currentPanel === 4) handleSave();
  }, [currentPanel]);

  useEffect(() => {
    setNoteBody(body || "");
  }, [body]);

  useEffect(() => {
    if (!uid) return;

    const loadDefaultProvider = async () => {
      try {
        const provider = await fetchDefaultProvider();
        setDefaultProvider(provider);
      } catch (error) {
        console.error("Failed to fetch default provider:", error);
      }
    };

    loadDefaultProvider();
  }, [uid]);

  useEffect(() => {
    if (!uid) return;

    const loadPatientData = async () => {
      const patientRef = doc(db, "patients", uid, "patientData", patientId);
      const patientSnapshot = await getDoc(patientRef);

      if (patientSnapshot.exists()) {
        const encryptedPatientData = patientSnapshot.data();
        const encryptedPayload = {
          iv: encryptedPatientData.patient.iv,
          ciphertext: encryptedPatientData.patient.ciphertext,
        };

        const decryptFunction = httpsCallable(functions, "decrypt");
        try {
          const decryptedResult = await decryptFunction(encryptedPayload);

          if (decryptedResult.data) {
            const decryptedPatientData = decryptedResult.data;
            const patient = {
              id: patientSnapshot.id,
              data: decryptedPatientData,
            };
            setPatientData(patient.data);
            setIsPatientDataLoaded(true);
          } else {
            console.error("Decryption failed with empty result.");
          }
        } catch (error) {
          console.error("Error decrypting patient data:", error);
        }
      } else {
        console.error("Patient not found for ID:", patientId);
      }
    };

    loadPatientData();
  }, [patientId, uid]);

  useEffect(() => {
    if (isPatientDataLoaded && body && body.trim() !== "") {
      handleSubmit({ preventDefault: () => {} });
    }
  }, [isPatientDataLoaded, body]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (noteBody.trim() === "") {
      setError("Note body is required");
      return;
    }

    setIsLoading(true);

    try {
      // Build the note body without any replacements/sanitization
      const newNoteBody = `
        Current Note Author: ${user?.fullName}
        Patient Name: ${patientData?.patient.firstName} ${patientData?.patient.lastName}
        Organization Name: ${user?.organizationName ? user.organizationName : '[Organization Name]'}
        Organization Contact Information: ${user?.phone ? user.phone : '[Contact Information]'}
        ${noteBody}
      `;

      // Include provider and patient data in the payload
      const data = {
        question: newNoteBody,
        uid: uid,
        provider: defaultProvider,
        patient: patientData,
      };

      const doctorsNoteCallable = httpsCallable(functions, "doctorsNote");
      const result = await doctorsNoteCallable(data);

      let tempFormattedResponse = result.data.text;
      setFormattedResponse(tempFormattedResponse);
      reRponse(tempFormattedResponse);
    } catch (error) {
      console.error("Error:", error);
    }

    setError(null);
    setCurrentPanel(currentPanel + 1);
    setIsLoading(false);
  };

  const back = () => {
    if (currentPanel >= 1) setCurrentPanel(currentPanel - 1);
    setError("");
  };

  const next = async () => {
    if (currentPanel === 1 && noteBody.trim() === "") {
      setError("Note body is required");
      return;
    }

    if (currentPanel === 2 && response.trim() === "") {
      setError("Note body is required");
      return;
    }

    if (currentPanel <= 4) setCurrentPanel(currentPanel + 1);
  };

  const handleSave = async () => {
    setIsUploading(true);

    try {
      const encryptFunction = httpsCallable(functions, "encrypt");
      const patientName = `${patientData.patient.firstName} ${patientData.patient.lastName} ${manualInputMode ? "" : "(AI)"}`;
      const authorName = user.fullName || "Unknown Author";
      const notesRef = collection(
        db,
        "patients",
        uid,
        "patientData",
        patientId,
        "notes"
      );

      const encryptedResponse = await encryptFunction({ text: response });
      const { ciphertext, iv } = encryptedResponse.data;

      const noteData = {
        ciphertext,
        iv,
        timestamp: serverTimestamp(),
        signatureId: signatureData?.id,
        signatureData,
        authorName,
        isAIGenerated: !manualInputMode,
      };

      let newNoteRef;
      if (savedNoteId) {
        const docRef = doc(notesRef, savedNoteId);
        await updateDoc(docRef, noteData);
        newNoteRef = docRef;
      } else {
        newNoteRef = await addDoc(notesRef, noteData);
      }

      const logData = {
        timestamp: Date.now(),
        patientId: patientId,
        noteId: newNoteRef.id,
        activity: "New note",
        activityType: "newNote",
        uid: uid,
      };

      await addDoc(collection(db, "users", uid, "activityLogs"), logData);

      setTimeout(() => {
        setSuccess("Note saved successfully!");
        setIsUploading(false);
      }, 3000);
    } catch (error) {
      console.error("Error saving note:", error);
      setError("Failed to save note: " + error.message);
      setIsUploading(false);
    }
  };

  const handleDownload = async () => {
    const pdfGenerator = new PdfGenerator();
    await pdfGenerator.downloadPdf(response, patientData, signatureData, uid, subUserUID);
  };

  const createAnotherNote = () => {
    setNoteBody("");
    reRponse("");
    setCurrentPanel(manualInputMode ? 2 : 1);
    setError("");
    setSuccess("");
  };

  const fetchDefaultProvider = async () => {
    setLoadingModalOpen(true);
    try {
      const providersRef = collection(db, `users/${uid}/providers`);
      const defaultProviderQuery = query(providersRef, where("isDefault", "==", true));
      const querySnapshot = await getDocs(defaultProviderQuery);

      if (querySnapshot.empty) {
        setSelectingProvider(true);
        return null;
      }

      const defaultProviderDoc = querySnapshot.docs[0];
      const encryptedData = defaultProviderDoc.data();

      if (!encryptedData.ciphertext || !encryptedData.iv) {
        throw new Error("Provider has no encrypted data fields.");
      }

      const decrypt = httpsCallable(functions, "decrypt");
      const decryptedResponse = await decrypt({
        ciphertext: encryptedData.ciphertext,
        iv: encryptedData.iv,
      });

      if (!decryptedResponse.data) {
        throw new Error("Decryption returned no data.");
      }

      const decryptedProvider = {
        id: defaultProviderDoc.id,
        ...decryptedResponse.data,
        isDefault: encryptedData.isDefault || false,
      };

      return decryptedProvider;
    } catch (error) {
      console.error("Error fetching default provider:", error);
      throw error;
    } finally {
      setLoadingModalOpen(false);
    }
  };

  const panels = {
    1: (
      <>
        <form onSubmit={handleSubmit}>
          {Error && <div className="error-message">{Error}</div>}
          <div className="response">
            <textarea
              id="body"
              disabled={isLoading}
              value={noteBody}
              onChange={(e) => setNoteBody(e.target.value)}
              className="response-edit"
              placeholder="Summarize your note, and leave the rest to us..."
            />
          </div>
          {isLoading ? (
            <HexSpinner />
          ) : (
            <>
              <button className="submit-button" disabled={isLoading}>
                Generate Note
              </button>
              <HelpArticleLink
                article={{
                  title: "Using Notes",
                  link: "https://popularishealth.com/article/Using-Note",
                }}
              />
            </>
          )}
        </form>
      </>
    ),
    2: (
      <div id="response" className="response" style={{ marginBottom: "20px" }}>
        {Error && <div className="error-message">{Error}</div>}
        <textarea
          className="response-edit"
          value={response}
          onChange={(e) => reRponse(e.target.value)}
          placeholder="Add a new note"
        />
        {Success && <div className="success-message">{Success}</div>}
        {!manualInputMode && (
          <div>
            <label htmlFor="body">
              PopularisAI may produce inaccurate information. Please carefully read the letter before saving.
            </label>
          </div>
        )}
        <div className="button-group">
          {!manualInputMode && (
            <button className="back-button rm-220" onClick={() => back()}>
              Back
            </button>
          )}
          <button className={manualInputMode ? "submit-button" : "next-button"} onClick={() => next()}>
            {manualInputMode ? "Add Note" : "Next"}
          </button>
        </div>
        {manualInputMode && (
          <HelpArticleLink
            article={{
              title: "Using Notes",
              link: "https://popularishealth.com/article/Using-Note",
            }}
          />
        )}
      </div>
    ),
    3: (
      <div>
        <Signature
          onBack={() => back()}
          onNext={() => next()}
          onApplySignature={(signatureData) => setSignatureData(signatureData)}
        />
      </div>
    ),
    4: (
      <div>
        <Confetti />
        <div className="congratulations-message">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100"
            height="100"
            viewBox="0 0 100 100"
            fill="none"
          >
            <circle
              cx="50"
              cy="50"
              r="45"
              stroke="rgb(0, 49, 111)"
              strokeWidth="8"
              fill="none"
            />
            <path
              d="M30 50L45 65L75 35"
              stroke="rgb(0, 49, 111)"
              strokeWidth="8"
              strokeLinecap="round"
            />
          </svg>
          <h2>Congratulations!</h2>
          <p>Your note is completed &amp; saved.</p>
          <p>You can now download,</p>
          <p>or create another one.</p>
        </div>
        {Success && (
          <div className="success-message">Note successfully saved.</div>
        )}
        <button className="back-button rm-220" onClick={() => back()}>
          Back
        </button>
        <button onClick={handleDownload} className="next-button">
          Download PDF
        </button>
        {defaultProvider ? (
          patientData?.patient?.email && (
            <button
              onClick={() => openEmailClient({ patientData, defaultProvider, noteContent: response })}
              className="next-button"
            >
              Email Patient
            </button>
          )
        ) : (
          <p>Loading Email...</p>
        )}
        <button onClick={createAnotherNote} className="next-button">
          + Create Another
        </button>
      </div>
    ),
  };

  return <div className="doctors-note">{panels[currentPanel]}</div>;
}

export default DoctorsNote;
