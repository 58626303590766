// src/components/BillingCodes/BillingCodeForm.jsx
import React from "react";

function BillingCodeForm({
  name,
  setName,
  code,
  setCode,
  billAmount,
  setBillAmount,
  modifierA,
  setModifierA,
  modifierB,
  setModifierB,
  error,
  onSubmit,
  onCancel,
  isEditing,
}) {
  return (
    <div className="billingInput">
      <form onSubmit={onSubmit}>
        <div className="input-group-row">
          <div className="input-field">
            <label style={{ color: "#ffffff" }} htmlFor="name">
              Name of Template:
            </label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value.toUpperCase())}
              placeholder="eg. Annual Wellness Visit"
              required
            />
          </div>
        </div>
        <div className="input-group-row">
          <div className="input-field">
            <label style={{ color: "#ffffff" }} htmlFor="billingCode">
              Billing Code:
            </label>
            <input
              type="text"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              placeholder="eg. G0438"
              required
            />
          </div>
          <div className="input-field">
            <label style={{ color: "#ffffff" }} htmlFor="billAmount">
              Charge Amount:
            </label>
            <input
              type="text"
              value={billAmount}
              placeholder="eg. $100"
              onChange={(e) => {
                let value = e.target.value.replace(/[^0-9.]/g, "");
                setBillAmount(value);
              }}
              required
            />
          </div>
        </div>
        <div className="input-group-row">
          <div className="input-field">
            <label style={{ color: "#ffffff" }} htmlFor="modifierA">
              Modifier A:
            </label>
            <input
              type="text"
              value={modifierA}
              onChange={(e) => setModifierA(e.target.value)}
              placeholder="Modifier A:"
            />
          </div>
          <div className="input-field">
            <label style={{ color: "#ffffff" }} htmlFor="modifierB">
              Modifier B:
            </label>
            <input
              type="text"
              value={modifierB}
              onChange={(e) => setModifierB(e.target.value)}
              placeholder="Modifier B:"
            />
          </div>
        </div>
        <button type="submit">
          {isEditing ? "Update Billing Code" : "Add Billing Code"}
        </button>
        {error && <div className="error">{error}</div>}
      </form>
      <button onClick={onCancel}>Cancel</button>
    </div>
  );
}

export default BillingCodeForm;
