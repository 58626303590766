import React, { useState, useEffect } from "react";
import {
  collection,
  addDoc,
  deleteDoc,
  doc,
  onSnapshot,
} from "firebase/firestore";
import { db } from "../../../firebase";
import HexSpinner from "../../General/Animations/Hexspinner";
import useUID from "../../General/useUID";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart as regularHeart } from "@fortawesome/free-regular-svg-icons";
import { faHeart as solidHeart } from "@fortawesome/free-solid-svg-icons";
import algoliasearch from "algoliasearch/lite";

// Initialize Algolia client and index.
const algoliaClient = algoliasearch(
  process.env.REACT_APP_AGNOLIA_CLIENT,
  process.env.REACT_APP_AGNOLIA_API
);
const algoliaIndex = algoliaClient.initIndex("ICD10");

const ICD10 = ({ onCodeSelect, initialSelectedCodes, billingCodeId }) => {
  const [selectedCodes, setSelectedCodes] = useState([]);
  const [favorites, setFavorites] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const [uid] = useUID();

  // Initialize selected codes on mount.
  useEffect(() => {
    if (initialSelectedCodes && initialSelectedCodes.length > 0) {
      console.log("Initializing codes:", initialSelectedCodes);
      setSelectedCodes(initialSelectedCodes);
    }
  }, [initialSelectedCodes]);

  // Listen for favorites from the Firestore subcollection for this billing code.
  useEffect(() => {
    if (billingCodeId) {
      const favoritesRef = collection(
        db,
        `users/${uid}/billingCodes/${billingCodeId}/favoriteDiagnosisCodes`
      );
      const unsubscribe = onSnapshot(favoritesRef, (snapshot) => {
        const favs = snapshot.docs.map((docSnap) => ({
          id: docSnap.id,
          ...docSnap.data(),
        }));
        console.log("Favorites loaded:", favs);
        setFavorites(favs);
      });
      return unsubscribe;
    }
  }, [billingCodeId, uid]);

  // Merge favorites with the current selections, ensuring each code is unique.
  useEffect(() => {
    const mergedCodes = Array.from(
      new Map([...favorites, ...selectedCodes].map((item) => [item.code, item]))
    .values());
    
    if (mergedCodes.length !== selectedCodes.length) {
      console.log("Merging favorites with current selections:", mergedCodes);
      setSelectedCodes(mergedCodes);
      if (onCodeSelect) {
        onCodeSelect(mergedCodes);
      }
    }
  }, [favorites, selectedCodes, onCodeSelect]);

  // Perform an Algolia search, limiting to 25 results.
  const searchICD10 = async (term) => {
    try {
      const { hits } = await algoliaIndex.search(term, { hitsPerPage: 25 });
      // Filter for valid codes if desired.
      const validHits = hits.filter((hit) => hit.valid_code === 1);
      console.log("Algolia search results for", term, validHits);
      return validHits;
    } catch (error) {
      console.error("Error while searching ICD10 via Algolia:", error);
      return [];
    }
  };

  const searchAndSelectCode = async (code) => {
    console.log("Searching for code:", code);
    setIsLoading(true);
    const results = await searchICD10(code);
    if (results.length > 0) {
      handleCodeSelect(results[0]);
    }
    setIsLoading(false);
  };

  const handleCodeSelect = (result) => {
    console.log("handleCodeSelect called with:", result);
    if (!selectedCodes.some((c) => c.code === result.code)) {
      const newSelectedCodes = [...selectedCodes, result];
      console.log("New selected codes:", newSelectedCodes);
      setSelectedCodes(newSelectedCodes);
      setSearchTerm("");
      setShowResults(false);
      if (onCodeSelect) {
        console.log("Calling onCodeSelect with:", newSelectedCodes);
        onCodeSelect(newSelectedCodes);
      }
    }
  };

  const removeSelectedCode = (codeToRemove) => {
    const updatedSelectedCodes = selectedCodes.filter(
      (c) => c.code !== codeToRemove
    );
    console.log("Removing code:", codeToRemove, "Updated selected codes:", updatedSelectedCodes);
    setSelectedCodes(updatedSelectedCodes);
    if (onCodeSelect) onCodeSelect(updatedSelectedCodes);
  };

  const handleChange = (event) => {
    const newTerm = event.target.value;
    setSearchTerm(newTerm);
  };

  useEffect(() => {
    if (searchTerm.trim() === "") {
      setShowResults(false);
      setSearchResults([]);
      return;
    }
    const timer = setTimeout(async () => {
      setIsLoading(true);
      const results = await searchICD10(searchTerm);
      setSearchResults(results);
      setShowResults(true);
      setIsLoading(false);
    }, 300);
    return () => clearTimeout(timer);
  }, [searchTerm]);

  const handleKeyPress = (e) => {
    // Example restriction against '.' if you wish:
    if (e.key === ".") {
      e.preventDefault();
    }
  };

  // Toggle favorite: add if not favorite, remove if already favorited.
  const toggleFavoriteCode = async (result) => {
    if (!billingCodeId) {
      console.error("No billingCodeId provided. Cannot toggle favorite.");
      return;
    }
    const favoritesRef = collection(
      db,
      `users/${uid}/billingCodes/${billingCodeId}/favoriteDiagnosisCodes`
    );
    if (favorites.some((fav) => fav.code === result.code)) {
      // Already favorited => remove
      const favToRemove = favorites.find((fav) => fav.code === result.code);
      if (favToRemove && favToRemove.id) {
        console.log("Removing favorite:", result.code);
        await deleteDoc(doc(favoritesRef, favToRemove.id));
      }
    } else {
      // Not favorited => add
      console.log("Favoriting code:", result.code);
      await addDoc(favoritesRef, result);
    }
  };

  // For rendering, prefer short_description if present, otherwise the code.
  const getDisplayText = (codeObj) =>
    codeObj.short_description ? codeObj.short_description : codeObj.code;

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <input
        type="text"
        placeholder="Search ICD10 codes"
        value={searchTerm}
        onChange={handleChange}
        onKeyPress={handleKeyPress}
      />

      {isLoading ? (
        <HexSpinner />
      ) : (
        <>
          {searchTerm.trim() !== "" ? (
            <div className="results-container">
              {searchResults.map((result, index) => (
                <div key={`${result.code}-${index}`}>
                  <span>
                    <button type="button" onClick={() => handleCodeSelect(result)}>
                      Select
                    </button>{" "}
                    {result.short_description}
                    <button
                      type="button"
                      onClick={() => toggleFavoriteCode(result)}
                      style={{ marginLeft: "8px", background: "none", border: "none" }}
                    >
                      <FontAwesomeIcon
                        icon={
                          favorites.some((fav) => fav.code === result.code)
                            ? solidHeart
                            : regularHeart
                        }
                        color={
                          favorites.some((fav) => fav.code === result.code)
                            ? "red"
                            : "inherit"
                        }
                      />
                    </button>
                  </span>
                </div>
              ))}
            </div>
          ) : (
            favorites.length > 0 && (
              <div className="favorites-container">
                <h4>Favorites</h4>
                {favorites.map((result, index) => (
                  <div key={`${result.code}-${index}`}>
                    <span>
                      <button type="button" onClick={() => handleCodeSelect(result)}>
                        Select
                      </button>{" "}
                      {result.short_description}
                      <button
                        type="button"
                        onClick={() => toggleFavoriteCode(result)}
                        style={{ marginLeft: "8px", background: "none", border: "none" }}
                      >
                        <FontAwesomeIcon icon={solidHeart} color="red" />
                      </button>
                    </span>
                  </div>
                ))}
              </div>
            )
          )}
        </>
      )}

      {selectedCodes.length > 0 && (
        <div>
          {selectedCodes.map((codeObj, index) => (
            <div
              className="primary-card w-100 content-center py-2 mt-3 m-0 px-0 pb-0"
              key={index}
              onClick={() => removeSelectedCode(codeObj.code)}
            >
              <h3>{getDisplayText(codeObj)}</h3>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ICD10;
