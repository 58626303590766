import React from 'react';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { db } from '../../../firebase';
import { doc, getDoc } from 'firebase/firestore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

const functions = getFunctions();

const DownloadBatchPatientXLSX = ({ selectedPatients, uid }) => {
  const s2ab = (s) => {
    const buffer = new ArrayBuffer(s.length);
    const view = new Uint8Array(buffer);
    for (let i = 0; i < s.length; i++) {
      view[i] = s.charCodeAt(i) & 0xFF;
    }
    return buffer;
  };

  const getCurrentDateFormatted = () => {
    const date = new Date();
    return `${date.getFullYear()}${(date.getMonth() + 1).toString().padStart(2, '0')}${date.getDate().toString().padStart(2, '0')}`;
  };

  const fetchPatientDataById = async (patientId) => {
    const docRef = doc(db, `patients/${uid}/patientData/${patientId}`);
    const docSnap = await getDoc(docRef);
    
    if (!docSnap.exists()) {
      return null;
    }
    
    const patientData = docSnap.data();
    if (patientData.patient.ciphertext && patientData.patient.iv) {
      const encryptedPayload = { ciphertext: patientData.patient.ciphertext, iv: patientData.patient.iv };
      try {
        const decryptFunction = httpsCallable(functions, 'decrypt');
        const decryptedResult = await decryptFunction(encryptedPayload);

        if (decryptedResult && decryptedResult.data) {
          return decryptedResult.data;
        } else {
          return null;
        }
      } catch (error) {
        return null;
      }
    } else {
      return patientData;
    }
  };

  const downloadPatientsData = async () => {
    const wb = XLSX.utils.book_new();
    const headers = [
      "firstName", "middleName", "lastName", "dateOfBirth", "gender",
      "address1", "address2", "city", "state", "zip",
      "notes", "PAYER", "memberId", "secondaryPayer", "secondaryMemberId", "phone", "email"
    ];

    const patientsData = await Promise.all(selectedPatients.map(async (id) => {
      const patient = await fetchPatientDataById(id);
      if (!patient) return {};
      const { patient: patientData, payers } = patient;
      return {
        firstName: patientData.firstName || '',
        middleName: patientData.middleName || '',
        lastName: patientData.lastName || '',
        dateOfBirth: patientData.dob || '',
        gender: patientData.gender || '',
        address1: patientData.address?.address1 || '',
        address2: patientData.address?.address2 || '',
        city: patientData.address?.city || '',
        state: patientData.address?.state || '',
        zip: patientData.address?.zip || '',
        PAYER: payers?.name || '',
        memberId: payers?.memberId || '',
        secondaryPayer: payers?.secondaryPayer?.name || '',
        secondaryMemberId: payers?.secondaryPayer?.memberId || '',
        phone: patientData.phone || '',
        email: patientData.email || ''
      };
    }));

    const ws = XLSX.utils.json_to_sheet(patientsData, { header: headers });
    XLSX.utils.book_append_sheet(wb, ws, "PatientsData");

    const binaryString = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
    const data = new Blob([s2ab(binaryString)], { type: "application/octet-stream" });
    const currentDate = getCurrentDateFormatted();
    saveAs(data, `patients_data_${currentDate}.xlsx`);

    try {
      const addLogFunction = httpsCallable(functions, 'addLog');
      await addLogFunction({
        uid: uid,
        message: `Patients data downloaded.`
      });
    } catch (error) {
      console.error("Error logging download event: ", error);
    }
  };

  return (
    <button className="delete" onClick={downloadPatientsData}>
      <FontAwesomeIcon title={'Download selected in XLSX'} icon={faDownload} />
    </button>
  );
};

export default DownloadBatchPatientXLSX;
