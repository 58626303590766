import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolder } from "@fortawesome/free-solid-svg-icons";
import { db } from "../../../../firebase";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { useNavigate, useParams } from "react-router-dom"; // <-- import useParams
import useUID from "../../../General/useUID";
import "./IntakeView.css";

function Sidebar() {
  const [clinics, setClinics] = useState([]);
  const [selectedClinic, setSelectedClinic] = useState(null);

  const [uid] = useUID();
  const navigate = useNavigate();
  const { clinicId: urlClinicId } = useParams(); // <-- get the clinicId from the URL

  useEffect(() => {
    if (!uid) return;

    const clinicsCol = collection(db, "clinics");
    const q = query(clinicsCol, where("ownerId", "==", uid));

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const clinicList = snapshot.docs
        .map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }))
        .filter((clinic) => !clinic.deleted); // Exclude deleted clinics

      setClinics(clinicList);
    });

    return () => unsubscribe();
  }, [uid]);

  // Runs when clinics change or the URL clinicId changes
  useEffect(() => {
    if (!clinics.length) return; 

    // If there's a clinicId in the URL, try to match it
    if (urlClinicId) {
      const foundClinic = clinics.find((c) => c.id === urlClinicId);
      if (foundClinic) {
        setSelectedClinic(foundClinic);
        return;
      }
      // if we can't find a match in the list, optionally select the first
    }

    // If there's no clinicId in URL or we didn't match it, select the first
    setSelectedClinic(clinics[0]);
  }, [clinics, urlClinicId]);

  const handleClinicSelect = (clinic) => {
    setSelectedClinic(clinic);
    // push to URL
    navigate(`/intakeview/${clinic.id}`);
  };

  return (
    <div className="sidebarContainer">
      <div className="backButton" onClick={() => navigate("/patients")}>
        <span>&lt; Back to Patients</span>
      </div>
      <ul className="clinicList">
        {clinics.map((clinic) => (
          <li
            key={clinic.id}
            className={`clinicItem ${
              selectedClinic?.id === clinic.id ? "selectedClinic" : ""
            }`}
            onClick={() => handleClinicSelect(clinic)}
          >
            <FontAwesomeIcon icon={faFolder} className="clinicIcon" />
            <span>{clinic.clinicName}</span>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Sidebar;
