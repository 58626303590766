import React, { useState, useEffect } from "react";
import {
  format,
  addMinutes,
  eachDayOfInterval,
  endOfWeek,
  startOfWeek,
  addWeeks,
  subWeeks,
  addHours,
  isAfter,
} from "date-fns";
import HexagonSpinner from "../../../../General/Animations/Hexspinner";

const AppointmentSlots = ({
  workingHours,
  appointmentTypes,
  clinicTimeZone,
  onAppointmentSelected,
  hoursBefore,
  bookedSlots
}) => {
  const [selectedAppointmentType, setSelectedAppointmentType] = useState(
    appointmentTypes[0]?.name || ""
  );
  const [currentWeek, setCurrentWeek] = useState(new Date());
  const [weekSlots, setWeekSlots] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [duration, setDuration] = useState(30);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    generateWeekSlots();
  }, [selectedAppointmentType, workingHours, clinicTimeZone, currentWeek, bookedSlots]);
  
  const generateWeekSlots = () => {
    const selectedType = appointmentTypes.find(
      (type) => type.name === selectedAppointmentType
    );
    const duration = parseInt(selectedType?.duration || 30, 10);
    setDuration(duration);
    const startWeek = startOfWeek(currentWeek, { weekStartsOn: 0 });
    const endWeek = endOfWeek(currentWeek, { weekStartsOn: 0 });
    const eachDay = eachDayOfInterval({ start: startWeek, end: endWeek });

    const slotsByDay = eachDay.map((day) => {
      const dayOfWeek = format(day, "EEEE");
      const slots = generateDaySlots(day, duration, dayOfWeek);
      return {
        dayOfWeek,
        date: format(day, "MMMM do"),
        slots,
      };
    });

    setWeekSlots(slotsByDay);
  };

  const handleSaveAppointment = async () => {
    if (!selectedSlot) {
      return;
    }

    const appointmentData = {
      ...selectedSlot,
      appointmentType: selectedAppointmentType,
      appointmentDuration: duration,
    };

    setIsSaving(true); // Set loading state to true

    await onAppointmentSelected(appointmentData);
    setIsSaving(false); // Set loading state to false
  };

  const generateDaySlots = (day, duration, dayOfWeek) => {
    let slots = [];
    const currentTime = new Date();
    const limitTime = addHours(currentTime, hoursBefore);
  
    if (workingHours[dayOfWeek]?.open) {
      let slotTime = new Date(day);
      slotTime.setHours(...workingHours[dayOfWeek].start.split(":"));
  
      while (format(slotTime, "HH:mm") < workingHours[dayOfWeek].end) {
        if (isAfter(slotTime, limitTime)) {
          const formattedSlotTime = format(slotTime, "HH:mm");
          const formattedSlotDate = format(slotTime, "yyyy-MM-dd"); // Standardize date format
  
          // Check if the slot is already booked
          const isBooked = bookedSlots.some(
            (appointment) =>
              appointment.date === formattedSlotDate && appointment.time === formattedSlotTime
          );
  
          if (!isBooked) {
            slots.push(formattedSlotTime);
          } else {
          }
        }
        slotTime = addMinutes(slotTime, duration);
      }
    }
  
    return slots;
  };
  
  

  const selectSlot = (slot, dayOfWeek, date) => {
    setSelectedSlot({ time: slot, dayOfWeek, date, timeZone: clinicTimeZone });
  };

  const handleNextWeek = () => {
    setCurrentWeek(addWeeks(currentWeek, 1));
    setSelectedSlot(null); // Clear selected slot when changing weeks
  };

  const handlePreviousWeek = () => {
    setCurrentWeek(subWeeks(currentWeek, 1));
    setSelectedSlot(null); // Clear selected slot when changing weeks
  };

  return (
    <div>
      <h2>Select an Appointment Type</h2>
      <select
        value={selectedAppointmentType}
        onChange={(e) => setSelectedAppointmentType(e.target.value)}
      >
        {appointmentTypes.map((type, index) => (
          <option key={index} value={type.name}>
            {type.name} - {type.duration} minutes
          </option>
        ))}
      </select>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "20px 0",
        }}
      >
        <button onClick={handlePreviousWeek}>← Prev Week</button>
        <h3>
          Week of{" "}
          {format(startOfWeek(currentWeek, { weekStartsOn: 0 }), "MMMM do")}
        </h3>
        <button onClick={handleNextWeek}>Next Week →</button>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          flexWrap: "wrap",
        }}
      >
        {weekSlots.map(({ dayOfWeek, date, slots }, index) => (
          <div key={index} style={{ margin: "10px", textAlign: "center" }}>
            <h4>{dayOfWeek}</h4>
            <p>{date}</p>
            {slots.map((slot, slotIndex) => {
              // Determine if this slot is selected
              const isSelectedSlot =
                selectedSlot?.time === slot &&
                selectedSlot?.dayOfWeek === dayOfWeek;
              return (
                <div key={slotIndex} style={{ margin: "5px" }}>
                  {isSelectedSlot ? (
                    <div className="selectedSlot">
                      <button
                        style={{ display: "block", margin: "5px auto" }}
                        onClick={() => selectSlot(slot, dayOfWeek, date)}
                      >
                        {slot} {clinicTimeZone}
                      </button>
                      <div style={{ textAlign: "center", marginTop: "10px" }}>
                        {isSaving ? (
                          <HexagonSpinner />
                        ) : (
                          <span
                            className="continueButton"
                            onClick={handleSaveAppointment}
                            style={{ color: "#0147DF"}}
                          >
                            Continue {">>"}
                          </span>
                        )}
                      </div>
                    </div>
                  ) : (
                    <button
                      style={{
                        padding: "10px",
                        margin: "5px",
                        border: "1px solid #ccc",
                      }}
                      onClick={() => selectSlot(slot, dayOfWeek, date)}
                    >
                      {slot} {clinicTimeZone}
                    </button>
                  )}
                </div>
              );
            })}
          </div>
        ))}
      </div>
    </div>
  );
};

export default AppointmentSlots;
