export const openEmailClient = ({ patientData, defaultProvider, noteContent }) => {
    if (!patientData?.patient?.email) {
      alert("Patient's email is not available.");
      return;
    }
  
    if (!defaultProvider) {
      alert("Default provider information is not available.");
      return;
    }
  
    const recipient = patientData.patient.email;
    const subject = `Note from Dr. ${
      defaultProvider?.firstName || "Unknown"
    } ${defaultProvider?.lastName || ""}`;
    const bodyContent = noteContent || "No note content available.";
    const body = encodeURIComponent(
      `Hello ${patientData.patient.firstName},\n\n` +
        `Here is your doctor's note:\n\n` +
        `${bodyContent}\n\n` +
        `Best regards,\nDr. ${
          defaultProvider?.firstName || "Unknown"
        } ${defaultProvider?.lastName || ""}`
    );
  
    const mailtoLink = `mailto:${recipient}?subject=${encodeURIComponent(
      subject
    )}&body=${body}`;
  
    // Open in a new tab
    const newTab = window.open(mailtoLink, "_blank");
  
    // Handle cases where the new tab couldn't open (e.g., pop-up blocker)
    if (!newTab || newTab.closed || typeof newTab.closed === "undefined") {
      alert("Unable to open email client. Please ensure pop-ups are not blocked.");
    }
  };
  