import React, { useState, useEffect, useCallback } from "react";
import { collection, getDocs, query, where, limit } from "firebase/firestore";
import { db } from "../../../../firebase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faTimes, faSpinner, faSearch, faPlus } from "@fortawesome/free-solid-svg-icons";
import { v4 as uuidv4 } from "uuid";
import algoliasearch from "algoliasearch/lite";

// Utility function to highlight matching text
const HighlightText = ({ text, highlight }) => {
  if (!highlight.trim() || !text) {
    return <span>{text}</span>;
  }

  const regex = new RegExp(`(${highlight.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')})`, 'gi');
  const parts = text.split(regex);

  return (
    <span>
      {parts.map((part, i) =>
        regex.test(part) ? <strong key={i}>{part}</strong> : <span key={i}>{part}</span>
      )}
    </span>
  );
};

// Initialize Algolia client and index.
const algoliaClient = algoliasearch(
  process.env.REACT_APP_AGNOLIA_CLIENT,
  process.env.REACT_APP_AGNOLIA_API
);
const algoliaIndex = algoliaClient.initIndex("ICD10");

const OptionRow = ({ index, option, onOptionChange, onRemove }) => {
  const [title, setTitle] = useState(option.title || "");
  const [selectedICD10, setSelectedICD10] = useState(option.icd10 || []);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showResults, setShowResults] = useState(false);

  const updateParent = (newTitle, newICD10) => {
    onOptionChange(index, { ...option, title: newTitle, icd10: newICD10 });
  };

  const handleTitleChange = (e) => {
    const newTitle = e.target.value;
    setTitle(newTitle);
    updateParent(newTitle, selectedICD10);
  };

  // Updated searchICD10 function that uses Algolia.
  const searchICD10 = useCallback(async (term) => {
    if (!term || term.trim().length < 2) {
      setSearchResults([]);
      setIsLoading(false);
      return;
    }

    setIsLoading(true);
    try {
      // Search Algolia with a limit of 8 hits.
      const { hits } = await algoliaIndex.search(term, { hitsPerPage: 8 });
      // Optionally filter for valid codes if needed.
      const results = hits.filter(hit => hit.valid_code === 1);
      setSearchResults(results);
    } catch (error) {
      console.error("Error searching ICD10 via Algolia:", error);
    } finally {
      setIsLoading(false);
      setShowResults(true);
    }
  }, []);

  // Debounced search implementation.
  useEffect(() => {
    const timer = setTimeout(() => {
      searchICD10(searchQuery);
    }, 300);

    return () => clearTimeout(timer);
  }, [searchQuery, searchICD10]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const addICD10 = (icd10Item) => {
    if (!selectedICD10.some((item) => item.code === icd10Item.code)) {
      const newSelected = [...selectedICD10, icd10Item];
      setSelectedICD10(newSelected);
      updateParent(title, newSelected);
    }
    setSearchQuery("");
    setShowResults(false);
  };

  const removeICD10 = (icd10Id) => {
    const newSelected = selectedICD10.filter((item) => (item.id || item.code) !== icd10Id);
    setSelectedICD10(newSelected);
    
    // Ensure the parent state is updated
    updateParent(title, newSelected);
  };  

  return (
    <div className="customOptionCard">
      <div className="customCardHeader">
        <input
          type="text"
          value={title}
          placeholder="Option Title"
          className="customOptionInput"
          onChange={handleTitleChange}
        />
        <button className="TrashButton" onClick={() => onRemove(index)}>
          <FontAwesomeIcon icon={faTrash} />
        </button>
      </div>

      <div className="customCardBody">
        <div className="customFieldGroup">
          <label>Selected ICD10 Codes:</label>
          <div className="icd10Container">
            {selectedICD10.map((item) => (
              <span key={item.id || item.code} className="icd10Badge">
                {item.code} - {item.short_description}
                <button className="removeBadge" onClick={() => removeICD10(item.id || item.code)}>
                  <FontAwesomeIcon icon={faTimes} />
                </button>
              </span>
            ))}
          </div>
        </div>

        <div className="customFieldGroup">
          <label>Search ICD10:</label>
          <div className="searchContainer">
            <div className="searchInputWrapper">
              <input
                type="text"
                value={searchQuery}
                placeholder="Search by code or description"
                className="customOptionInput searchInput"
                onChange={handleSearchChange}
                onFocus={() => setShowResults(true)}
              />
              <div className="searchIconContainer">
                <FontAwesomeIcon
                  icon={isLoading ? faSpinner : faSearch}
                  className={isLoading ? "searchIcon spin" : "searchIcon"}
                />
              </div>
            </div>

            {showResults && searchResults.length > 0 && (
              <ul className="searchResults">
                {searchResults.map((result) => (
                  <li
                    key={result.id || result.code}
                    className="searchResultItem"
                    onClick={() => addICD10(result)}
                  >
                    <div className="resultCode">
                      <HighlightText text={result.code} highlight={searchQuery} />
                    </div>
                    <div className="resultDescription">
                      <HighlightText text={result.short_description} highlight={searchQuery} />
                    </div>
                  </li>
                ))}
              </ul>
            )}

            {showResults && searchQuery.trim() && searchResults.length === 0 && !isLoading && (
              <div className="noResults">No matching ICD-10 codes found</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const SymptomsListInputSection = ({ item, fieldDef, fieldIndex, handleChange }) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const initialOptions = item.fields?.[fieldIndex]?.options || [];
    const normalizedOptions = initialOptions.map((opt) =>
      typeof opt === "string"
        ? { id: uuidv4(), title: opt, icd10: [] }
        : (opt.id ? opt : { ...opt, id: uuidv4() })
    );
    setOptions(normalizedOptions);
  }, [item.fields, fieldIndex]);

  const updateOptionsInParent = (updatedOptions) => {
    handleChange({ target: { value: updatedOptions } }, fieldIndex, "options");
  };

  const addOption = () => {
    const newTitle = `Option ${options.length + 1}`;
    const newOption = { id: uuidv4(), title: newTitle, icd10: [] };
    const updatedOptions = [...options, newOption];
    setOptions(updatedOptions);
    updateOptionsInParent(updatedOptions);
  };

  const removeOption = (index) => {
    const updatedOptions = options.filter((_, i) => i !== index);
    setOptions(updatedOptions);
    updateOptionsInParent(updatedOptions);
  };

  const handleOptionChange = (index, updatedOption) => {
    const updatedOptions = options.map((opt, i) => (i === index ? updatedOption : opt));
    setOptions(updatedOptions);
    updateOptionsInParent(updatedOptions);
  };

  return (
    <>
      <label>Options:</label>
      <div className="optionsContainer">
        {options.map((option, index) => (
          <OptionRow
            key={option.id}
            index={index}
            option={option}
            onOptionChange={handleOptionChange}
            onRemove={removeOption}
          />
        ))}
      </div>
      <button onClick={addOption} className="customAddOptionButton">
        <FontAwesomeIcon icon={faPlus} style={{ marginRight: "0.5rem" }} />
        Add Option
      </button>
    </>
  );
};

export default SymptomsListInputSection;
