// useBillableEncounters.js
import { useState, useEffect } from "react";
import { collection, getDocs, query, where, limit, Timestamp } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { db } from "../../../firebase";
import useUID from "../../General/useUID";

const BATCH_SIZE = 50;

const formatDate = (dateValue) => {
  if (!dateValue) return "Unknown date";

  let dateObj;
  if (dateValue.toDate) {
    dateObj = dateValue.toDate();
  } else if (dateValue instanceof Date) {
    dateObj = dateValue;
  } else {
    dateObj = new Date(dateValue);
  }
  
  if (isNaN(dateObj.getTime())) return "Unknown date";
  
  const month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
  const day   = ("0" + dateObj.getDate()).slice(-2);
  const year  = dateObj.getFullYear();
  return `${month}/${day}/${year}`;
};

export const useBillableEncounters = (fromDate, toDate) => {
  const [uid] = useUID();
  const [loading, setLoading] = useState(true);
  const [encounters, setEncounters] = useState([]);
  const [allEncountersLoaded, setAllEncountersLoaded] = useState(false);
  const [decryptedEncounterCache, setDecryptedEncounterCache] = useState(new Map());
  
  const functions = getFunctions();
  const batchDecryptEncounters = httpsCallable(functions, "batchDecryptEncounters");

  const loadMoreEncounters = async () => {
    if (!uid || allEncountersLoaded) return;
    setLoading(true);
  
    const patientDataCollectionRef = collection(db, `patients/${uid}/patientData`);
    const patientDocs = await getDocs(patientDataCollectionRef);
  
    const patients = [];
    const rawEncounters = [];
  
    await Promise.all(
      patientDocs.docs.map(async (patientDoc) => {
        const patientData = patientDoc.data();
        patients.push({
          id: patientDoc.id,
          ciphertext: patientData.patient?.ciphertext,
          iv: patientData.patient?.iv,
        });
  
        const encountersRef = collection(patientDoc.ref, "encounters");
        const fromDateObj = new Date(fromDate);
        const fromTimestamp = Timestamp.fromDate(fromDateObj);
        let q = query(encountersRef, where("timestamp", ">=", fromTimestamp));
  
        if (toDate) {
          const toDateObj = new Date(toDate);
          const toTimestamp = Timestamp.fromDate(toDateObj);
          q = query(q, where("timestamp", "<=", toTimestamp));
        }
  
        q = query(q, limit(BATCH_SIZE));
  
        const encounterDocs = await getDocs(q);
  
        encounterDocs.docs.forEach((encounterDoc) => {
          const encounterData = encounterDoc.data();
          rawEncounters.push({
            id: encounterDoc.id,
            patientId: patientDoc.id,
            ciphertext: encounterData.ciphertext,
            iv: encounterData.iv,
            BillingStatus: encounterData.BillingStatus,
            rawTimestamp: encounterData.timestamp,
          });
        });
      })
    );
  
    if (rawEncounters.length === 0) {
      setAllEncountersLoaded(true);
      setLoading(false);
      return;
    }
  
    const decryptedResponse = await batchDecryptEncounters({
      patients,
      encounters: rawEncounters,
    });
  
    const decryptedPatients = decryptedResponse.data.decryptedPatients;
    const decryptedEncounters = decryptedResponse.data.decryptedEncounters;
  
    const patientIdToPatientData = {};
    decryptedPatients.forEach((decryptedPatient) => {
      if (decryptedPatient) {
        patientIdToPatientData[decryptedPatient.id] = decryptedPatient.data;
      }
    });
  
    const newDecryptedData = decryptedEncounters.map((encObj) => {
      if (!encObj) return null;
      const { id: encounterId, patientId, data: decryptedEncounterData, BillingStatus } = encObj;
      const patient = patientIdToPatientData[patientId];
      if (!patient || !decryptedEncounterData) return null;
  
      const dateOfService = decryptedEncounterData.dateOfService;
      return {
        ...decryptedEncounterData,
        patientName: `${patient.patient?.firstName || ""} ${patient.patient?.middleName || ""} ${patient.patient?.lastName || ""}`.trim(),
        date: formatDate(dateOfService),
        diagnosisCodes: decryptedEncounterData.diagnosisCode || [],
        id: encounterId,
        isBilled: BillingStatus === true,
        patient: patient.patient || {},
        payers: patient.payers || [],
        selectedBillingCodes: [],
      };
    });
  
    const filteredData = newDecryptedData.filter((item) => item !== null);
  
    setDecryptedEncounterCache((prev) => {
      const updatedCache = new Map(prev);
      filteredData.forEach((enc) => updatedCache.set(enc.id, enc));
      return updatedCache;
    });
  
    // Merge previously cached data with new data
    const updatedEncounters = Array.from(decryptedEncounterCache.values()).concat(filteredData);
    setEncounters(updatedEncounters);
    setLoading(false);
  };

  useEffect(() => {
    // Reset state when filters change
    setEncounters([]);
    setDecryptedEncounterCache(new Map());
    setAllEncountersLoaded(false);
    loadMoreEncounters();
  }, [uid, fromDate, toDate]);

  return { loading, encounters, loadMoreEncounters, allEncountersLoaded };
};
