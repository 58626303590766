import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShieldAlt, faLock, faCloud, faKey, faGlobe, faMobileAlt, faCheckCircle, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import "./EncryptionSelection.css"; 

const EncryptionSelection = ({ setEncryptionType, encryptionType }) => {

  const handleSelection = (type) => {
    setEncryptionType(type);
    console.log("Selected Encryption:", type); // Debugging
  };

  return (
    <div>
      <h1 className="heading" >Select Encryption Level</h1>
      <p>Choose the encryption level for your account:</p>

      <div className="encryption-options">
        {/* Zero-Trust AES Option */}
        <div 
          className={`encryption-card ${encryptionType === "Zero-Trust AES" ? "selected" : ""}`}
          onClick={() => handleSelection("Zero-Trust AES")}
        >
          <h3><FontAwesomeIcon icon={faShieldAlt} />AES-256</h3>
          <p>Fast, efficient, and the highest standard encryption.</p>
          <ul className="custom-list">
            <li><FontAwesomeIcon icon={faLock} className="icon" /> <span>Zero-Trust Architecture</span></li>
            <li><FontAwesomeIcon icon={faCloud} className="icon" /> <span>Daily Disaster Backups</span></li>
            <li><FontAwesomeIcon icon={faGlobe} className="icon" /> <span>Works on Web App</span></li>
            <li><FontAwesomeIcon icon={faMobileAlt} className="icon" /> <span>iOS, OSX, Android <span className="coming-soon">Coming Soon</span></span></li>
          </ul>
        </div>

        {/* Post-Quantum Encryption Option */}
        <div 
          className={`encryption-card ${encryptionType === "Post-Quantum Encryption" ? "selected" : ""}`}
          onClick={() => handleSelection("Post-Quantum Encryption")}
        >
          <h3><FontAwesomeIcon icon={faKey} /> End-To-End </h3>
          <p>Future-proof encryption resistant to quantum attacks.</p>
          <ul className="custom-list">
            <li><FontAwesomeIcon icon={faShieldAlt} className="icon" /> <span>Post-Quantum: Kyber</span></li>
            <li><FontAwesomeIcon icon={faLock} className="icon" /> <span>End-to-End Encrypted</span></li>
            <li><FontAwesomeIcon icon={faExclamationTriangle} className="icon warning" /> <span>Requires Desktop App</span></li>
            <li><FontAwesomeIcon icon={faMobileAlt} className="icon disabled" /> <span>iOS, OSX, Android <span className="coming-soon">Coming Soon</span></span></li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default EncryptionSelection;
