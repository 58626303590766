import React, { useState, useEffect } from 'react';

const CodeInput = ({ onChange, onSubmit }) => {
  const [code, setCode] = useState('');

  const handleChange = (e) => {
    const value = e.target.value.replace(/[^0-9]/g, ''); // Allow only numeric input
    setCode(value);
    onChange(value);

    if (value.length === 6) {
      onSubmit(value); // Automatically submit when the code is complete
    }
  };

  useEffect(() => {
    // Autofocus the input field on initial render
    document.getElementById('otp-input').focus();
  }, []);

  return (
    <div className="input-group-code">
      <input
        id="otp-input"
        type="text"
        inputMode="numeric"
        autoComplete="one-time-code"
        maxLength="6"
        className="code-input"
        value={code}
        onChange={handleChange}
        placeholder="Enter your 6-digit code"
      />
    </div>
  );
};

export default CodeInput;
